import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.admin.secondary,
    },
    delete: {
        backgroundColor: theme.palette.text.danger,
        color: "white",
    },
}));

const Title = (props) => {
    const classes = useStyles();
    const { title, subtitle, button } = props;

    return (
        <Grid container spacing={0}>
            <Grid item lg={12} md={12} xs={12}>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                >
                    <Box>
                        <Typography variant="h2" component="h2" gutterBottom>
                            {title}
                        </Typography>
                        <Typography gutterBottom className={classes.title}>
                            {subtitle}
                        </Typography>
                    </Box>
                    <Box m={2}>{button}</Box>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Title;
