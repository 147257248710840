import { Avatar, Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    avatar: {
        border: "2px solid white",
        "& img": {
            objectPosition: "top",
        },
        marginRight: 10,
    },
    box: {
        padding: 2,
        borderRadius: 30,
        color: "white",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
    },
}));

const EscolaTaula = (props) => {
    const classes = useStyles();
    const { value } = props;
    return (
        <Box
            className={classes.box}
            style={{ backgroundColor: value.color_alt }}
        >
            <Avatar
                alt={value.nom}
                className={classes.avatar}
                src={"https://api.sinapsisberga.cat/storage/" + value.imatge}
                style={{ backgroundColor: value.color }}
            />
            {value.nom}
        </Box>
    );
};

export default EscolaTaula;
