import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Container, createMuiTheme, Fade, IconButton, makeStyles, MuiThemeProvider } from "@material-ui/core";
import Page from "../../../components/Page";
import { useNavigate } from "react-router-dom";
import { getActivitats } from "../../../database/API";
import MUIDataTable from "mui-datatables";
import Title from "../../../components/Title";
import { Add } from "@material-ui/icons";
import ActivitatsColumns from "./Table/ActivitatsColumns";
import ActivitatsOptions from "./Table/ActivitatsOptions";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: "white",
		minHeight: "100%",
	},
	container: {
		paddingTop: 40,
		paddingBottom: 80,
	},
}));

const getMuiTheme = () =>
	createMuiTheme({
		overrides: {
			TableHead: {
				root: {
					fontSize: 23,
				},
			},
		},
	});

const ActivitatsAdmin = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const [activitats, setActivitats] = useState();
	const [loading, setLoading] = useState(true);

	const filterList = JSON.parse(localStorage.getItem("activitatsFilter"));
	const columns = ActivitatsColumns(activitats, filterList ? filterList : []);

	const filterFunc = (changedColumn, filterList) => localStorage.setItem("activitatsFilter", JSON.stringify(filterList[2]));
	const options = ActivitatsOptions(activitats, filterFunc);

	useEffect(() => {
		const get = async () => {
			const { activitats } = await getActivitats(null);
			setActivitats(activitats);
			setLoading(false);
		};

		get();
	}, []);

	return (
		<Page className={classes.root} title={"Activitats"}>
			<Container maxWidth={false} className={classes.container}>
				<Title
					title={"Activitats"}
					subtitle={"Administra les activitats"}
					button={
						<>
							<Button onClick={() => navigate("packs")}>Packs</Button>
							<IconButton className={classes.addButton} onClick={() => navigate("nou")}>
								<Add />
							</IconButton>
						</>
					}
				/>
				<Box pt={2} spacing={3}>
					{!loading ? (
						<Fade in={!loading}>
							<div>
								<MuiThemeProvider theme={getMuiTheme()}>
									<MUIDataTable data={activitats} columns={columns} options={options} />
								</MuiThemeProvider>
							</div>
						</Fade>
					) : (
						<CircularProgress />
					)}
				</Box>
			</Container>
		</Page>
	);
};

export default ActivitatsAdmin;
