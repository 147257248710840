import React, { useEffect, useState } from "react";
import {
	Box,
	Container,
	Grid,
	makeStyles,
	TextField,
	Typography,
	Button,
	CircularProgress,
	Fade,
	FormControl,
	InputLabel,
	Select,
	IconButton,
} from "@material-ui/core";
import Page from "../../../components/Page";
import { useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { deleteButton, getActivitats, getButton, updateButton } from "../../../database/API";
import { useSnackbar } from "notistack";
import Title from "../../../components/Title";
import Thumb from "../../../components/Thumb";
import { PictureAsPdf } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: "white",
		minHeight: "100%",
	},
	container: {
		paddingTop: 47,
	},
	"@global": {
		html: {
			width: "100%",
			height: "100%",
		},
	},
	formControl: {
		marginTop: 16,
		marginBottom: 15,
	},
	paper: {
		backgroundColor: "white",
		borderRadius: 5,
		padding: 30,
		boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
	},
}));

const ButtonEdit = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const { key } = useParams();
	const [button, setButton] = useState();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const get = async () => {
			const { button } = await getButton(key);
			setButton(button);
			setLoading(false);
			console.log(button);
		};
		get();
	}, [key]);

	const editar = async (values) => {
		const message = await updateButton(values, key);
		console.log(values);
		enqueueSnackbar(message, {
			variant: "success",
		});
		navigate(-1, { state: values });
	};

	const eliminarBoto = async () => {
		const message = await deleteButton(key);
		enqueueSnackbar(message, {
			variant: "success",
		});
		navigate(-1);
	};

	return (
		<Page className={classes.root} title={"Editar botó"}>
			<Container maxWidth={false} className={classes.container}>
				<Box mb={3} display="flex" justifyContent="space-between">
					<Title
						title={"Editar botó"}
						button={
							<Button variant="contained" color="danger" onClick={eliminarBoto}>
								Eliminar botó
							</Button>
						}
					/>
				</Box>
				<Box className={classes.paper}>
					{loading ? (
						<CircularProgress />
					) : (
						<Fade in={!loading}>
							<Formik
								enableReinitialize
								initialValues={{
									nom: button?.nom,
									subtitol: button?.subtitol,
									url: button.url !== "null" ? button?.url : "",
									file: button?.file,
									icon: button?.icon,
								}}
								validationSchema={Yup.object().shape({
									nom: Yup.string().required("El nom és obligatòri"),
								})}
								onSubmit={(values) => {
									editar(values);
								}}
							>
								{({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, touched, values }) => (
									<form onSubmit={handleSubmit}>
										<Grid container spacing={3}>
											<Grid item md={6} xs={12}>
												<TextField
													error={Boolean(touched.nom && errors.nom)}
													fullWidth
													helperText={touched.nom && errors.nom}
													label="Títol"
													margin="normal"
													variant="outlined"
													name="nom"
													onBlur={handleBlur}
													onChange={handleChange}
													type="text"
													value={values.nom}
												/>
											</Grid>
											<Grid item md={3} xs={12}>
												<TextField
													error={Boolean(touched.subtitol && errors.subtitol)}
													fullWidth
													helperText={touched.subtitol && errors.subtitol}
													label="Subtitol"
													margin="normal"
													variant="outlined"
													name="subtitol"
													onBlur={handleBlur}
													onChange={handleChange}
													type="text"
													value={values.subtitol}
												/>
											</Grid>
											<Grid item md={3} xs={12}>
												<FormControl
													className={classes.formControl}
													variant="outlined"
													fullWidth
													InputLabelProps={{
														shrink: true,
													}}
												>
													<InputLabel htmlFor="icon">Icona</InputLabel>
													<Select
														native
														value={values.icon}
														onChange={handleChange}
														onBlur={handleBlur}
														label="Icona"
														name="icon"
														InputLabelProps={{
															shrink: true,
														}}
														inputProps={{
															name: "icon",
															id: "icon",
														}}
													>
														<option value="campana">Campana</option>
														<option value="descarregar">Descarregar</option>
														<option value="document">Document</option>
														<option value="rellotge">Rellotge</option>
														<option value="info">Informació</option>
														<option value="alerta">Alerta</option>
														<option value="video">Vídeo</option>
													</Select>
												</FormControl>
											</Grid>
										</Grid>

										<Grid container spacing={3}>
											<Grid item md={6} xs={12}>
												<Box mt={2}>
													<>
														<Typography>Document adjunt</Typography>
														<input
															id="file"
															name="file[]"
															type="file"
															onChange={(event) => {
																setFieldValue("file", event.currentTarget.files[0]);
															}}
															className="form-control"
														/>
													</>
													{values.file !== "1" && (
														<IconButton href={"https://api.sinapsisberga.cat/storage/" + values.file} target="_blank">
															<PictureAsPdf />
														</IconButton>
													)}
												</Box>
											</Grid>
											<Grid item md={6} xs={12}>
												<TextField
													error={Boolean(touched.url && errors.url)}
													fullWidth
													helperText={touched.url && errors.url}
													label="Enllaç (si no hi ha document ajunt)"
													margin="normal"
													variant="outlined"
													name="url"
													onBlur={handleBlur}
													onChange={handleChange}
													type="text"
													value={values.url}
												/>
											</Grid>
										</Grid>

										<Box my={2}>
											<Button color="primary" fullWidth size="large" type="submit" variant="contained">
												{"Editar"}
											</Button>
										</Box>
									</form>
								)}
							</Formik>
						</Fade>
					)}
				</Box>
			</Container>
		</Page>
	);
};

export default ButtonEdit;
