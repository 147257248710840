import React, { useEffect, useState } from "react";
import {
    Box,
    CircularProgress,
    Container,
    createMuiTheme,
    Fade,
    IconButton,
    makeStyles,
    MuiThemeProvider,
} from "@material-ui/core";
import Page from "../../../components/Page";
import { useNavigate } from "react-router-dom";
import { getAlumnes } from "../../../database/API";
import MUIDataTable from "mui-datatables";
import Title from "../../../components/Title";
import { Add } from "@material-ui/icons";
import UsersColumns from "./Table/UsersColumns";
import UsersOptions from "./Table/UsersOptions";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "white",
        minHeight: "100%",
    },
    container: {
        paddingTop: 40,
        paddingBottom: 40,
    },
}));

const getMuiTheme = () =>
    createMuiTheme({
        overrides: {
            TableHead: {
                root: {
                    fontSize: 23,
                },
            },
        },
    });

const UsersAdmin = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [alumnes, setAlumnes] = useState();
    const [loading, setLoading] = useState(true);
    const columns = UsersColumns(alumnes);
    const options = UsersOptions(alumnes);

    useEffect(() => {
        const get = async () => {
            const { alumnes } = await getAlumnes();
            setAlumnes(alumnes);
            setLoading(false);
        };

        get();
    }, []);

    return (
        <Page className={classes.root} title={"Alumnes"}>
            <Container maxWidth={false} className={classes.container}>
                <Title
                    title={"Alumnes"}
                    subtitle={"Administra els alumnes inscrits"}
                    button={
                        <IconButton
                            className={classes.addButton}
                            onClick={() => navigate("nou")}
                        >
                            <Add />
                        </IconButton>
                    }
                />
                <Box py={2} spacing={3}>
                    {!loading ? (
                        <Fade in={!loading}>
                            <div>
                                <MuiThemeProvider theme={getMuiTheme()}>
                                    <MUIDataTable
                                        data={alumnes}
                                        columns={columns}
                                        options={options}
                                    />
                                </MuiThemeProvider>
                            </div>
                        </Fade>
                    ) : (
                        <CircularProgress />
                    )}
                </Box>
            </Container>
        </Page>
    );
};

export default UsersAdmin;
