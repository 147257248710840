import { CircularProgress, Grid } from "@material-ui/core";
import { SvgIcon } from "@material-ui/core";
import { Container } from "@material-ui/core";
import { Link } from "@material-ui/core";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { AccessTime, CloudDownload, Description, Info, NotificationsActiveRounded, ReportProblem, Videocam } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import file from "../assets/Inscripcions_Lets_Play_22.pdf";
import { getButtons } from "../database/API";

const useStyles = makeStyles((theme) => ({
	infoImportant: {
		borderRadius: 80,
		border: "6px solid " + theme.palette.background.default,
		padding: 10,
		marginBottom: 50,
		justifyContent: "center",
		alignItems: "center",
		display: "flex",
		backgroundColor: "white",
		cursor: "pointer",
		zIndex: 50,
		transition: "0.2s",
		margin: 10,
		"&:hover": {
			backgroundColor: theme.palette.background.default,
		},
	},
	infoImportantMob: {
		borderRadius: 80,
		marginBottom: 10,
		justifyContent: "center",
		alignItems: "center",
		display: "flex",
		backgroundColor: "white",
		cursor: "pointer",
		zIndex: 50,
		transition: "0.2s",
		"&:hover": {
			backgroundColor: theme.palette.background.default,
		},
	},
	bell: {
		fill: theme.palette.third.main,
	},
}));

const InfoImportant = (props) => {
	const { mobile } = props;
	const classes = useStyles();
	const [buttons, setButtons] = useState([]);
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();

	useEffect(() => {
		const get = async () => {
			const { buttons } = await getButtons();
			setButtons(buttons);
			setLoading(false);
		};
		get();
	}, []);

	const renderIcon = (icon) => {
		switch (icon) {
			case "campana":
				return <NotificationsActiveRounded className={classes.bell} style={{ fontSize: 50 }} />;
			case "descarregar":
				return <CloudDownload className={classes.bell} style={{ fontSize: 50 }} />;
			case "document":
				return <Description className={classes.bell} style={{ fontSize: 50 }} />;
			case "rellotge":
				return <AccessTime className={classes.bell} style={{ fontSize: 50 }} />;
			case "info":
				return <Info className={classes.bell} style={{ fontSize: 50 }} />;
			case "alerta":
				return <ReportProblem className={classes.bell} style={{ fontSize: 50 }} />;
			case "video":
				return <Videocam className={classes.bell} style={{ fontSize: 50 }} />;
			default:
				break;
		}
	};

	return (
		<Container maxWidth={buttons.length >= 4 ? "xl" : "lg"}>
			<Box display="flex" justifyContent="space-evenly" flexDirection={mobile ? "column" : "row"}>
				{loading ? (
					<CircularProgress />
				) : (
					buttons?.map((item) => (
						<Link
							href={item.url !== "null" ? item.url : "https://api.sinapsisberga.cat/storage/" + item.file}
							className={mobile ? classes.infoImportantMob : classes.infoImportant}
							style={{ width: "100%" }}
							target="_blank"
						>
							<Grid container spacing={3} style={{ alignItems: "center" }}>
								<Grid item xs={3} style={{ textAlign: "center" }}>
									{renderIcon(item.icon)}
								</Grid>
								<Grid item xs={9}>
									<Typography variant="h4" fontSize={17}>
										{item?.nom}
									</Typography>
									<Typography variant="body1">{item?.subtitol}</Typography>
								</Grid>
							</Grid>
						</Link>
					))
				)}
				{/* <Link
                            href={
                                "/informacio"
                            }
                            className={
                                mobile
                                    ? classes.infoImportantMob
                                    : classes.infoImportant
                            }
                            style={{ width: '100%' }}
                            target="_blank"
                        >
                            <Grid
                                container
                                spacing={3}
                                style={{ alignItems: 'center' }}
                            >
                                <Grid
                                    item
                                    xs={3}
                                    style={{ textAlign: 'center' }}
                                >
                                        <NotificationsActiveRounded
                                            className={classes.bell}
                                            style={{
                                                fontSize: mobile ? 70 : 90,
                                            }}
                                        />
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography variant="h4">
                                    Xerrades informatives online
                                    </Typography>
                                    <Typography variant="body1">
                                        Clica per obtenir informació
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Link> */}
			</Box>
		</Container>
	);
};

export default InfoImportant;
