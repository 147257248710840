import React from "react";
import { Box, Grid, makeStyles, Typography, Avatar } from "@material-ui/core";
import moment from "moment/min/moment-with-locales";

const useStyles = makeStyles((theme) => ({
	activitatWrap: {
		backgroundColor: "white",
		padding: 30,
		border: "6px solid white",
		borderRadius: 10,
		transition: ".2s",
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		position: "relative",
	},
	avatar: {
		width: 120,
		height: 120,
		border: "8px solid white",
		cursor: "pointer",
		"& img": {
			objectPosition: "top",
			width: "90%",
		},
		marginBottom: 15,
	},
	places: {
		position: "absolute",
		top: 30,
		right: 20,
		paddingLeft: 15,
		paddingRight: 15,
		borderRadius: 20,
		backgroundColor: theme.palette.primary.main,
		"& p": {
			color: "white",
			fontSize: 16,
		},
	},
	ple: {
		color: theme.palette.error.main + "!important",
	},
}));

const Activitat = (props) => {
	const classes = useStyles();
	const { activitat } = props;

	return (
		<Box
			className={classes.activitatWrap}
			style={{
				backgroundColor: activitat?.escola.color_alt,
			}}
		>
			<Grid container spacing={4}>
				<Grid item>
					<Avatar
						alt={activitat.escola.nom}
						className={classes.avatar}
						style={{
							backgroundColor: activitat.escola.color,
						}}
						src={"https://api.sinapsisberga.cat/storage/" + activitat.escola.imatge}
					/>
				</Grid>
				<Grid item>
					<Typography variant="caption" style={{ color: "white" }}>
						{activitat?.curs}
					</Typography>
					<Typography variant="h3" style={{ color: "white" }}>
						{activitat?.nom}
					</Typography>
					<Typography variant="body1" style={{ color: "white" }}>
						{activitat?.escola.nom}
					</Typography>
					<Box mt={3}>
						<Typography variant="body1" style={{ color: "white" }}>
							{activitat?.setmanal === "1" ? (
								<span
									style={{
										textTransform: "capitalize",
									}}
								>
									Diària
								</span>
							) : (
								<span
									style={{
										textTransform: "capitalize",
									}}
								>
									{moment(activitat?.dia, "e").locale("ca").format("dddd")}
								</span>
							)}{" "}
							de {moment(activitat?.hora_i, "HH:mm:ss").format("H:mm")} h a {moment(activitat?.hora_f, "HH:mm:ss").format("H:mm")} h
						</Typography>
						<Typography variant="body1" style={{ color: "white" }}>
							{activitat?.imparteix}
						</Typography>

						<Typography variant="caption" style={{ color: "white", opacity: 0.8 }}>
							{activitat?.preu} €
						</Typography>
					</Box>
				</Grid>
				<Box className={classes.places}>
					<Typography variant="body1" className={activitat?.alumnes.length == activitat?.places && classes.ple}>
						{activitat?.alumnes.length}/{activitat?.places}
					</Typography>
				</Box>
			</Grid>
		</Box>
	);
};

export default Activitat;
