import { Typography } from "@material-ui/core";
import { Avatar, Box, Card, CardHeader, Grid, makeStyles } from "@material-ui/core";
import { AccessTime, CloudDownload, Description, Info, NotificationsActiveRounded, ReportProblem, Videocam } from "@material-ui/icons";
import React from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.default,
		borderRadius: 60,
	},
	item: { paddingTop: 25 },
	text: {
		paddingTop: 5,
		paddingLeft: 10,
	},
	index: {
		width: 20,
	},
	media: {
		paddingTop: "100%", // 16:9
	},
	imatge: {
		width: 200,
		padding: 20,
	},
	imatgeWrap: {
		display: "flex",
		justifyContent: "center",
		marginTop: 30,
	},
	avatar: {
		width: 180,
		height: 180,
		border: "8px solid white",
		"& img": {
			objectPosition: "top",
		},
	},
	link: {
		textDecoration: "none",
		"& img": {
			transition: ".2s",
		},
		"& .MuiAvatar-root": {
			transition: ".2s",
		},
		"& :hover": {
			"& img": {
				transform: "scale(1.1)",
			},
			"& .MuiAvatar-root": {
				borderColor: theme.palette.admin.text,
			},
		},
	},
	icon: {
		fill: theme.palette.third.main,
		paddingRight: 20,
	},
}));

const ButtonItem = (props) => {
	const { item } = props;
	const classes = useStyles();

	const renderIcon = () => {
		switch (item.icon) {
			case "campana":
				return <NotificationsActiveRounded className={classes.icon} style={{ fontSize: 60 }} />;
			case "descarregar":
				return <CloudDownload className={classes.icon} style={{ fontSize: 60 }} />;
			case "document":
				return <Description className={classes.icon} style={{ fontSize: 60 }} />;
			case "rellotge":
				return <AccessTime className={classes.icon} style={{ fontSize: 60 }} />;
			case "info":
				return <Info className={classes.icon} style={{ fontSize: 60 }} />;
			case "alerta":
				return <ReportProblem className={classes.icon} style={{ fontSize: 60 }} />;
			case "video":
				return <Videocam className={classes.icon} style={{ fontSize: 60 }} />;
			default:
				break;
		}
	};

	return (
		<Grid item md={6} xs={12} key={item.id}>
			<Link to={`/admin/buttons/${item.id}`} className={classes.link}>
				<Card className={classes.root}>
					<Box display="flex" p={3} alignItems="center">
						{renderIcon()}
						<Box>
							<Typography variant="h4" style={{ color: "white" }}>
								{item.nom}
							</Typography>
							<Typography variant="body1" style={{ color: "white" }}>
								{item.subtitol}
							</Typography>
						</Box>
					</Box>
				</Card>
			</Link>
		</Grid>
	);
};

export default ButtonItem;
