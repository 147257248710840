import React from "react";
import {
    Box,
    Container,
    Grid,
    makeStyles,
    TextField,
    Typography,
    Button,
} from "@material-ui/core";
import Page from "../../../components/Page";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { createEscola } from "../../../database/API";
import { useSnackbar } from "notistack";
import Title from "../../../components/Title";
import Thumb from "../../../components/Thumb";
import { ColorButton, ColorPicker } from "material-ui-color";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "white",
        minHeight: "100%",
    },
    container: {
        paddingTop: 47,
    },
    // "@global": {
    //     html: {
    //         width: "100%",
    //         height: "100%",
    //     },
    // },
    formControl: {
        marginTop: 16,
        marginBottom: 15,
    },
}));

const EscolesAdd = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const crear = async (values) => {
        const message = await createEscola(values);
        enqueueSnackbar(message, {
            variant: "success",
        });
        navigate(-1, { state: values });
    };

    return (
        <Page className={classes.root} title={"Dashboard"}>
            <Container maxWidth={false} className={classes.container}>
                <Formik
                    initialValues={{
                        nom: "",
                        color: "#ffffff",
                        color_alt: "#ffffff",
                        imatge: "",
                    }}
                    validationSchema={Yup.object().shape({
                        nom: Yup.string().required("El nom és obligatòri"),
                    })}
                    onSubmit={(values) => {
                        crear(values);
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        touched,
                        values,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <Box mb={3}>
                                <Title
                                    title={"Crear escola"}
                                    subtitle={
                                        "Entra les dades per guardar una nova escola"
                                    }
                                />
                            </Box>
                            <Grid container spacing={3}>
                                <Grid item md={6}>
                                    <TextField
                                        error={Boolean(
                                            touched.nom && errors.nom
                                        )}
                                        fullWidth
                                        helperText={touched.nom && errors.nom}
                                        label="Nom"
                                        margin="normal"
                                        variant="outlined"
                                        name="nom"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text"
                                        value={values.nom}
                                    />
                                </Grid>
                                <Grid item md={3}>
                                    <Typography variant="body1">
                                        Color
                                    </Typography>
                                    <Box mt={1} />
                                    <ColorPicker
                                        onChange={(value) => {
                                            setFieldValue("color", value);
                                        }}
                                        value={values.color}
                                        label="Color"
                                        name="color"
                                    />
                                </Grid>

                                <Grid item md={3}>
                                    <Typography variant="body1">
                                        Color alternatiu
                                    </Typography>
                                    <Box mt={1} />
                                    <ColorPicker
                                        onChange={(value) => {
                                            setFieldValue("color_alt", value);
                                        }}
                                        value={values.color}
                                        label="Color alternatiu"
                                        name="color_alt"
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={3}>
                                <Grid item md={12}>
                                    <Box mt={2}>
                                        <Typography>Imatge</Typography>
                                        <input
                                            id="imatge"
                                            name="imatge[]"
                                            type="file"
                                            onChange={(event) => {
                                                setFieldValue(
                                                    "imatge",
                                                    event.currentTarget.files[0]
                                                );
                                            }}
                                            className="form-control"
                                        />
                                    </Box>
                                    <Box p={2}>
                                        <Thumb file={values.imatge} />
                                    </Box>
                                </Grid>
                            </Grid>

                            <Box my={2}>
                                <Button
                                    color="primary"
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                >
                                    {"Crear"}
                                </Button>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Container>
        </Page>
    );
};

export default EscolesAdd;
