import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, Button, Divider, Drawer, Hidden, List, Typography, makeStyles, IconButton } from "@material-ui/core";
import { Activity, Airplay, BarChart as BarChartIcon, Home, Sun, UserCheck, Users, UserX } from "react-feather";
import NavItem from "./NavItem";
import { Close, ListAlt, SchoolOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
	mobileDrawer: {
		width: 256,
	},
	desktopDrawer: {
		width: 256,
		top: 64,
		height: "calc(100% - 64px)",
	},
	avatar: {
		cursor: "pointer",
		width: 64,
		height: 64,
		marginBottom: 10,
	},
}));

const NavBar = ({ onMobileClose, openMobile }) => {
	const classes = useStyles();
	const location = useLocation();
	const user = JSON.parse(localStorage.getItem("user")) || "";
	const items = [
		{
			href: "/admin/alumnes",
			icon: UserCheck,
			title: "Alumnes",
		},
		{
			href: "/admin/activitats",
			icon: ListAlt,
			title: "Activitats",
		},
		{
			href: "/admin/escoles",
			icon: Home,
			title: "Escoles",
		},
		{
			href: "/admin/buttons",
			icon: Airplay,
			title: "Botons",
		},
		{
			href: "/admin/inscrits",
			icon: Sun,
			title: "Inscrits Casals",
		},
	];

	const content = (
		<Box height="100%" display="flex" flexDirection="column">
			<Box alignItems="center" display="flex" flexDirection="column" p={2}>
				<Typography color="textSecondary" variant="body2">
					{user.email}
				</Typography>
			</Box>
			<Divider />
			<Box p={2}>
				<List>
					{items?.map((item) => (
						<NavItem href={item.href} key={item.title} title={item.title} icon={item.icon} />
					))}
				</List>
			</Box>
			<Box flexGrow={1} />
		</Box>
	);

	return (
		<>
			<Hidden mdUp>
				<Drawer anchor="left" classes={{ paper: classes.mobileDrawer }} onClose={onMobileClose} open={openMobile} variant="temporary">
					<IconButton onClick={onMobileClose}>
						<Close />
					</IconButton>
					{content}
				</Drawer>
			</Hidden>
			<Hidden mdDown>
				<Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
					{content}
				</Drawer>
			</Hidden>
		</>
	);
};

NavBar.propTypes = {
	onMobileClose: PropTypes.func,
	openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
	onMobileClose: () => {},
	openMobile: false,
};

export default NavBar;
