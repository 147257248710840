import { Avatar, Box, Container, Grid, makeStyles, Typography } from "@material-ui/core";
import { SvgIcon } from "@material-ui/core";
import { ReactComponent as Comellas } from "../theme/icons/colaboradors/academia-comellas-cluet.svg";
import { ReactComponent as Tossalet } from "../theme/icons/colaboradors/el-tossalet.svg";
import { ReactComponent as Padel } from "../theme/icons/colaboradors/padel-indoor.svg";
import { ReactComponent as Ceec } from "../theme/icons/colaboradors/ceec.svg";
import { ReactComponent as Basquet } from "../theme/icons/colaboradors/basquet-berga.svg";
import { ReactComponent as Espai } from "../theme/icons/colaboradors/espai-viu.svg";
import { ReactComponent as Cpa } from "../theme/icons/colaboradors/cpa.svg";
import { ReactComponent as Trave } from "../theme/icons/colaboradors/cal-trave.svg";
import { ReactComponent as Anonima } from "../theme/icons/colaboradors/lanonima.svg";
import { ReactComponent as Peu } from "../theme/icons/colaboradors/peu-de-via.svg";
import { ReactComponent as Volei } from "../theme/icons/colaboradors/volei.svg";

const useStyles = makeStyles((theme) => ({
	avatar: {
		border: "2px solid white",
		"& img": {
			objectPosition: "top",
		},
		marginRight: 10,
	},
	box: {
		backgroundColor: "#e8e3c5",
		justifyContent: "center",
		alignContent: "center",
		alignItems: "center",
	},
	icon: {
		width: "100% !important",
		height: "100% !important",
	},

	image: {
		marginTop: 30,
		width: "100% !important",
	},
}));

const Colaboradors = () => {
	const classes = useStyles();
	const colaboradors = [
		{
			logo: Comellas,
			viewBox: "0 0 75 30",
		},
		{
			logo: Tossalet,
			viewBox: "0 0 50 40",
		},
		{
			logo: Padel,
			viewBox: "0 0 75 20",
		},
		{
			logo: Ceec,
			viewBox: "0 0 72 20",
		},
		{
			logo: Basquet,
			viewBox: "0 0 40 50",
		},
		{
			logo: Espai,
			viewBox: "0 0 50 35",
		},
		{
			logo: Cpa,
			viewBox: "0 0 55 50",
		},
		{
			logo: Trave,
			viewBox: "0 0 70 50",
		},
		{
			logo: Anonima,
			viewBox: "0 0 375 100",
		},
		{
			logo: Peu,
			viewBox: "0 0 350 350",
		},
		{
			logo: Volei,
			viewBox: "0 0 450 620",
		},
	];

	return (
		<Box className={classes.box} px={10}>
			<Box py={5}>
				<Typography variant="h2">Col·laboradors</Typography>
			</Box>

			<Grid container spacing={10}>
				{colaboradors.map((item) => (
					<Grid item md={2} xl={1}>
						<SvgIcon component={item.logo} viewBox={item.viewBox} className={classes.icon} />
					</Grid>
				))}
				<Grid item md={2} xl={1}>
					<Box mt={-4}>
						<img
							src={process.env.PUBLIC_URL + "/images/scratch.png"}
							className={classes.image}
							alt="Scratch barcelona"
							style={{ mixBlendMode: "multiply" }}
						/>
					</Box>
				</Grid>
				<Grid item md={2} xl={1}>
					<Box mt={-2}>
						<img src={process.env.PUBLIC_URL + "/images/lebanna.png"} className={classes.image} alt="Lebanna" />
					</Box>
				</Grid>
				<Grid item md={2} xl={1}>
					<Box mt={-4}>
						<img src={process.env.PUBLIC_URL + "/images/la-galeta.png"} className={classes.image} alt="La Galeta" />
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};

export default Colaboradors;
