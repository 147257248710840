import axios from "axios";

const url = "https://api.sinapsisberga.cat/api/";

const Login = async (email, password) => {
	let message;
	await axios
		.post(url + "login", {
			email: email,
			password: password,
		})
		.then((response) => {
			if (response.status === 200) {
				localStorage.setItem("isLoggedIn", true);
				localStorage.setItem("user", JSON.stringify(response.data));
				message = "Login amb èxit";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "Email o contrasenya incorrecte";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return message;
};

const getEscoles = async () => {
	let message;
	let escoles;
	await axios
		.get(url + "escoles")
		.then((response) => {
			if (response.status === 200) {
				escoles = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { escoles, message };
};

const getEscola = async (id) => {
	let message;
	let escola;
	await axios
		.get(url + "escoles/" + id)
		.then((response) => {
			if (response.status === 200) {
				escola = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { escola, message };
};

const createEscola = async (values) => {
	const user = JSON.parse(localStorage.getItem("user"));
	const data = new FormData();
	data.append("nom", values.nom);
	data.append("color", "#" + values.color.hex);
	data.append("color_alt", "#" + values.color_alt.hex);
	if (values.imatge.name) {
		data.append("imatge", values.imatge, values.imatge.name);
	}
	let message;

	let config = {
		headers: {
			"Content-Type": "multipart/form-data",
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
			"Access-Control-Allow-Origin": "*",
		},
	};

	await axios.post(url + "escoles", data, config).then((response) => {
		if (response.status === 200) {
			message = "Escola guardada amb èxit";
		}
		if (response.data.status === "failed" && response.data.success === undefined) {
			message = "Error al crear l'escola";
		}
	});

	return message;
};

const updateEscola = async (values, key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	const data = new FormData();
	let message;
	data.append("nom", values.nom);
	data.append("color", values.color.hex ? "#" + values.color.hex : values.color);
	data.append("color_alt", values.color_alt.hex ? "#" + values.color_alt.hex : values.color_alt);
	console.log("#" + values.color);
	if (values.imatge.name) {
		data.append("imatge", values.imatge, values.imatge.name);
	}

	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
			"Access-Control-Allow-Origin": "*",
		},
	};

	await axios.post(url + "escoles/" + key, data, config).then((response) => {
		if (response.status === 200) {
			message = "Escola actualitzada amb èxit";
		}
		if (response.data.status === "failed" && response.data.success === undefined) {
			message = "Error al editar l'escola";
		}
	});

	return message;
};

const deleteEscola = async (id) => {
	const user = JSON.parse(localStorage.getItem("user"));

	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios.delete(url + "escoles/" + id, config).then((response) => {
		if (response.status === 200) {
			message = "Eliminat amb èxit";
		}
		if (response.data.status === "failed" && response.data.success === undefined) {
			message = "Error al eliminar l'escola";
		}
	});

	return message;
};

const getActivitats = async (escola) => {
	let message;
	let activitats;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
		},
	};
	await axios
		.get(url + "activitats?escola=" + escola, config)
		.then((response) => {
			if (response.status === 200) {
				activitats = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { activitats, message };
};

const getActivitat = async (key) => {
	let message;
	let activitat;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
		},
	};
	await axios
		.get(url + "activitats/" + key, config)
		.then((response) => {
			if (response.status === 200) {
				activitat = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { activitat, message };
};

const createActivitat = async (values) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios.post(url + "activitats", values, config).then((response) => {
		if (response.status === 200) {
			message = "Activitat guardada amb èxit";
		}
		if (response.data.status === "failed" && response.data.success === undefined) {
			message = "Error al crear activitat";
		}
	});

	return message;
};

const updateActivitat = async (values, key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios.put(url + "activitats/" + key, values, config).then((response) => {
		if (response.status === 200) {
			message = "Activitat actualitzada amb èxit";
		} else {
			message = "Error al editar l'activitat";
		}
	});

	return message;
};

const deleteActivitat = async (id) => {
	const user = JSON.parse(localStorage.getItem("user"));

	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios.delete(url + "activitats/" + id, config).then((response) => {
		if (response.status === 200) {
			message = "Eliminat amb èxit";
		}
		if (response.data.status === "failed" && response.data.success === undefined) {
			message = "Error al eliminar l'activitat";
		}
	});

	return message;
};

const changePublicada = async (key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios.get(url + "changePublicada/" + key, config).then((response) => {
		if (response.status === 200) {
			message = "Activitat actualitzada amb èxit";
		} else {
			message = "Error al editar l'activitat";
		}
	});

	return message;
};

const getAlumnes = async () => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let alumnes;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios
		.get(url + "alumnes", config)
		.then((response) => {
			if (response.status === 200) {
				alumnes = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { alumnes, message };
};

const getAlumne = async (key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let alumne;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios
		.get(url + "alumnes/" + key, config)
		.then((response) => {
			if (response.status === 200) {
				alumne = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { alumne, message };
};

const createAlumne = async (values) => {
	let message;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
		},
	};
	await axios
		.post(url + "inscripcio", values, config)
		.then((response) => {
			if (response.status === 200) {
				message = "Inscripció guardada! En breus rebràs un e-mail amb la confirmació. Gràcies!";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { message };
};

const inscripcioCasals = async (values) => {
	let message;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
		},
	};
	await axios
		.post(url + "inscripcioCasals", values, config)
		.then((response) => {
			if (response.status === 200) {
				message = "Inscripció guardada! En breus rebràs un e-mail amb la confirmació. Gràcies!";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { message };
};

const getNumeroInscrits = async () => {
	let numero;
	let config = {
		headers: {
			Accept: "application/json",
		},
	};
	await axios
		.get(url + "numeroInscrits", config)
		.then((response) => {
			if (response.status === 200) {
				numero = response.data.data;
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { numero };
};

const getInscrits = async () => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let alumnes;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios
		.get(url + "inscrits", config)
		.then((response) => {
			if (response.status === 200) {
				alumnes = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { alumnes, message };
};

const getInscrit = async (key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let alumne;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios
		.get(url + "inscrits/" + key, config)
		.then((response) => {
			if (response.status === 200) {
				alumne = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { alumne, message };
};

const updateField = async (values, key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
			"Access-Control-Allow-Origin": "*",
		},
	};

	await axios.post(url + "updateField/" + key, values, config).then((response) => {
		if (response.status === 200) {
			message = "Alumne actualitzat amb èxit";
		}
		if (response.data.status === "failed" && response.data.success === undefined) {
			message = "Error al editar l'alumne";
		}
	});

	return message;
};

const updateAlumne = async (values, key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
			"Access-Control-Allow-Origin": "*",
		},
	};

	await axios.post(url + "alumnes/" + key, values, config).then((response) => {
		if (response.status === 200) {
			message = "Alumne actualitzat amb èxit";
		}
		if (response.data.status === "failed" && response.data.success === undefined) {
			message = "Error al editar l'alumne";
		}
	});

	return message;
};

const deleteAlumne = async (id) => {
	const user = JSON.parse(localStorage.getItem("user"));

	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios.delete(url + "alumnes/" + id, config).then((response) => {
		if (response.status === 200) {
			message = "Eliminat amb èxit";
		} else {
			message = "Error al eliminar l'alumne";
		}
	});

	return message;
};

const getPacks = async (escola) => {
	let message;
	let packs;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
		},
	};
	await axios
		.get(url + "packs?escola=" + escola, config)
		.then((response) => {
			if (response.status === 200) {
				packs = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { packs, message };
};

const getPack = async (key) => {
	let message;
	let pack;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
		},
	};
	await axios
		.get(url + "packs/" + key, config)
		.then((response) => {
			if (response.status === 200) {
				pack = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { pack, message };
};

const createPack = async (values) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios.post(url + "packs", values, config).then((response) => {
		if (response.status === 200) {
			message = "Pack guardat amb èxit";
		}
		if (response.data.status === "failed" && response.data.success === undefined) {
			message = "Error al crear el pack";
		}
	});

	return message;
};

const updatePack = async (values, key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios.put(url + "packs/" + key, values, config).then((response) => {
		if (response.status === 200) {
			message = "Activitat actualitzada amb èxit";
		} else {
			message = "Error al editar l'activitat";
		}
	});

	return message;
};

const deletePack = async (id) => {
	const user = JSON.parse(localStorage.getItem("user"));

	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios.delete(url + "packs/" + id, config).then((response) => {
		if (response.status === 200) {
			message = "Eliminat amb èxit";
		}
		if (response.data.status === "failed" && response.data.success === undefined) {
			message = "Error al eliminar el pack";
		}
	});

	return message;
};

const getTweets = async () => {
	let message;
	let tweets;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
		},
	};
	await axios
		.get(url + "getTweets", config)
		.then((response) => {
			if (response.status === 200) {
				tweets = response.data;
				message = "Obtingut amb èxit";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { tweets, message };
};

const getButtons = async () => {
	let message;
	let buttons;
	await axios
		.get(url + "buttons")
		.then((response) => {
			if (response.status === 200) {
				buttons = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { buttons, message };
};

const getButton = async (id) => {
	let message;
	let button;
	await axios
		.get(url + "buttons/" + id)
		.then((response) => {
			if (response.status === 200) {
				button = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { button, message };
};

const createButton = async (values) => {
	const user = JSON.parse(localStorage.getItem("user"));
	const data = new FormData();
	data.append("nom", values.nom);
	data.append("subtitol", values.subtitol);
	data.append("icon", values.icon);
	data.append("url", values.url);
	if (values.file.name) {
		data.append("file", values.file, values.file.name);
	}
	let message;

	let config = {
		headers: {
			"Content-Type": "multipart/form-data",
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
			"Access-Control-Allow-Origin": "*",
		},
	};

	await axios.post(url + "buttons", data, config).then((response) => {
		if (response.status === 200) {
			message = "Botó guardada amb èxit";
		}
		if (response.data.status === "failed" && response.data.success === undefined) {
			message = "Error al crear el botó";
		}
	});

	return message;
};

const updateButton = async (values, key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	const data = new FormData();
	let message;
	data.append("nom", values.nom);
	data.append("subtitol", values.subtitol);
	data.append("icon", values.icon);
	data.append("url", values.url);

	if (values.file.name) {
		data.append("file", values.file, values.file.name);
	}

	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
			"Access-Control-Allow-Origin": "*",
		},
	};

	await axios.post(url + "buttons/" + key, data, config).then((response) => {
		if (response.status === 200) {
			message = "Botó actualitzat amb èxit";
		}
		if (response.data.status === "failed" && response.data.success === undefined) {
			message = "Error al editar el botó";
		}
	});

	return message;
};

const deleteButton = async (id) => {
	const user = JSON.parse(localStorage.getItem("user"));

	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios.delete(url + "buttons/" + id, config).then((response) => {
		if (response.status === 200) {
			message = "Eliminat amb èxit";
		}
		if (response.data.status === "failed" && response.data.success === undefined) {
			message = "Error al eliminar el botó";
		}
	});

	return message;
};

const contacte = async (values) => {
	const data = new FormData();
	let message;
	data.append("nom", values.nom);
	data.append("email", values.email);
	data.append("missatge", values.missatge);
	let config = {
		headers: {
			Accept: "application/json",
			"Access-Control-Allow-Origin": "*",
		},
	};

	await axios.post(url + "contacte", data, config).then((response) => {
		if (response.status === 200) {
			message = "Missatge enviat!";
		}
		if (response.data.status === "failed" && response.data.success === undefined) {
			message = "Error al enviar missatge. Prova-ho més tard";
		}
	});

	return message;
};

const descarregarPdf = async (id) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
			responseType: "blob",
		},
	};
	let message;
	let button;
	await axios
		.get(url + "inscritsPDF/" + id, config)
		.then((response) => {
			if (response.status === 200) {
				const link = document.createElement("a");
				link.href = response.data.url;
				link.setAttribute("download", "file.pdf");
				link.setAttribute("target", "_blank");
				document.body.appendChild(link);
				link.click();
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { button, message };
};

export {
	Login,
	getEscoles,
	createEscola,
	updateEscola,
	deleteEscola,
	getEscola,
	getActivitats,
	getActivitat,
	createActivitat,
	updateActivitat,
	deleteActivitat,
	changePublicada,
	getAlumnes,
	createAlumne,
	updateAlumne,
	deleteAlumne,
	getAlumne,
	getPack,
	getPacks,
	createPack,
	updatePack,
	deletePack,
	getTweets,
	getButtons,
	createButton,
	updateButton,
	deleteButton,
	getButton,
	contacte,
	inscripcioCasals,
	getInscrits,
	getInscrit,
	updateField,
	getNumeroInscrits,
	descarregarPdf,
};
