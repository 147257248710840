import { Box, Container, makeStyles, Paper, Table, TableCell, TableContainer, TableRow, Typography, TableBody } from "@material-ui/core";
import React, { useEffect } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Footer from "../../components/Footer";
import Page from "../../components/Page";

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: 30,
	},
	main: {
		[theme.breakpoints.down("sm")]: {
			width: "100%",
		},
	},
	image: {
		width: 150,
		textAlign: "center",
	},
}));

const Avis = () => {
	const classes = useStyles();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const rows = [
		{
			nom: "Denominació social: ",
			desc: "ISAAC SANTIAGO VILARDAGA",
		},
		{
			nom: "Nom comercial: ",
			desc: "Sinapsisberga",
		},
		{
			nom: "CIF",
			desc: "39376869H",
		},
		{
			nom: "Domicili social: ",
			desc: "Plaça Tarascón, 7 4º 4ª - 08600 Berga - BARCELONA (ESPANYA).",
		},
	];

	return (
		<Page title="Sinapsis - Avís legal">
			<ScrollAnimation animateIn="fadeIn" delay={300} style={{ transition: "0.5s" }}>
				<Container className={classes.root}>
					<Box className={classes.main} mb={8} mt={12}>
						<Typography variant="h2">Avís Legal</Typography>
					</Box>
					<Box my={4}>
						<Typography variant="h4">0. OBJECTE</Typography>
						<Typography variant="body1">
							<p>
								El present avís legal regula l'ús i utilització del lloc web www.sinapsisberga.cat, del qual és titular ISAAC SANTIAGO VILARDAGA
								(en endavant, Sinapsisberga).
							</p>
							<p>
								La navegació pel lloc web de Sinapsisberga li atribueix la condició d'USUARI del mateix i comporta la seva acceptació plena i
								sense reserves de totes i cadascuna de les condicions publicades en aquest avís legal, advertint que aquestes condicions podran
								ser modificades sense notificació prèvia per part de Sinapsisberga, cas en el qual es procedirà a la seva publicació i avís amb
								la màxima antelació possible.
							</p>
							<p>
								Per això és recomanable llegir atentament el seu contingut en cas de desitjar accedir i fer ús de la informació i dels serveis
								oferts des d'aquest lloc web.
							</p>
							<p>
								L'usuari a més, s'obliga a fer un ús correcte del lloc web de conformitat amb les lleis, la bona fe, l'ordre públic, els usos
								del trànsit i el present Avís Legal, i respondrà davant Sinapsisberga o davant tercers, de qualssevol danys i perjudicis que
								poguessin causar-se com a conseqüència de l'incompliment d'aquesta obligació.
							</p>
							<p>
								Qualsevol utilització diferent a l' autoritzada està expressament prohibida, podent Sinapsisberga denegar o retirar l' accés i
								el seu ús en qualsevol moment.
							</p>
						</Typography>
					</Box>
					<Box my={4}>
						<Typography variant="h4">1. IDENTIFICACIÓ</Typography>

						<TableContainer>
							<Table sx={{ minWidth: 650 }} aria-label="simple table">
								<TableBody>
									{rows?.map((row) => (
										<TableRow
											key={row.nom}
											sx={{
												"&:last-child td, &:last-child th": { border: 0 },
											}}
										>
											<TableCell>{row.nom}</TableCell>
											<TableCell>{row.desc}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>

					<Box my={4}>
						<Typography variant="h4">2. COMUNICACIONS</Typography>
						<Typography variant="body1">
							Per comunicar-se amb nosaltres, posem a la seva disposició diferents mitjans de contacte que detallem a continuació:
							<ul>
								<li>Tfn: 660841190.</li>
								<li>Email: sinapsis.berga@gmail.com</li>
							</ul>
							<p>
								Totes les notificacions i comunicacions entre els usuaris i Sinapsisberga es consideraran eficaces, a tots els efectes, quan es
								realitzin a través de qualsevol mitjà dels detallats anteriorment.
							</p>
						</Typography>
					</Box>
					<Box my={4}>
						<Typography variant="h4">3. CONDICIONS D' ACCÉS I UTILITZACIÓ</Typography>
						<Typography variant="body1">
							<p>
								El lloc web i els seus serveis són d'accés lliure i gratuït. No obstant això, Sinapsisberga pot condicionar la utilització
								d'alguns dels serveis oferts al seu web a la prèvia emplenament del corresponent formulari.
							</p>
							<p>
								L'usuari garanteix l'autenticitat i actualitat de totes aquelles dades que comuniqui a Sinapsisberga i serà l'únic responsable
								de les manifestacions falses o inexactes que realitzi.
							</p>
							<p>
								L'usuari es compromet expressament a fer un ús adequat dels continguts i serveis de Sinapsisberga i a no emprar-los per, entre
								d'altres:
							</p>
							<p>
								a) Difondre continguts delictius, violents, pornogràfics, racistes, xenòfobs, ofensius, d' apologia del terrorisme o, en
								general, contraris a la llei o a l' ordre públic.
							</p>
							<p>
								b) Introduir a la xarxa virus informàtics o realitzar actuacions susceptibles d' alterar, malmetre, interrompre o generar errors
								o danys en els documents electrònics, dades o sistemes físics i lògics de Sinapsisberga o de terceres persones; així com
								obstaculitzar l'accés d'altres usuaris al lloc web i als seus serveis mitjançant el consum massiu dels recursos informàtics a
								través dels quals Sinapsisberga presta els seus serveis.
							</p>
							<p>
								c) Intentar accedir als comptes de correu electrònic d' altres usuaris o a àrees restringides dels sistemes informàtics de
								Sinapsisberga o de tercers i, si s' escau, extreure informació.
							</p>
							<p>
								d) Vulnerar els drets de propietat intel·lectual o industrial, així com violar la confidencialitat de la informació de
								Sinapsisberga o de tercers.
							</p>
							<p>e) Suplantar la identitat de qualsevol altre usuari.</p>
							<p>
								f) Reproduir, copiar, distribuir, posar a disposició de, o qualsevol altra forma de comunicació pública, transformar o modificar
								els continguts, llevat que es compti amb l' autorització del titular dels corresponents drets o això resulti legalment permès.
							</p>
							<p>
								g) Recaptar dades amb finalitat publicitària i de remetre publicitat de qualsevol classe i comunicacions amb finalitats de venda
								o altres de naturalesa comercial sense que hi hagi la seva prèvia sol·licitud o consentiment.
							</p>

							<p>
								Tots els continguts del lloc web, com textos, fotografies, gràfics, imatges, icones, tecnologia, programari, així com el seu
								disseny gràfic i codis font, constitueixen una obra la propietat de la qual pertany a Sinapsisberga, sense que puguin entendre's
								cedits a l'usuari cap dels drets d'explotació sobre els mateixos més enllà de l'estrictament necessari per al correcte ús de la
								web.
							</p>
							<p>
								En definitiva, els usuaris que accedeixin a aquest lloc web poden visualitzar els continguts i efectuar, si s'escau, còpies
								privades autoritzades sempre que els elements reproduïts no siguin cedits posteriorment a tercers, ni s'instal·lin a servidors
								connectats a xarxes, ni siguin objecte de cap tipus d'explotació.
							</p>
							<p>
								Així mateix, totes les marques, noms comercials o signes distintius de qualsevol classe que apareixen al lloc web són propietat
								de Sinapsisberga, sense que es pugui entendre que l'ús o accés al mateix atribueixi a l'usuari cap dret sobre els mateixos.
							</p>
							<p>
								La distribució, modificació, cessió o comunicació pública dels continguts i qualsevol altre acte que no hagi estat expressament
								autoritzat pel titular dels drets d' explotació queden prohibits.
							</p>
							<p>
								L'establiment d'un enllaç no implica en cap cas l'existència de relacions entre Sinapsisberga i el propietari del lloc web en la
								qual s'estableixi, ni l'acceptació i aprovació per part de Sinapsisberga dels seus continguts o serveis.
							</p>
							<p>
								Sinapsisberga no es responsabilitza de l'ús que cada usuari li doni als materials posats a disposició en aquest lloc web ni de
								les actuacions que realitzi en base als mateixos.
							</p>
						</Typography>
					</Box>
					<Box my={4}>
						<Typography variant="h4">3.1 EXCLUSIÓ DE GARANTIES I DE RESPONSABILITAT EN L' ACCÉS I LA UTILITZACIÓ</Typography>
						<Typography variant="body1">
							<p>
								El contingut del present lloc web és de caràcter general i té una finalitat merament informativa, sense que es garanteixi
								plenament l'accés a tots els continguts, ni la seva exhaustivitat, correcció, vigència o actualitat, ni la seva idoneïtat o
								utilitat per a un objectiu específic.
							</p>
							<p>
								Sinapsisberga exclou, fins on permet l' ordenament jurídic, qualsevol responsabilitat pels danys i perjudicis de tota naturalesa
								derivats de:
							</p>
							<p>
								a) La impossibilitat d'accés al lloc web o la falta de veracitat, exactitud, exhaustivitat i/o actualitat dels continguts, així
								com l'existència de vicis i defectes de tota classe dels continguts transmesos, difosos, emmagatzemats, posats a disposició, als
								quals s'hagi accedit a través del lloc web o dels serveis que s'ofereixen.
							</p>
							<p>
								b) La presència de virus o d' altres elements en els continguts que puguin produir alteracions en els sistemes informàtics,
								documents electrònics o dades dels usuaris.
							</p>

							<p>
								c) L'incompliment de les lleis, la bona fe, l'ordre públic, els usos del trànsit i el present Avís Legal com a conseqüència de
								l'ús incorrecte del lloc web. En particular, i a mode exemplificatiu, Sinapsisberga no es fa responsable de les actuacions de
								tercers que vulnerin drets de propietat intel·lectual i industrial, secrets empresarials, drets a l'honor, a la intimitat
								personal i familiar i a la pròpia imatge, així com la normativa en matèria de competència deslleial i publicitat il·lícita.
							</p>

							<p>
								Així mateix, Sinapsisberga declina qualsevol responsabilitat respecte a la informació que es trobi fora d'aquesta web i no sigui
								gestionada directament pel nostre webmaster. La funció dels links que apareixen en aquesta web és exclusivament la d'informar
								l'usuari sobre l'existència d'altres fonts susceptibles d'ampliar els continguts que ofereix aquest lloc web.
							</p>
							<p>
								Sinapsisberga no garanteix ni es responsabilitza del funcionament o accessibilitat dels llocs enllaçats; ni suggereix, convida o
								recomana la visita als mateixos, per la qual cosa tampoc serà responsable del resultat obtingut. Sinapsisberga no es
								responsabilitza de l'establiment d'enllaços per part de tercers.
							</p>
						</Typography>
					</Box>
					<Box my={4}>
						<Typography variant="h4">3.2 PROCEDIMENT EN CAS DE REALITZACIÓ D' ACTIVITATS DE CARÀCTER IL·LÍCIT</Typography>
						<Typography variant="body1">
							En el cas que qualsevol usuari o un tercer consideri que existeixen fets o circumstàncies que revelin el caràcter il·lícit de la
							utilització de qualsevol contingut i/o de la realització de qualsevol activitat a les pàgines web incloses o accessibles a través
							del lloc web, haurà d'enviar una notificació a Sinapsisberga identificant-se degudament i especificant les suposades infraccions.
						</Typography>
					</Box>
					<Box my={4}>
						<Typography variant="h4">3.3 PUBLICACIONS</Typography>
						<Typography variant="body1">
							La informació administrativa facilitada a través del lloc web no substitueix la publicitat legal de les lleis, normatives, plans,
							disposicions generals i actes que hagin de ser publicats formalment als diaris oficials de les administracions públiques, que
							constitueixen l'únic instrument que dona fe de la seva autenticitat i contingut. La informació disponible en aquest lloc web s'ha
							d'entendre com una guia sense propòsit de validesa legal.
						</Typography>
					</Box>

					<Box my={4}>
						<Typography variant="h4">4. LEGISLACIÓ APLICABLE</Typography>
						<Typography variant="body1">
							<p>Les condicions presents es regiran per la legislació espanyola vigent.</p>
							<p>La llengua utilitzada serà el Català.</p>
						</Typography>
					</Box>
				</Container>
			</ScrollAnimation>
			<Footer />
		</Page>
	);
};

export default Avis;
