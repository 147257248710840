import { Box, useMediaQuery } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import React from "react";
import Page from "../../components/Page";
import Portada from "./Portada";

const useStyles = makeStyles((theme) => ({
    "@global": {
        body: {
            overflow: "hidden",
        },
        ".makeStyles-wrapper-2": {
            padding: 0,
        },
    },
    destacat: {
        color: theme.palette.third.main,
    },
    boxQuisom: {
        backgroundColor: "white",
        borderRadius: 50,
        padding: 100,
        width: "60%",
    },
    boxQuisomM: {
        // backgroundColor: "white",
        borderRadius: 0,
        padding: 30,
        paddingTop: 100,
        paddingBottom: 100,
        backgroundColor: theme.palette.background.secondary,
        marginBottom: 100,
    },
    boxEscoles: {
        width: "55%",
        justifyContent: "center",
    },
}));

const QueFem = () => {
    const classes = useStyles();
    const matches = useMediaQuery("(min-width:960px)");

    return (
        <Box className={matches ? classes.boxQuisom : classes.boxQuisomM}>
            <Typography variant="h2" className={classes.destacat}>
                QUÈ FEM?
            </Typography>
            <Box mt={3}>
                <Grid container spacing={3}>
                    <Grid item md={3} sx={12}>
                        <Typography variant="body1">
                            Les{" "}
                            <strong className={classes.destacat}>
                                ACTIVITATS EXTRAESCOLARS
                            </strong>{" "}
                            son una de les branques principals del projecte.
                            Gestionem les escoles de la ciutat de Berga i
                            col.laborem amb altres empreses per tal d’oferir un
                            servei integral. Des de les{" "}
                            <strong className={classes.destacat}>
                                ACOLLIDES
                            </strong>{" "}
                            matinals, fins a activitats de migdia i tarda, també
                            activitats fora de l’escola en altres espais.
                        </Typography>
                    </Grid>
                    <Grid item md={3} sx={12}>
                        <Typography variant="body1">
                            El{" "}
                            <strong className={classes.destacat}>
                                MONITORATGE DE MENJADOR
                            </strong>{" "}
                            també és una dels nostres focus de treball, on al
                            llarg dels anys hem creat equips competents
                            provinents del lleure, que s’impliquen en aquesta
                            franja diaria del desenvolupament i creixement dels
                            infants.
                        </Typography>
                    </Grid>
                    <Grid item md={3} sx={12}>
                        <Typography variant="body1">
                            <strong className={classes.destacat}>
                                LET’S PLAY
                            </strong>{" "}
                            és la nostra marca en casals d’estiu de lleure i
                            esportius. Els últims 8 anys el Casal ha pres vida a
                            Berga, però també n’hem desenvolupat en altres
                            indrets de la comarca.
                        </Typography>
                    </Grid>
                    <Grid item md={3} sx={12}>
                        <Typography variant="body1">
                            Els{" "}
                            <strong className={classes.destacat}>
                                TALLERS i JORNADES
                            </strong>{" "}
                            de lleure educatiu són propostes d’activitats
                            concretes per realitzar dins i fora de l’horari
                            lectiu. Així podem treballar la iniciació esportiva,
                            les emocions, la cohesió i els valors en format
                            gimcana.
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default QueFem;
