import Page from "../../components/Page";
import { useForm } from "react-hook-form";
import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Collapse,
	Container,
	FormControl,
	FormControlLabel,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Typography,
	useMediaQuery,
} from "@material-ui/core";
import Input from "../../components/CustomInput";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "../../components/Schema";
import { useEffect, useState } from "react";
import moment from "moment";
import Footer from "../../components/Footer";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { getNumeroInscrits, inscripcioCasals } from "../../database/API";
import { useSnackbar } from "notistack";
import { constructFormInscripcioCasals } from "../../database/ConstructForm";
import { useNavigate } from "react-router";

export default function InscripcionsCasals() {
	const navigate = useNavigate();
	const classes = useStyles();
	const [preu, setPreu] = useState(0);
	const matches = useMediaQuery("(min-width:960px)");
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [limit, setLimit] = useState(0);
	const { enqueueSnackbar } = useSnackbar();
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		watch,
		reset,
	} = useForm({
		mode: "onTouched",
		resolver: yupResolver(schema),
	});

	useEffect(() => {
		setValue("setmanes", []);
	}, []);

	useEffect(() => {
		let p = 0;
		if (watch("ampa") || watch("nombrosa")) {
			p = preus[watch("setmanes")?.length]?.preuD;
		} else {
			p = preus[watch("setmanes")?.length]?.preu;
		}
		// if (watch("recollida")) {
		// 	p = p + watch("setmanes")?.length * 8;
		// }
		// if (watch("menjador")) {
		// 	p = p + watch("setmanes")?.length * 5 * 8.5;
		// }
		if (watch("carmanyola")) {
			p = p + watch("setmanes")?.length * 20;
		}
		setPreu(p);
	}, [watch("setmanes"), watch("ampa"), watch("nombrosa"), watch("carmanyola")]);

	useEffect(() => {
		const years = moment().diff(watch("data_naixement"), "years");
		setValue("edat", years);
	}, [watch("data_naixement")]);

	useEffect(() => {
		const obtenir = async () => {
			setIsSubmitting(true);
			const { numero } = await getNumeroInscrits();
			setLimit(numero);
			setIsSubmitting(false);
		};
		if (watch("ubicacio") === "Escola La Valldan") {
			console.log(watch("carmanyola"));
			setValue("menjador", false, true);
			setValue("carmanyola", false, true);
		} else if (watch("ubicacio") === "Escola Sant Joan") {
			setValue("recollida", false, true);
		} else {
			obtenir();
			setValue("menjador", false, true);
			setValue("carmanyola", false), true;
			setValue("recollida", false, true);
		}
	}, [watch("ubicacio")]);

	const afegirSetmana = (setmana) => {
		if (watch("setmanes")?.some((i) => i.nom === setmana.nom)) {
			setValue(
				"setmanes",
				watch("setmanes")?.filter((i) => i.nom !== setmana.nom)
			);
		} else {
			setValue("setmanes", [...watch("setmanes"), setmana]);
		}
	};

	const enviar = async (values) => {
		console.log(values);
		setIsSubmitting(true);
		setValue("preu", preu);
		try {
			const { message } = await inscripcioCasals(constructFormInscripcioCasals(values));
			enqueueSnackbar(message, { variant: "success" });
		} catch (e) {
			enqueueSnackbar("Alguna cosa no ha anat bé...", { variant: "error" });
			console.log(e);
		}
		reset();
		navigate("/");
		setIsSubmitting(false);
	};

	return (
		<Page title="Inscripcions Casals d'estiu">
			<Box mb={5} mt={20}>
				<Typography variant="h1" style={{ textAlign: "center" }}>
					Inscripcions Casals d'Estiu
				</Typography>
			</Box>
			<Container>
				<form onSubmit={handleSubmit(enviar)}>
					<Typography variant="h2" style={{ textAlign: "center" }}>
						Omple les dades
					</Typography>
					<Box my={10} />

					<Typography variant="h4" style={{ textAlign: "center" }}>
						Dades personals
					</Typography>
					<Grid spacing={3} container>
						<Grid item md={12} xs={12}>
							<Input
								error={Boolean(errors?.nom)}
								fullWidth
								helperText={errors?.nom?.message}
								label="Nom i cognoms de l'alumne"
								margin="normal"
								variant="outlined"
								type="text"
								{...register("nom")}
							/>
						</Grid>
						<Grid item md={4} xs={12}>
							<Input
								error={Boolean(errors?.data_naixement)}
								fullWidth
								helperText={errors?.data_naixement?.message}
								label="Data naixement"
								margin="normal"
								variant="outlined"
								type="date"
								InputLabelProps={{
									shrink: true,
								}}
								{...register("data_naixement")}
							/>
						</Grid>
						<Grid item md={4} xs={12}>
							<Input
								error={Boolean(errors?.edat)}
								fullWidth
								helperText={errors?.edat?.message}
								label="Edat"
								margin="normal"
								variant="outlined"
								type="number"
								InputLabelProps={{
									shrink: true,
								}}
								{...register("edat")}
							/>
						</Grid>
						<Grid item md={4} xs={12} style={{ marginTop: 15 }}>
							<FormControl
								fullWidth
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
							>
								<InputLabel htmlFor="curs">Curs</InputLabel>
								<Select {...register("curs")} fullWidth variant="outlined" label={"Curs"} defaultValue={"I3"}>
									{cursos?.map((item) => (
										<MenuItem key={item} value={item}>
											{item}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item md={4} xs={12}>
							<Input
								error={Boolean(errors?.adreca)}
								fullWidth
								helperText={errors?.adreca?.message}
								label="Adreça (númerio, pis...)"
								margin="normal"
								variant="outlined"
								type="text"
								{...register("adreca")}
							/>
						</Grid>
						<Grid item md={4} xs={12}>
							<Input
								error={Boolean(errors?.codi_postal)}
								fullWidth
								helperText={errors?.codi_postal?.message}
								label="Codi postal"
								margin="normal"
								variant="outlined"
								type="text"
								{...register("codi_postal")}
							/>
						</Grid>
						<Grid item md={4} xs={12}>
							<Input
								error={Boolean(errors?.poblacio)}
								fullWidth
								helperText={errors?.poblacio?.message}
								label="Població"
								margin="normal"
								variant="outlined"
								type="text"
								{...register("poblacio")}
							/>
						</Grid>
						<Grid item md={4} xs={12}>
							<Input
								error={Boolean(errors?.email)}
								fullWidth
								helperText={errors?.email?.message}
								label="E-mail"
								margin="normal"
								variant="outlined"
								type="text"
								{...register("email")}
							/>
						</Grid>
						<Grid item md={4} xs={12}>
							<Input
								error={Boolean(errors?.telefon)}
								fullWidth
								helperText={errors?.telefon?.message}
								label="Telèfon"
								margin="normal"
								variant="outlined"
								type="text"
								{...register("telefon")}
							/>
						</Grid>
						<Grid item md={4} xs={12}>
							<Input
								error={Boolean(errors?.telefon_alt)}
								fullWidth
								helperText={errors?.telefon_alt?.message}
								label="Telèfon alternatiu"
								margin="normal"
								variant="outlined"
								type="text"
								{...register("telefon_alt")}
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<Input
								error={Boolean(errors?.recollir)}
								fullWidth
								helperText={errors?.recollir?.message}
								label="Qui vindrà a recollir el nen/a?"
								margin="normal"
								variant="outlined"
								type="text"
								{...register("recollir")}
							/>
						</Grid>

						<Grid item md={6} xs={12}>
							<Input
								error={Boolean(errors?.colegi)}
								fullWidth
								helperText={errors?.colegi?.message}
								label="Alumne del col·legi"
								margin="normal"
								variant="outlined"
								type="text"
								{...register("colegi")}
							/>
						</Grid>
						{/* <Grid item md={12} xs={12} style={{ marginTop: 15 }}>
							<FormControl
								fullWidth
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
							>
								<InputLabel htmlFor="ubicacio">A quin Casal d'Estiu vols inscriure't?</InputLabel>
								<Select
									{...register("ubicacio")}
									fullWidth
									variant="outlined"
									label={"A quin Casal d'Estiu vols inscriure't?"}
									defaultValue={"Escola d'Olvan"}
								>
									{ubicacio?.map((item) => (
										<MenuItem key={item} value={item}>
											{item}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid> */}
					</Grid>
					{/* <Collapse in={watch("ubicacio") === "Escola La Valldan"}>
						<Grid spacing={3} container>
							<Grid item md={12} xs={12}>
								<FormControlLabel
									control={<Checkbox {...register("recollida")} />}
									label="Utilitzareu el servei d'acollida de 8 a 9 del matí per 8 euros setmanals?"
								/>
							</Grid>
						</Grid>
					</Collapse>
					<Collapse in={watch("ubicacio") === "Escola Sant Joan" || watch("ubicacio") === "Escola La Valldan"}>
						<Grid spacing={3} container>
							<Grid item md={12} xs={12}>
								<FormControlLabel
									control={<Checkbox {...register("menjador")} />}
									label="Utilitzareu el servei de menjador per 8,5€ diaris el menú?"
								/>
							</Grid>
						</Grid>
					</Collapse> */}
					{/* <Collapse in={watch("ubicacio") === "Escola Sant Joan"}> */}
					<Grid spacing={3} container>
						<Grid item md={12} xs={12}>
							<FormControlLabel control={<Checkbox {...register("carmanyola")} />} label="Es quedarà a dinar amb carmanyola fins les 15.00h?" />
						</Grid>
					</Grid>
					{/* </Collapse> */}
					{/* <Collapse in={watch("ubicacio") === "Escola d'Olvan"}> */}
					{/* <Typography variant="body1">
							<small>Limit: {limit}/20</small>
						</Typography>
						{limit >= 20 && (
							<Typography variant="body1" style={{ color: "red" }}>
								<small>S'ha arribat al màxim d'inscrits</small>
							</Typography>
						)} */}

					{/* <Grid spacing={3} container>
							<Grid item md={12} xs={12}>
								<FormControlLabel control={<Checkbox {...register("piscina")} />} label="Farà cursets de piscina?" />
							</Grid>
						</Grid> */}
					{/* </Collapse> */}
					<Box my={3} />
					<Grid spacing={3} container pt={4}>
						<Grid item md={6} xs={12}>
							<Input
								error={Boolean(errors?.pare)}
								fullWidth
								helperText={errors?.pare?.message}
								label="Nom del pare"
								margin="normal"
								variant="outlined"
								type="text"
								{...register("pare")}
							/>
						</Grid>

						<Grid item md={6} xs={12}>
							<Input
								error={Boolean(errors?.mare)}
								fullWidth
								helperText={errors?.mare?.message}
								label="Nom de la mare"
								margin="normal"
								variant="outlined"
								type="text"
								{...register("mare")}
							/>
						</Grid>

						<Grid item md={6} xs={12}>
							<FormControlLabel control={<Checkbox {...register("ampa")} />} label="Soci AFA" />
						</Grid>

						<Grid item md={6} xs={12}>
							<FormControlLabel control={<Checkbox {...register("nombrosa")} />} label="Família nombrosa" />
						</Grid>
					</Grid>
					<Box my={10} />
					<Typography variant="h4" style={{ textAlign: "center" }}>
						Setmanes que assistirà
					</Typography>

					<Typography style={{ textAlign: "center" }}>Sel·lecciona les setmanes que assistirà el nen/a</Typography>
					<Box mt={2} direction={"row"} spacing={2} justifyContent="center" display={matches ? "flex" : "block"}>
						{setmanes?.map((item) => (
							<Box
								key={item.id}
								className={clsx(
									classes.setmana,
									watch("setmanes")?.some((i) => i.nom === item?.nom) && classes.selected,
									item?.disabled ? classes.disabled : ""
								)}
								onClick={item?.disabled ? () => {} : () => afegirSetmana(item)}
							>
								<Box className={classes.check}></Box>
								<Box mr={1} ml={1} textAlign="center">
									<Typography pb={0} mb={-1}>
										{item?.nom}
									</Typography>
									<Typography variant="caption" style={{ fontSize: 12 }}>
										{item?.dies}
									</Typography>
								</Box>
							</Box>
						))}
					</Box>

					<Box my={10} />
					<Typography variant="h4" style={{ textAlign: "center" }}>
						Fitxa mèdica
					</Typography>
					<Grid container spacing={3}>
						<Grid item md={6} xs={12}>
							<Input
								error={Boolean(errors?.metge)}
								fullWidth
								helperText={errors?.metge?.message}
								label="Metge o pediatre que en fa el seguiment"
								margin="normal"
								variant="outlined"
								type="text"
								{...register("metge")}
							/>
						</Grid>

						<Grid item md={6} xs={12}>
							<Input
								error={Boolean(errors?.ss)}
								fullWidth
								helperText={errors?.ss?.message}
								label="Número de la targeta de la Seguretat Social / Mútua"
								margin="normal"
								variant="outlined"
								type="text"
								{...register("ss")}
							/>
						</Grid>

						<Grid item md={12} xs={12}>
							<Input
								error={Boolean(errors?.personalitat)}
								fullWidth
								helperText={errors?.personalitat?.message}
								label="Alguna característica a destacar de la seva personalitat?"
								margin="normal"
								variant="outlined"
								type="text"
								multiline
								rows={5}
								{...register("personalitat")}
							/>
						</Grid>
						<Grid item md={12} xs={12} style={{ paddingBottom: 0 }}>
							<FormControlLabel control={<Checkbox {...register("alergia")} />} label="Té al·lèrgia?" />
						</Grid>
						<Grid item md={12} xs={12} style={{ paddingTop: 0 }}>
							<Collapse in={watch("alergia") === true}>
								<Input
									error={Boolean(errors?.alergies)}
									fullWidth
									helperText={errors?.alergies?.message}
									label="Quines al·lèrgies?"
									margin="normal"
									variant="outlined"
									type="text"
									multiline
									rows={3}
									{...register("alergies")}
								/>
							</Collapse>
						</Grid>
						<Grid item md={12} xs={12} style={{ paddingBottom: 0 }}>
							<FormControlLabel control={<Checkbox {...register("regim")} />} label="Segueix un règim alimentari especial?" />
						</Grid>
						<Grid item md={12} xs={12} style={{ paddingTop: 0 }}>
							<Collapse in={watch("regim") === true}>
								<Input
									error={Boolean(errors?.regims)}
									fullWidth
									helperText={errors?.regims?.message}
									label="Quin règim?"
									margin="normal"
									variant="outlined"
									type="text"
									multiline
									rows={3}
									{...register("regims")}
								/>
							</Collapse>
						</Grid>
						<Grid item md={12} xs={12}>
							<Input
								error={Boolean(errors?.observacions)}
								fullWidth
								helperText={errors?.observacions?.message}
								label="Observacions"
								margin="normal"
								variant="outlined"
								type="text"
								multiline
								rows={5}
								{...register("observacions")}
							/>
						</Grid>
						<Grid item md={4} xs={12}>
							<InputLabel>Fotografia del carnet de vacunes</InputLabel>
							<Box my={3} />
							<input
								error={Boolean(errors?.foto_ss)}
								fullWidth
								helperText={errors?.foto_ss?.message}
								margin="normal"
								variant="outlined"
								type="file"
								{...register("foto_ss")}
							/>
							<Typography variant="body1" style={{ fontSize: 12, paddingTop: 10 }}>
								En format imatge
							</Typography>
							<Typography variant="body1" style={{ fontSize: "0.8rem", color: "#ea7c7c" }}>
								{errors?.foto_ss?.message}
							</Typography>
						</Grid>
						<Grid item md={4} xs={12}>
							<InputLabel>Fotografia de la targeta sanitària</InputLabel>
							<Box my={3} />
							<input
								error={Boolean(errors?.foto_targeta)}
								fullWidth
								helperText={errors?.foto_targeta?.message}
								margin="normal"
								variant="outlined"
								type="file"
								{...register("foto_targeta")}
							/>
							<Typography variant="body1" style={{ fontSize: 12, paddingTop: 10 }}>
								En format imatge
							</Typography>
							<Typography variant="body1" style={{ fontSize: "0.8rem", color: "#ea7c7c" }}>
								{errors?.foto_targeta?.message}
							</Typography>
						</Grid>

						<Grid item md={4} xs={12}>
							<InputLabel>Fotografia del nen/a</InputLabel>
							<Box my={3} />
							<input
								error={Boolean(errors?.foto_nen)}
								fullWidth
								helperText={errors?.foto_nen?.message}
								margin="normal"
								variant="outlined"
								type="file"
								{...register("foto_nen")}
							/>
							<Typography variant="body1" style={{ fontSize: 12, paddingTop: 10 }}>
								En format imatge
							</Typography>
							<Typography variant="body1" style={{ fontSize: "0.8rem", color: "#ea7c7c" }}>
								{errors?.foto_nen?.message}
							</Typography>
						</Grid>
					</Grid>
					<Box my={3} />

					<Grid container spacing={3}>
						<Grid item md={12}>
							<FormControlLabel
								control={<Checkbox {...register("imatge")} />}
								label="Autoritzes que la imatge de l'infant sigui utilitzada en els materials de difusió de Sinapsis (Facebook,
                                                    Instagram i Twitter)?"
							/>
						</Grid>
					</Grid>
					<Grid container spacing={3}>
						<Grid item md={12}>
							<FormControlLabel
								control={<Checkbox {...register("accepta")} />}
								label={
									<Typography>
										Accepta que has llegit i estas d'acord amb la{" "}
										<a href="/politica-privacitat" target="_blank">
											Política de Privacitat
										</a>
									</Typography>
								}
							/>
						</Grid>
						<Typography variant="body1" style={{ fontSize: "0.8rem", color: "#ea7c7c" }}>
							{errors?.accepta?.message}
						</Typography>
						<Grid item md={12}>
							<FormControlLabel
								control={<Checkbox {...register("assistir")} />}
								label={
									<Typography>
										Autoritzo al meu fill/a perquè assisteixi al Casal d’estiu organitzat per Sinapsis que es realitzarà del 26 de juny al
										28 de juliol del 2023, a l'escola en les condicions establertes.
									</Typography>
								}
							/>
						</Grid>
						<Typography variant="body1" style={{ fontSize: "0.8rem", color: "#ea7c7c" }}>
							{errors?.assistir?.message}
						</Typography>
					</Grid>

					<Box my={3} />
					<Typography variant="h4">Preu: {preu} €</Typography>

					<Box my={5}>
						<Button color="primary" fullWidth size="large" type="submit" disabled={isSubmitting} variant="contained">
							{!isSubmitting ? "INSCRIURE'S!" : <CircularProgress size={26} color="third" />}
						</Button>
					</Box>
				</form>
			</Container>
			<Footer />
		</Page>
	);
}

const cursos = ["I3", "I4", "I5", "P1", "P2", "P3", "P4", "P5", "P6"];

const setmanes = [
	{ nom: "1a setmana", dies: "del 25 al 28 de juny", disabled: true },
	{ nom: "2a setmana", dies: "del 1 al 5 de juliol", disabled: true },
	{ nom: "3a setmana", dies: "del 8 al 12 de juliol", disabled: false },
	{ nom: "4a setmana", dies: "del 15 al 19 de juliol", disabled: false },
	{ nom: "5a setmana", dies: "del 22 al 26 de juliol", disabled: true },
	{ nom: "6a setmana", dies: "del 2 al 6 de setembre", disabled: true },
];

const preus = [
	{ setmanes: 0, preu: 0, preuD: 0 },
	{ setmanes: 1, preu: 65, preuD: 60 },
	{ setmanes: 2, preu: 120, preuD: 110 },
	{ setmanes: 3, preu: 177, preuD: 162 },
	{ setmanes: 4, preu: 234, preuD: 214 },
	{ setmanes: 5, preu: 291, preuD: 266 },
	{ setmanes: 6, preu: 348, preuD: 318 },
];

const ubicacio = ["Escola Sant Joan", "Escola d'Olvan", "Escola La Valldan"];

const useStyles = makeStyles((theme) => ({
	setmana: {
		padding: 10,
		backgroundColor: "white",
		margin: 5,
		borderRadius: 10,
		transition: "0.2s",
		cursor: "pointer",
		opacity: 0.5,
	},
	selected: {
		backgroundColor: "green",
		opacity: 1,
		"& .MuiTypography-root": {
			color: "white",
		},
	},
	disabled: {
		opacity: 0.2,
		cursor: "not-allowed",
	},
}));
