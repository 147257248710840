import { Grow, IconButton, ThemeProvider } from "@material-ui/core";
import theme from "./theme";
import routes from "./routes";
import { useRoutes } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { SnackbarProvider } from "notistack";
import { Close } from "@material-ui/icons";
import PoliticaDeCookies from "./components/PoliticaDeCookies";

function App() {
	const [user, setUser] = useState("");
	const [tancar, setTancar] = useState(false);
	const routing = useRoutes(routes(user));
	const notistackRef = React.createRef();
	const onClickDismiss = (key) => () => {
		notistackRef.current.closeSnackbar(key);
	};

	useEffect(() => {
		const t = localStorage.getItem("tancar");
		setTancar(t);
	}, []);

	useEffect(() => {
		const loggedInUser = localStorage.getItem("isLoggedIn");
		if (loggedInUser) {
			const foundUser = JSON.parse(loggedInUser);
			setUser(foundUser);
		}
	}, [routing]);

	return (
		<ThemeProvider theme={theme}>
			<SnackbarProvider
				maxSnack={3}
				anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
				TransitionComponent={Grow}
				ref={notistackRef}
				action={(key) => (
					<IconButton onClick={onClickDismiss(key)}>
						<Close style={{ color: "white" }} />
					</IconButton>
				)}
			>
				{routing}
			</SnackbarProvider>
			{!tancar && <PoliticaDeCookies setTancar={setTancar} />}
		</ThemeProvider>
	);
}

export default App;
