import React from "react";
import { Navigate } from "react-router";
import AdminNavBar from "./layouts/Admin/AdminNavBar";
import PublicNavBar from "./layouts/Public/PublicNavBar";
import UsersAdmin from "./views/Admin/Users/UsersAdmin";
import EscolesAdd from "./views/Admin/Escoles/EscolesAdd";
import EscolesView from "./views/Admin/Escoles/EscolesAdmin";
import EscolesEdit from "./views/Admin/Escoles/EscolesEdit";
import ActivitatsAdmin from "./views/Admin/Activitats/ActivitatsAdmin";
import LoginView from "./views/Auth/LoginView";
import Home from "./views/Home/Home";
import Inscripcions from "./views/Inscripcions/Inscripcions";
import ActivitatAdd from "./views/Admin/Activitats/ActivitatsAdd";
import ActivitatsEdit from "./views/Admin/Activitats/ActivitatsEdit";
import Politica from "./views/Politica/Politica";
import ActivitatsView from "./views/Admin/Activitats/ActivitatsView";
import UserView from "./views/Admin/Users/UserView";
import UserEdit from "./views/Admin/Users/UserEdit";
import UserAdd from "./views/Admin/Users/UserAdd";
import PacksAdmin from "./views/Admin/Packs/PacksAdmin";
import PackAdd from "./views/Admin/Packs/PackAdd";
import PackView from "./views/Admin/Packs/PackView";
import Informacio from "./views/Info/Informacio";
import Avis from "./views/Politica/Avis";
import ButtonsAdmin from "./views/Admin/Buttons/ButtonsAdmin";
import ButtonEdit from "./views/Admin/Buttons/ButtonEdit";
import ButtonAdd from "./views/Admin/Buttons/ButtonAdd";
import Contacte from "./views/Contacte/Contacte";
import InscripcionsCasals from "./views/Inscripcions/InscripcionsCasals";
import InscritsAdmin from "./views/Admin/InscritsCasals/InscritsAdmin";
import InscritView from "./views/Admin/InscritsCasals/InscritView";
import Cookies from "./views/Politica/Cookies";
const routes = (user) => [
	{
		path: "/admin",
		element: user ? <AdminNavBar /> : <Navigate to="/login" />,
		children: [
			{ path: "/alumnes", element: <UsersAdmin /> },
			{ path: "/alumnes/nou", element: <UserAdd /> },
			{ path: "/alumnes/:key", element: <UserView /> },
			{ path: "/alumnes/:key/edit", element: <UserEdit /> },
			{ path: "/escoles", element: <EscolesView /> },
			{ path: "/escoles/nou", element: <EscolesAdd /> },
			{ path: "/escoles/:key", element: <EscolesEdit /> },
			{ path: "/activitats", element: <ActivitatsAdmin /> },
			{ path: "/activitats/nou", element: <ActivitatAdd /> },
			{ path: "/activitats/:key", element: <ActivitatsView /> },
			{ path: "/activitats/:key/edit", element: <ActivitatsEdit /> },
			{ path: "/activitats/packs", element: <PacksAdmin /> },
			{ path: "/activitats/packs/nou", element: <PackAdd /> },
			{ path: "/activitats/packs/:key", element: <PackView /> },
			{ path: "/buttons", element: <ButtonsAdmin /> },
			{ path: "/buttons/nou", element: <ButtonAdd /> },
			{ path: "/buttons/:key", element: <ButtonEdit /> },
			{ path: "/inscrits", element: <InscritsAdmin /> },
			{ path: "/inscrits/:key", element: <InscritView /> },
			{ path: "/inscrits/:key/edit", element: <UserEdit /> },
		],
	},
	{
		path: "/",
		element: <PublicNavBar />,
		children: [
			{ path: "/", element: <Home /> },
			{ path: "/inscripcions", element: <Inscripcions /> },
			{ path: "/inscripcions-casals", element: <InscripcionsCasals /> },
			{ path: "/politica-privacitat", element: <Politica /> },
			{ path: "/politica-cookies", element: <Cookies /> },
			{ path: "/avis-legal", element: <Avis /> },
			{ path: "/informacio", element: <Informacio /> },
			{ path: "/contacte", element: <Contacte /> },
			{
				path: "login",
				element: !user ? <LoginView /> : <Navigate to="/admin/alumnes" />,
			},
		],
	},
];

export default routes;
