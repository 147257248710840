import { Grid } from "@material-ui/core";
import { SvgIcon } from "@material-ui/core";
import { Link } from "@material-ui/core";
import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useNavigate } from "react-router";
import { ReactComponent as Logo } from "../theme/icons/logo-nou.svg";

const useStyles = makeStyles((theme) => ({
	wrapFooter: {
		paddingTop: 100,
		paddingBottom: 100,
		// backgroundColor: theme.palette.background.default,
		zIndex: 0,
		width: "100%",
	},
	icon: {
		fill: "white",
		width: 200,
		height: 200,
	},
}));

const Footer = () => {
	const classes = useStyles();
	const navigate = useNavigate();

	return (
		<Box className={classes.wrapFooter}>
			<Grid container spacing={10}>
				<Grid item md={4} xs={12}></Grid>
				<Grid item md={4} xs={12}>
					<Box my={2} style={{ cursor: "pointer", textAlign: "center" }}>
						<Logo style={{ width: 200, height: 200, fill: "white" }} />
						{/* <SvgIcon component={Logo} viewBox="0 0 23 26" className={classes.icon} /> */}
						<Box my={2} />
						{/* <Typography variant="body1" style={{ color: "white" }}>
                            Avís legal
                        </Typography> */}
						<div onClick={() => navigate("/politica-privacitat")} style={{ color: "white" }}>
							<Typography variant="body1" style={{ color: "white" }}>
								Política de privacitat
							</Typography>
						</div>
						<div onClick={() => navigate("/avis-legal")} style={{ color: "white !important" }}>
							<Typography variant="body1" style={{ color: "white !important" }}>
								Avís legal
							</Typography>
						</div>
					</Box>
				</Grid>
				<Grid item md={4} xs={12}></Grid>
			</Grid>
		</Box>
	);
};

export default Footer;
