import React, { useState } from "react";

import { Box, Container, Grid, makeStyles, Typography, Button, CircularProgress, FormControlLabel, Checkbox } from "@material-ui/core";
import { ErrorMessage, Formik } from "formik";
import Page from "../../components/Page";
import * as Yup from "yup";
import Input from "../../components/CustomInput";
import { contacte, createAlumne } from "../../database/API";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router";
import Footer from "../../components/Footer";

const useStyles = makeStyles((theme) => ({
	container: {
		paddingBottom: 100,
	},
	loading: {
		height: "70vh",
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
	},
	imatgeWrap: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		marginBottom: 40,
	},
	"@global": {
		html: {
			width: "auto",
			height: "auto",
		},
	},
	avatar: {
		width: 180,
		height: 180,
		border: "8px solid white",
		transition: "0.2s",
		cursor: "pointer",
		"& img": {
			objectPosition: "top",
			transition: "0.2s",
			width: "90%",
		},
		marginBottom: 15,
		"&:hover": {
			border: "8px solid",
			borderColor: theme.palette.primary.main,
			"& img": {
				width: "100%",
			},
		},
	},
	activitatWrap: {
		backgroundColor: "white",
		padding: 30,
		border: "6px solid white",
		cursor: "pointer",
		borderRadius: 10,
		transition: ".2s",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		position: "relative",
	},
	active: {
		border: "6px solid",
		borderColor: theme.palette.text.primary,
	},
	checkWrap: {
		display: "flex",
		justifyContent: "flex-end",
		alignItems: "center",
	},
	check: {
		color: "green",
		transition: ".2s",
		opacity: 0,
		fontSize: 30,
	},
	opacity: {
		opacity: 1,
	},
}));

const Contacte = () => {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();

	const crear = async (values, actions) => {
		setLoading(true);
		const message = await contacte(values);
		enqueueSnackbar(message, {
			variant: "success",
		});
		actions.setSubmitting(false);
		navigate("/");
	};

	return (
		<Page title="Contacte">
			<Box mb={5} mt={20}>
				<Typography variant="h1" style={{ textAlign: "center" }}>
					Contacte / Baixa
				</Typography>
			</Box>

			<Formik
				initialValues={{
					nom: "",
					email: "",
					missatge: "",
				}}
				validationSchema={Yup.object().shape({
					nom: Yup.string().required("El nom és obligatòri"),
					email: Yup.string().email("Ha de ser un E-mail vàlid").required("L'email és obligatòri"),
					missatge: Yup.string().required("El missatge és obligatòri"),
				})}
				onSubmit={(values, actions) => {
					crear(values, actions);
				}}
			>
				{({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, touched, values }) => (
					<form onSubmit={handleSubmit}>
						<Container className={classes.container}>
							<Grid container spacing={3}>
								<Grid item md={6} xs={12}>
									<Input
										error={Boolean(touched.nom && errors.nom)}
										fullWidth
										helperText={touched.nom && errors.nom}
										label="Nom i cognoms"
										margin="normal"
										variant="outlined"
										name="nom"
										onBlur={handleBlur}
										onChange={handleChange}
										type="text"
										value={values.nom}
									/>
								</Grid>
								<Grid item md={6} xs={12}>
									<Input
										error={Boolean(touched.email && errors.email)}
										fullWidth
										helperText={touched.email && errors.email}
										label="E-mail"
										margin="normal"
										variant="outlined"
										name="email"
										onBlur={handleBlur}
										onChange={handleChange}
										type="text"
										value={values.email}
									/>
								</Grid>
							</Grid>
							<Grid container spacing={3}>
								<Grid item md={12} xs={12}>
									<Input
										error={Boolean(touched.missatge && errors.missatge)}
										fullWidth
										helperText={touched.missatge && errors.missatge}
										label="Missatge"
										multiline
										rows={4}
										margin="normal"
										variant="outlined"
										name="missatge"
										onBlur={handleBlur}
										onChange={handleChange}
										type="text"
										value={values.missatge}
									/>
								</Grid>
							</Grid>
							<Grid container spacing={3}>
								<Grid item md={12}>
									<FormControlLabel
										control={<Checkbox checked={values.promo} onChange={handleChange} name="promo" />}
										label="Accepto l'enviament de comunicacions promocionals"
									/>
								</Grid>
							</Grid>
							<Grid container spacing={3}>
								<Grid item md={12}>
									<FormControlLabel
										control={
											<Checkbox
												error={Boolean(touched.accepta && errors.accepta)}
												checked={values.accepta}
												onChange={handleChange}
												name="accepta"
											/>
										}
										label={
											<Typography>
												Accepto la{" "}
												<a href="/politica-privacitat" target="_blank">
													Política de Privacitat
												</a>
											</Typography>
										}
									/>
									<ErrorMessage name="accepta">
										{(msg) => (
											<Typography
												variant="body1"
												style={{
													color: "red",
												}}
											>
												{msg}
											</Typography>
										)}
									</ErrorMessage>
								</Grid>
							</Grid>
							<Box my={2}>
								<Button color="primary" fullWidth size="large" type="submit" disabled={isSubmitting} variant="contained">
									{!isSubmitting ? "ENVIAR" : <CircularProgress size={26} color="third" />}
								</Button>
							</Box>
						</Container>
					</form>
				)}
			</Formik>
			<Footer />
		</Page>
	);
};

export default Contacte;
