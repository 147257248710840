import React from "react";
import { Box, Container, Grid, makeStyles, TextField, Typography, Button, FormControl, InputLabel, Select } from "@material-ui/core";
import Page from "../../../components/Page";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { createButton, createEscola } from "../../../database/API";
import { useSnackbar } from "notistack";
import Title from "../../../components/Title";
import Thumb from "../../../components/Thumb";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: "white",
		minHeight: "100%",
	},
	container: {
		paddingTop: 47,
	},
	"@global": {
		html: {
			width: "100%",
			height: "100%",
		},
	},
	formControl: {
		marginTop: 16,
		marginBottom: 15,
	},
	paper: {
		backgroundColor: "white",
		borderRadius: 5,
		padding: 30,
		boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
	},
}));

const ButtonAdd = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();

	const crear = async (values) => {
		console.log(values);
		const message = await createButton(values);
		enqueueSnackbar(message, {
			variant: "success",
		});
		navigate(-1, { state: values });
	};

	return (
		<Page className={classes.root} title={"Dashboard"}>
			<Container maxWidth={false} className={classes.container}>
				<Box className={classes.paper}>
					<Formik
						initialValues={{
							nom: "",
							subtitol: "",
							url: "",
							file: "",
							icon: "campana",
						}}
						validationSchema={Yup.object().shape({
							nom: Yup.string().required("El nom és obligatòri"),
						})}
						onSubmit={(values) => {
							crear(values);
						}}
					>
						{({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, touched, values }) => (
							<form onSubmit={handleSubmit}>
								<Box mb={3}>
									<Title title={"Crear botó"} subtitol={"Entra les dades per guardar un botó nou"} />
								</Box>
								<Grid container spacing={3}>
									<Grid item md={6} xs={12}>
										<TextField
											error={Boolean(touched.nom && errors.nom)}
											fullWidth
											helperText={touched.nom && errors.nom}
											label="Títol"
											margin="normal"
											variant="outlined"
											name="nom"
											onBlur={handleBlur}
											onChange={handleChange}
											type="text"
											value={values.nom}
										/>
									</Grid>
									<Grid item md={3} xs={12}>
										<TextField
											error={Boolean(touched.subtitol && errors.subtitol)}
											fullWidth
											helperText={touched.subtitol && errors.subtitol}
											label="Subtitol"
											margin="normal"
											variant="outlined"
											name="subtitol"
											onBlur={handleBlur}
											onChange={handleChange}
											type="text"
											value={values.subtitol}
										/>
									</Grid>
									<Grid item md={3} xs={12}>
										<FormControl
											className={classes.formControl}
											variant="outlined"
											fullWidth
											InputLabelProps={{
												shrink: true,
											}}
										>
											<InputLabel htmlFor="icon">Icona</InputLabel>
											<Select
												native
												value={values.icon}
												onChange={handleChange}
												onBlur={handleBlur}
												label="Icona"
												name="icon"
												InputLabelProps={{
													shrink: true,
												}}
												inputProps={{
													name: "icon",
													id: "icon",
												}}
											>
												<option value="campana">Campana</option>
												<option value="descarregar">Descarregar</option>
												<option value="document">Document</option>
												<option value="rellotge">Rellotge</option>
												<option value="info">Informació</option>
												<option value="alerta">Alerta</option>
												<option value="video">Vídeo</option>
											</Select>
										</FormControl>
									</Grid>
								</Grid>

								<Grid container spacing={3}>
									<Grid item md={6} xs={12}>
										<Box mt={2}>
											<Typography>Document adjunt</Typography>
											<input
												id="file"
												name="file[]"
												type="file"
												onChange={(event) => {
													setFieldValue("file", event.currentTarget.files[0]);
												}}
												className="form-control"
											/>
										</Box>
									</Grid>
									<Grid item md={6} xs={12}>
										<TextField
											error={Boolean(touched.url && errors.url)}
											fullWidth
											helperText={touched.url && errors.url}
											label="Enllaç (si no hi ha document ajunt)"
											margin="normal"
											variant="outlined"
											name="url"
											onBlur={handleBlur}
											onChange={handleChange}
											type="text"
											value={values.url}
										/>
									</Grid>
								</Grid>

								<Box my={2}>
									<Button color="primary" fullWidth size="large" type="submit" variant="contained">
										{"Crear"}
									</Button>
								</Box>
							</form>
						)}
					</Formik>
				</Box>
			</Container>
		</Page>
	);
};

export default ButtonAdd;
