import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { AppBar, Box, Hidden, IconButton, Toolbar } from "@material-ui/core";
import InputIcon from "@material-ui/icons/Input";
import { Dashboard } from "@material-ui/icons";
import navBarStyle from "./NavBarStyle";
import { Button } from "@material-ui/core";
import { ReactComponent as Logo } from "../../theme/icons/logo-nou.svg";
import { SvgIcon } from "@material-ui/core";

const PublicNavBar = () => {
	const classes = navBarStyle();
	const [scrollY, setScrollY] = useState(window.scrollY);
	const [isLogged, setIsLogged] = useState(localStorage.getItem("isLoggedIn") || "");
	let navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	});

	const handleScroll = () => {
		setScrollY(window.scrollY);
	};

	useEffect(() => {
		setIsLogged(localStorage.getItem("isLoggedIn"));
	}, [isLogged]);

	const logOut = () => {
		localStorage.removeItem("user");
		localStorage.setItem("isLoggedIn", false);
		setIsLogged(false);
		navigate("/");
	};

	return (
		<div className={classes.root}>
			<AppBar elevation={0} className={classes.navBar}>
				<Toolbar className={classes.toolbar}>
					{location.pathname === "/" ? (
						<>
							<Button className={classes.inscripcions} onClick={() => navigate("/contacte")}>
								Contacte / Baixa
							</Button>
							<Button className={classes.inscripcions2} onClick={() => navigate("/inscripcions")}>
								Inscripcions
							</Button>
							{/* <Button
                                className={classes.inscripcions}
                                onClick={() => navigate('/inscripcions')}
                            >
                                Normativa
                            </Button> */}
						</>
					) : (
						<>
							<Box
								my={2}
								onClick={() => navigate("/")}
								className={scrollY < 100 ? classes.showTitle : classes.hideTitle}
								style={{
									cursor: "pointer",
									textAlign: "center",
								}}
							>
								<SvgIcon component={Logo} viewBox="0 0 23 26" className={classes.icon} />
							</Box>
						</>
					)}

					{isLogged === "true" && (
						<Box className={classes.adminButton}>
							<IconButton color="inherit" onClick={() => navigate("/admin/alumnes")}>
								<Dashboard />
							</IconButton>
							<IconButton color="inherit" onClick={() => logOut()}>
								<InputIcon />
							</IconButton>
						</Box>
					)}
				</Toolbar>
			</AppBar>
			<div className={classes.wrapper}>
				<div className={classes.contentContainer}>
					<div className={classes.content}>
						<Outlet />
					</div>
				</div>
			</div>
		</div>
	);
};

export default PublicNavBar;
