import {
    Box,
    Container,
    Grid,
    Link,
    makeStyles,
    Typography,
} from '@material-ui/core';
import { VideoCall } from '@material-ui/icons';
import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import Footer from '../../components/Footer';
import Page from '../../components/Page';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 30,
    },
    main: {
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        padding: 30,
    },
    title: {
        color: theme.palette.third.main,
    },
    button: {
        padding: 10,
        backgroundColor: 'white',
        borderRadius: 30,
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 30,
        paddingLeft: 30,
        transition: '0.2s',
        marginTop: 15,
        marginBottom: 15,
        '&:hover': {
            backgroundColor: theme.palette.third.main,
        },
    },
}));

const Informacio = () => {
    const classes = useStyles();

    return (
        <Page title="Informació reunions">
            <ScrollAnimation animateIn="fadeIn">
                <Container className={classes.root}>
                    <Box mb={20} mt={20}>
                        <Typography
                            variant="h1"
                            style={{ textAlign: 'center' }}
                        >
                            Xerrades informatives online
                        </Typography>
                        <Typography
                            variant="body1"
                            style={{ textAlign: 'center' }}
                        >
                            El dia de la reunió clica el botó de la teva escola
                            per a accedir a la sala virtual
                        </Typography>
                    </Box>
                    <Box className={classes.main} my={6}>
                        <Grid container spacing={8}>
                            <Grid item md={6}>
                                <Typography
                                    variant="h2"
                                    className={classes.title}
                                >
                                    Dimecres 7
                                </Typography>
                                <Link
                                    href="https://meet.google.com/qxe-qhmr-xdn"
                                    className={classes.button}
                                    target="_blank"
                                >
                                    <Typography variant="body1">
                                        <strong>19h</strong> Escola Sant Joan
                                    </Typography>
                                    <VideoCall />
                                </Link>
                                <Link
                                    href="https://meet.google.com/eih-fnvy-nvy"
                                    className={classes.button}
                                    target="_blank"
                                >
                                    <Typography variant="body1">
                                        <strong>20h</strong> Escola Santa
                                        Eulàlia
                                    </Typography>
                                    <VideoCall />
                                </Link>
                                <Link
                                    href="https://meet.google.com/wew-fzca-xmc"
                                    className={classes.button}
                                    target="_blank"
                                >
                                    <Typography variant="body1">
                                        <strong>21h</strong> Escola Vedruna
                                    </Typography>
                                    <VideoCall />
                                </Link>
                            </Grid>
                            <Grid item md={6}>
                                <Typography
                                    variant="h2"
                                    className={classes.title}
                                >
                                    Dijous 8
                                </Typography>
                                <Link
                                    href="https://meet.google.com/met-ywwt-qam"
                                    className={classes.button}
                                    target="_blank"
                                >
                                    <Typography variant="body1">
                                        <strong>20h</strong> Escola La
                                        Valldan
                                    </Typography>
                                    <VideoCall />
                                </Link>
                                <Link
                                    href="https://meet.google.com/fie-trda-okr"
                                    className={classes.button}
                                    target="_blank"
                                >
                                    <Typography variant="body1">
                                        <strong>21h</strong> Escola FEDAC Xarxa
                                    </Typography>
                                    <VideoCall />
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </ScrollAnimation>
            <Footer />
        </Page>
    );
};

export default Informacio;
