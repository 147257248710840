import React, { useEffect, useState } from "react";

import {
	Box,
	Container,
	Grid,
	makeStyles,
	Typography,
	Button,
	CircularProgress,
	FormControlLabel,
	Checkbox,
	FormControl,
	InputLabel,
	Select,
} from "@material-ui/core";
import { ErrorMessage, Field, Formik } from "formik";
import Page from "../../components/Page";
import * as Yup from "yup";
import Input from "../../components/CustomInput";
import { createAlumne, getActivitats, getEscoles, getPacks } from "../../database/API";
import { Element, scroller } from "react-scroll";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate } from "react-router";
import ActivitatsWeek from "../../components/ActivitatsWeek";
import EscolaSingle from "../../components/EscolaSingle";
import Footer from "../../components/Footer";

const Inscripcions = () => {
	const classes = useStyles();
	const [escoles, setEscoles] = useState();
	const [continuar, setContinuar] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const [activitats, setActivitats] = useState();
	const [activitatsFora, setActivitatsFora] = useState();
	const [escola, setEscola] = useState(null);
	const [loading, setLoading] = useState(true);
	const [loadingAct, setLoadingAct] = useState(false);
	const navigate = useNavigate();
	const { state } = useLocation();
	const [packs, setPacks] = useState();
	const [packsFora, setPacksFora] = useState([]);

	useEffect(() => {
		const get = async () => {
			const { escoles } = await getEscoles();
			setEscoles(escoles);
			setLoading(false);
		};
		get();
	}, []);

	useEffect(() => {
		if (state) {
			chooseEscola(state);
			setTimeout(function () {
				scroller.scrollTo("activitats", {
					duration: 500,
					smooth: true,
				});
			}, 800);
		}
	}, [state]);

	useEffect(() => {
		const get = async () => {
			const { activitats } = await getActivitats(escola.id);
			const { packs } = await getPacks(escola.id);
			const packsF = await getPacks(0);
			setActivitats(activitats);
			setPacks(packs);
			setPacksFora(packsF.packs);
			setLoadingAct(false);
		};
		if (escola !== null) get();
	}, [escola]);

	useEffect(() => {
		const get = async () => {
			const { activitats } = await getActivitats("0");
			setActivitatsFora(activitats);
		};
		get();
	}, []);

	const chooseEscola = (id) => {
		setLoadingAct(true);
		setEscola(id);
	};

	const crear = async (values, actions) => {
		// console.log(values);
		const { message } = await createAlumne(values);
		enqueueSnackbar(message, {
			variant: "success",
		});
		actions.setSubmitting(false);
		navigate("/");
	};

	Yup.addMethod(Yup.string, "integer", function () {
		return this.matches(/^\d+$/, "El camp només pot contenir dígits");
	});

	return (
		<Page title="Inscripcions">
			<Box mb={5} mt={20}>
				<Typography variant="h1" style={{ textAlign: "center" }}>
					Inscripcions
				</Typography>
			</Box>

			{!loading ? (
				<Formik
					initialValues={{
						nom: "",
						email: "",
						telefon: "",
						telefon2: "",
						escola: "",
						curs: "",
						edat: 0,
						nom_titular: "",
						dni: "",
						n_compte_1: "",
						n_compte_2: "",
						n_compte_3: "",
						n_compte_4: "",
						n_compte_5: "",
						n_compte_6: "",
						ampa: false,
						dret: false,
						observacions: "",
						activitat: [],
						accepta: false,
						talla: "",
					}}
					validationSchema={Yup.object().shape({
						nom: Yup.string().required("El nom és obligatòri"),
						email: Yup.string().email("Ha de ser un E-mail vàlid").required("L'email és obligatòri"),
						telefon: Yup.string().required("El telèfon és obligatòri"),
						telefon_2: Yup.string(),
						escola: Yup.string().required("L'escola és obligatòria"),
						curs: Yup.string().required("El curs és obligatòri"),
						edat: Yup.number().min(2).max(20).required("L'edat és obligatòria"),
						nom_titular: Yup.string().required("El nom del titular és obligatòri"),
						dni: Yup.string().required("El DNI del titular és obligatòri"),
						n_compte_1: Yup.string().max(4).min(4).required("El número de compte del titular és obligatòri"),
						n_compte_2: Yup.string()
							.integer()
							.max(4, "El camp ha de ser de 4 dígits")
							.min(4, "El camp ha de ser de 4 dígits")
							.required("El número de compte del titular és obligatòri"),
						n_compte_3: Yup.string()
							.integer()
							.max(4, "El camp ha de ser de 4 dígits")
							.min(4, "El camp ha de ser de 4 dígits")
							.required("El número de compte del titular és obligatòri"),
						n_compte_4: Yup.string()
							.integer()
							.max(4, "El camp ha de ser de 4 dígits")
							.min(4, "El camp ha de ser de 4 dígits")
							.required("El número de compte del titular és obligatòri"),
						n_compte_5: Yup.string()
							.integer()
							.max(4, "El camp ha de ser de 4 dígits")
							.min(4, "El camp ha de ser de 4 dígits")
							.required("El número de compte del titular és obligatòri"),
						n_compte_6: Yup.string()
							.integer()
							.max(4, "El camp ha de ser de 4 dígits")
							.min(4, "El camp ha de ser de 4 dígits")
							.required("El número de compte del titular és obligatòri"),
						accepta: Yup.bool().oneOf([true], "Has d'acceptar la Política de Privacitat per a continuar"),
						normativa: Yup.bool().oneOf([true], "Has d'acceptar que has llegit la normativa per a continuar"),
						activitat: Yup.array().min(1, "Has de seleccionar, al menys, una activitat"),
					})}
					onSubmit={(values, actions) => {
						crear(values, actions);
					}}
				>
					{({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, touched, values }) => (
						<form onSubmit={handleSubmit}>
							{continuar && (
								<Box
									className={classes.continuar}
									style={{
										backgroundColor: escola?.color_alt,
										borderTop: "5px solid " + escola?.color,
									}}
									onClick={() => {
										scroller.scrollTo("formulari", {
											duration: 500,
											smooth: true,
										});
										setContinuar(false);
									}}
								>
									<Typography variant="h4" style={{ color: "white" }}>
										Continuar
									</Typography>
									<Typography variant="body1" style={{ color: "white" }}>
										Passa al pas d'omplir les dades
									</Typography>
								</Box>
							)}
							<Container className={classes.container}>
								<Box mb={10}>
									<Typography variant="h5" style={{ textAlign: "center" }}>
										Tria l'escola on vols fer l'extraescolar
									</Typography>
								</Box>
								<Grid container spacing={5} style={{ justifyContent: "center" }}>
									{escoles?.map((escola) => {
										if (escola.id !== 0)
											return (
												<EscolaSingle
													escola={escola}
													onClick={() => {
														chooseEscola(escola);
														setFieldValue("activitat", []);
														scroller.scrollTo("activitats", {
															duration: 500,
															smooth: true,
														});
														setContinuar(false);
													}}
												/>
											);
									})}
								</Grid>
							</Container>
							<Element
								name="activitats"
								style={{
									paddingTop: 80,
									paddingBottom: 80,
									backgroundColor: escola?.color,
								}}
							>
								{escola !== null && (
									<Container className={classes.container}>
										<Box mt={10} mb={5}>
											<Typography
												variant="h5"
												style={{
													textAlign: "center",
												}}
											>
												Selecciona les activitats en les que et vols inscriure
											</Typography>
										</Box>

										{!loadingAct ? (
											<>
												<Box mb={5}>
													<Typography
														variant="h3"
														style={{
															textAlign: "center",
														}}
													>
														Activitats a l'escola
													</Typography>
												</Box>
												<ActivitatsWeek
													activitats={activitats}
													color={escola.color_alt}
													values={values}
													packs={packs}
													setFieldValue={setFieldValue}
													setContinuar={setContinuar}
												/>
												<Box mb={5} mt={8}>
													<Typography
														variant="h3"
														style={{
															textAlign: "center",
														}}
													>
														Activitats fora de l'escola
													</Typography>
												</Box>
												<ActivitatsWeek
													activitats={activitatsFora}
													color={escola.color_alt}
													packs={packsFora}
													values={values}
													setFieldValue={setFieldValue}
													setContinuar={setContinuar}
												/>
											</>
										) : (
											<Box className={classes.loading}>
												<CircularProgress />
											</Box>
										)}
									</Container>
								)}
							</Element>
							<Element
								name="formulari"
								style={{
									paddingTop: 50,
									paddingBottom: 80,
									backgroundColor: escola?.color,
								}}
							>
								<Container className={classes.container}>
									{/* <Box className={values.activitat.length === 0 && classes.noOpacity}> */}
									<Box>
										<Box mt={15}>
											<Typography variant="h3" style={{ textAlign: "center" }}>
												Omple les dades
											</Typography>
										</Box>
										<Field
											component="select"
											name="activitat"
											value={values.activitat}
											onChange={(evt) => setFieldValue("activitat", [])}
											multiple={true}
											hidden
										>
											{activitats?.map((item) => (
												<option key={item.id} value={item.id}>
													{item.nom}
												</option>
											))}
										</Field>
										<Box my={3}>
											<Typography variant="h4">Dades de l'alumne</Typography>
										</Box>
										<Grid container spacing={3}>
											<Grid item md={6} xs={12}>
												<Input
													error={Boolean(touched.nom && errors.nom)}
													fullWidth
													helperText={touched.nom && errors.nom}
													label="Nom i cognoms de l'alumne"
													margin="normal"
													variant="outlined"
													name="nom"
													onBlur={handleBlur}
													onChange={handleChange}
													type="text"
													value={values.nom}
												/>
											</Grid>
											<Grid item md={6} xs={12}>
												<Input
													error={Boolean(touched.email && errors.email)}
													fullWidth
													helperText={touched.email && errors.email}
													label="E-mail"
													margin="normal"
													variant="outlined"
													name="email"
													onBlur={handleBlur}
													onChange={handleChange}
													type="text"
													value={values.email}
												/>
											</Grid>
										</Grid>
										<Grid container spacing={3}>
											<Grid item md={6} xs={12}>
												<Input
													error={Boolean(touched.telefon && errors.telefon)}
													fullWidth
													helperText={touched.telefon && errors.telefon}
													label="Telèfon"
													margin="normal"
													variant="outlined"
													name="telefon"
													onBlur={handleBlur}
													onChange={handleChange}
													type="text"
													value={values.telefon}
												/>
											</Grid>
											<Grid item md={6} xs={12}>
												<Input
													error={Boolean(touched.telefon2 && errors.telefon2)}
													fullWidth
													helperText={touched.telefon2 && errors.telefon2}
													label="Telèfon 2 (opcional)"
													margin="normal"
													variant="outlined"
													name="telefon2"
													onBlur={handleBlur}
													onChange={handleChange}
													type="text"
													value={values.telefon2}
												/>
											</Grid>
											{/* <Grid item md={4} xs={12}>
												<FormControl
													className={classes.formControl}
													variant="outlined"
													fullWidth
													InputLabelProps={{
														shrink: true,
													}}
												>
													<InputLabel htmlFor="talla">Talla de samarreta</InputLabel>
													<Select
														native
														value={values.icon}
														onChange={handleChange}
														onBlur={handleBlur}
														label="Talla de samarreta"
														name="talla"
														InputLabelProps={{
															shrink: true,
														}}
														inputProps={{
															name: "talla",
															id: "talla",
														}}
													>
														<option value="3-4">3-4</option>
														<option value="5-6">5-6</option>
														<option value="7-8">7-8</option>
														<option value="9-10">9-10</option>
														<option value="11-12">11-12</option>
													</Select>
												</FormControl>
											</Grid> */}
										</Grid>
										<Grid container spacing={3}>
											<Grid item md={4} xs={12}>
												<FormControl
													className={classes.formControl}
													variant="outlined"
													fullWidth
													InputLabelProps={{
														shrink: true,
													}}
												>
													<InputLabel htmlFor="escola">Escola on cursa</InputLabel>
													<Select
														native
														value={values.icon}
														onChange={handleChange}
														onBlur={handleBlur}
														label="Escola on cursa"
														name="escola"
														InputLabelProps={{
															shrink: true,
														}}
														inputProps={{
															name: "escola",
															id: "escola",
														}}
														error={Boolean(touched.escola && errors.escola)}
														helperText={touched.escola && errors.escola}
													>
														<option value="">-</option>
														<option value="La Valldan">La Valldan</option>
														<option value="Santa Eulàlia">Santa Eulàlia</option>
														<option value="Vedruna">Vedruna</option>
														<option value="Xarxa">Xarxa</option>
														<option value="Sant Joan">Sant Joan</option>
													</Select>
												</FormControl>
											</Grid>
											<Grid item md={4} xs={12}>
												<FormControl
													className={classes.formControl}
													variant="outlined"
													fullWidth
													InputLabelProps={{
														shrink: true,
													}}
												>
													<InputLabel htmlFor="curs">Curs</InputLabel>
													<Select
														native
														value={values.icon}
														onChange={handleChange}
														onBlur={handleBlur}
														label="Curs"
														name="curs"
														InputLabelProps={{
															shrink: true,
														}}
														inputProps={{
															name: "curs",
															id: "curs",
														}}
														error={Boolean(touched.curs && errors.curs)}
														helperText={touched.curs && errors.curs}
													>
														<option value="">-</option>
														<option value="I3">I3</option>
														<option value="I4">I4</option>
														<option value="I5">I5</option>
														<option value="P1">P1</option>
														<option value="P2">P2</option>
														<option value="P3">P3</option>
														<option value="P4">P4</option>
														<option value="P5">P5</option>
														<option value="P6">P6</option>
													</Select>
												</FormControl>
											</Grid>
											<Grid item md={4} xs={12}>
												<Input
													error={Boolean(touched.edat && errors.edat)}
													fullWidth
													label="Edat"
													margin="normal"
													variant="outlined"
													name="edat"
													onBlur={handleBlur}
													onChange={handleChange}
													type="number"
													value={values.edat}
												/>
											</Grid>
										</Grid>
										<Grid container spacing={3}>
											<Grid item md={12} xs={12}>
												<Input
													error={Boolean(touched.observacions && errors.observacions)}
													fullWidth
													helperText={touched.observacions && errors.observacions}
													label="Observacions"
													multiline
													rows={2}
													margin="normal"
													variant="outlined"
													name="observacions"
													onBlur={handleBlur}
													onChange={handleChange}
													type="number"
													value={values.observacions}
												/>
											</Grid>
										</Grid>
										<Grid container spacing={3}>
											<Grid item md={12} xs={12}>
												<FormControlLabel
													control={<Checkbox checked={values.ampa} onChange={handleChange} name="ampa" />}
													label="Soci AMPA"
												/>
											</Grid>
										</Grid>

										<Box mb={3} mt={10}>
											<Typography variant="h4">Dades del titular</Typography>
										</Box>
										<Grid container spacing={3}>
											<Grid item md={6} xs={12}>
												<Input
													error={Boolean(touched.nom_titular && errors.nom_titular)}
													fullWidth
													helperText={touched.nom_titular && errors.nom_titular}
													label="Nom i cognoms del titular"
													margin="normal"
													variant="outlined"
													name="nom_titular"
													onBlur={handleBlur}
													onChange={handleChange}
													type="text"
													value={values.nom_titular}
												/>
											</Grid>
											<Grid item md={6} xs={12}>
												<Input
													error={Boolean(touched.dni && errors.dni)}
													fullWidth
													helperText={touched.dni && errors.dni}
													label="DNI del titular"
													margin="normal"
													variant="outlined"
													name="dni"
													onBlur={handleBlur}
													onChange={handleChange}
													type="text"
													value={values.dni}
												/>
											</Grid>
										</Grid>
										<Box mt={3}>
											<Typography>Número de compte</Typography>
										</Box>
										<Grid container spacing={3}>
											<Grid item md={2} xs={4}>
												<Input
													error={Boolean(touched.n_compte_1 && errors.n_compte_1)}
													fullWidth
													helperText={touched.n_compte_1 && errors.n_compte_1}
													label="ES00"
													margin="normal"
													variant="outlined"
													name="n_compte_1"
													onBlur={handleBlur}
													onChange={handleChange}
													type="text"
													value={values.n_compte_1}
												/>
											</Grid>
											<Grid item md={2} xs={4}>
												<Input
													error={Boolean(touched.n_compte_2 && errors.n_compte_2)}
													fullWidth
													helperText={touched.n_compte_2 && errors.n_compte_2}
													label="0000"
													margin="normal"
													variant="outlined"
													name="n_compte_2"
													onBlur={handleBlur}
													onChange={handleChange}
													type="text"
													value={values.n_compte_2}
												/>
											</Grid>
											<Grid item md={2} xs={4}>
												<Input
													error={Boolean(touched.n_compte_3 && errors.n_compte_3)}
													fullWidth
													helperText={touched.n_compte_3 && errors.n_compte_3}
													label="0000"
													margin="normal"
													variant="outlined"
													name="n_compte_3"
													onBlur={handleBlur}
													onChange={handleChange}
													type="text"
													value={values.n_compte_3}
												/>
											</Grid>
											<Grid item md={2} xs={4}>
												<Input
													error={Boolean(touched.n_compte_4 && errors.n_compte_4)}
													fullWidth
													helperText={touched.n_compte_4 && errors.n_compte_4}
													label="0000"
													margin="normal"
													variant="outlined"
													name="n_compte_4"
													onBlur={handleBlur}
													onChange={handleChange}
													type="text"
													value={values.n_compte_4}
												/>
											</Grid>
											<Grid item md={2} xs={4}>
												<Input
													error={Boolean(touched.n_compte_5 && errors.n_compte_5)}
													fullWidth
													helperText={touched.n_compte_5 && errors.n_compte_5}
													label="0000"
													margin="normal"
													variant="outlined"
													name="n_compte_5"
													onBlur={handleBlur}
													onChange={handleChange}
													type="text"
													value={values.n_compte_5}
												/>
											</Grid>
											<Grid item md={2} xs={4}>
												<Input
													error={Boolean(touched.n_compte_6 && errors.n_compte_6)}
													fullWidth
													helperText={touched.n_compte_6 && errors.n_compte_6}
													label="0000"
													margin="normal"
													variant="outlined"
													name="n_compte_6"
													onBlur={handleBlur}
													onChange={handleChange}
													type="text"
													value={values.n_compte_6}
												/>
											</Grid>
										</Grid>

										<Grid container spacing={3}>
											<Grid item md={12}>
												<FormControlLabel
													control={<Checkbox checked={values.dret} onChange={handleChange} name="dret" />}
													label="Accepto la captació d'imatges i/o vídeos i la seva publicació"
												/>
											</Grid>
										</Grid>
										<Grid container spacing={3}>
											<Grid item md={12}>
												<FormControlLabel
													control={<Checkbox checked={values.promo} onChange={handleChange} name="promo" />}
													label="Accepto l'enviament de comunicacions promocionals"
												/>
											</Grid>
										</Grid>
										<Grid container spacing={3}>
											<Grid item md={12}>
												<FormControlLabel
													control={
														<Checkbox
															error={Boolean(touched.accepta && errors.accepta)}
															checked={values.accepta}
															onChange={handleChange}
															name="accepta"
														/>
													}
													label={
														<Typography>
															Accepta que has llegit i estas d'acord amb la{" "}
															<a href="/politica-privacitat" target="_blank">
																Política de Privacitat
															</a>
														</Typography>
													}
												/>
												<ErrorMessage name="accepta">
													{(msg) => (
														<Typography
															variant="body1"
															style={{
																color: "red",
															}}
														>
															{msg}
														</Typography>
													)}
												</ErrorMessage>
											</Grid>
										</Grid>
										<Grid container spacing={3}>
											<Grid item md={12}>
												<FormControlLabel
													control={
														<Checkbox
															error={Boolean(touched.normativa && errors.normativa)}
															checked={values.normativa}
															onChange={handleChange}
															name="normativa"
														/>
													}
													label={
														<Typography>
															Accepta que has llegit la{" "}
															<a href="/politica-privacitat" target="_blank">
																Normativa
															</a>{" "}
															i el{" "}
															<a href="/cataleg.pdf" target="_blank">
																Catàleg d'activitats
															</a>{" "}
															i sé a què inscric el meu fill/a i com funcionen les extraescolars.
														</Typography>
													}
												/>
												<ErrorMessage name="normativa">
													{(msg) => (
														<Typography
															variant="body1"
															style={{
																color: "#ea7c7c",
															}}
														>
															{msg}
														</Typography>
													)}
												</ErrorMessage>
											</Grid>
										</Grid>
										<Box my={2}>
											<Button color="primary" fullWidth size="large" type="submit" disabled={isSubmitting} variant="contained">
												{!isSubmitting ? "INSCRIURE'S!" : <CircularProgress size={26} color="third" />}
											</Button>
											<Typography
												variant="body1"
												style={{
													color: "#ea7c7c",
												}}
											>
												{errors.activitat}
											</Typography>
										</Box>
									</Box>
								</Container>
							</Element>
						</form>
					)}
				</Formik>
			) : (
				<Box className={classes.loading}>
					<CircularProgress />
				</Box>
			)}
			<Footer />
		</Page>
	);
};

export default Inscripcions;

const useStyles = makeStyles((theme) => ({
	container: {
		paddingBottom: 100,
	},
	loading: {
		height: "70vh",
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
	},
	imatgeWrap: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		marginBottom: 40,
	},
	"@global": {
		html: {
			width: "auto",
			height: "auto",
		},
	},
	avatar: {
		width: 180,
		height: 180,
		border: "8px solid white",
		transition: "0.2s",
		cursor: "pointer",
		"& img": {
			objectPosition: "top",
			transition: "0.2s",
			width: "90%",
		},
		marginBottom: 15,
		"&:hover": {
			border: "8px solid",
			borderColor: theme.palette.primary.main,
			"& img": {
				width: "100%",
			},
		},
	},
	activitatWrap: {
		backgroundColor: "white",
		padding: 30,
		border: "6px solid white",
		cursor: "pointer",
		borderRadius: 10,
		transition: ".2s",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		position: "relative",
	},
	active: {
		border: "6px solid",
		borderColor: theme.palette.text.primary,
	},
	checkWrap: {
		display: "flex",
		justifyContent: "flex-end",
		alignItems: "center",
	},
	check: {
		color: "green",
		transition: ".2s",
		opacity: 0,
		fontSize: 30,
	},
	opacity: {
		opacity: 1,
	},
	noOpacity: {
		opacity: 0,
		height: 0,
	},
	places: {
		position: "absolute",
		top: 20,
		right: 20,
		paddingLeft: 15,
		paddingRight: 15,
		borderRadius: 20,
		backgroundColor: theme.palette.primary.main,
		"& p": {
			color: "white",
			fontSize: 16,
		},
	},
	ple: {
		color: theme.palette.error.main + "!important",
	},
	continuar: {
		bottom: 0,
		width: "100%",
		position: "fixed",
		paddingTop: 20,
		textAlign: "center",
		paddingBottom: 20,
		cursor: "pointer",
		zIndex: 10,
	},
	formControl: {
		marginTop: 16,
	},
}));
