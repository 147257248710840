import React, { useEffect, useState } from 'react';
import {
    Box,
    CircularProgress,
    Container,
    Fade,
    Grid,
    IconButton,
    makeStyles,
} from '@material-ui/core';
import Page from '../../../components/Page';
import { useNavigate } from 'react-router-dom';
import { getButtons } from '../../../database/API';
import Title from '../../../components/Title';
import { Add } from '@material-ui/icons';
import ButtonItem from './ButtonItem';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'white',
        minHeight: '100%',
    },
    container: {
        paddingTop: 40,
    },
    '@global': {
        html: {
            width: '100%',
            height: '100%',
        },
    },
}));

const ButtonsAdmin = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [buttons, setButtons] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const get = async () => {
            const { buttons, message } = await getButtons();
            setButtons(buttons);
            console.log(buttons);
            setLoading(false);
        };

        get();
    }, [navigate]);

    return (
        <Page className={classes.root} title={'Botons'}>
            <Container maxWidth={false} className={classes.container}>
                <Title
                    title={'Botons'}
                    subtitle={"Administra els botons d'inici"}
                    button={
                        <Box>
                            <IconButton
                                className={classes.addButton}
                                onClick={() => navigate('nou')}
                            >
                                <Add />
                            </IconButton>
                        </Box>
                    }
                />
                <Box pt={2} spacing={3}>
                    {!loading ? (
                        <Fade in={!loading}>
                            <Grid container spacing={4}>
                                {buttons?.map((item) => {
                                    return <ButtonItem item={item} />;
                                })}
                            </Grid>
                        </Fade>
                    ) : (
                        <CircularProgress />
                    )}
                </Box>
            </Container>
        </Page>
    );
};

export default ButtonsAdmin;
