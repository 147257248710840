import React, { useEffect, useRef, useState } from "react";
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import { ReactComponent as Logo } from "../../theme/icons/logo-nou.svg";
import { ReactComponent as Cloud } from "../../theme/icons/cloud.svg";
import { ReactComponent as StaEulalia } from "../../theme/icons/staeulalia.svg";
import { ReactComponent as Valldan } from "../../theme/icons/valldan.svg";
import { ReactComponent as StJoan } from "../../theme/icons/stjoan.svg";
import { Container, makeStyles, SvgIcon } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { getEscoles } from "../../database/API";
import EscolaSingle from "../../components/EscolaSingle";
import { Grid } from "@material-ui/core";
import Footer from "../../components/Footer";
import { useNavigate } from "react-router";
import QueFem from "./QueFem";
import QuiSom from "./QuiSom";
import { NotificationsActiveRounded } from "@material-ui/icons";
import InfoImportant from "../../components/InfoImportant";
import Twitter from "../../components/Twitter";
import Colaboradors from "../../components/Colaboradors";

const useStyles = makeStyles((theme) => ({
	"@global": {
		body: {
			overflow: "hidden",
		},
		".makeStyles-wrapper-2": {
			padding: 0,
		},
	},
	icon: {
		fill: "#21C4B4",
		height: 500,
		width: 500,
	},
	cloud: {
		fill: "#21C4B4",
		height: 500,
		width: 500,
	},
	titolEscoles: {
		backgroundColor: theme.palette.background.default,
		borderRadius: 50,
		padding: 10,
		textAlign: "center",
		marginBottom: 70,
	},
	boxEscoles: {
		marginTop: 200,
	},
}));

export default function App() {
	const classes = useStyles();
	const parallax = useRef(null);
	const navigate = useNavigate();

	const [escoles, setEscoles] = useState();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const get = async () => {
			const { escoles } = await getEscoles();
			setEscoles(escoles);
			setLoading(false);
		};
		get();
	}, []);

	return (
		<div
			style={{
				width: "100%",
				height: "100%",
				position: "absolute",
				backgroundColor: "#9FE1D9",
				marginBottom: 100,
				zIndex: 10,
			}}
		>
			<Parallax ref={parallax} pages={5}>
				<ParallaxLayer offset={5} factor={1} speed={1} />
				<ParallaxLayer offset={4} factor={2} speed={1} />
				<ParallaxLayer offset={3} factor={2} speed={1} style={{ backgroundColor: "white" }} />
				<ParallaxLayer offset={2} speed={1} factor={2} style={{ backgroundColor: "#e8e3c5" }} />
				<ParallaxLayer offset={1} speed={1} style={{ backgroundColor: "#9FE1D9" }} />
				<ParallaxLayer offset={0} speed={1} style={{ backgroundColor: "white" }} />

				{/* Contingut */}

				<ParallaxLayer
					offset={4.1}
					speed={0.1}
					onClick={() => parallax.current.scrollTo(0)}
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						flexDirection: "column",
					}}
				>
					<Footer />
				</ParallaxLayer>
				{/* <ParallaxLayer
					offset={4.1}
					speed={0.1}
					onClick={() => parallax.current.scrollTo(5)}
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						flexDirection: "column",
					}}
				>
					<Twitter />
				</ParallaxLayer> */}
				<ParallaxLayer
					offset={2}
					speed={0.1}
					onClick={() => parallax.current.scrollTo(3)}
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<QueFem />
				</ParallaxLayer>
				<ParallaxLayer
					offset={1}
					speed={0.1}
					onClick={() => parallax.current.scrollTo(2)}
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<QuiSom />
				</ParallaxLayer>

				<ParallaxLayer
					offset={3}
					speed={0.1}
					style={{
						display: "flex",

						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Box className={classes.boxEscoles}>
						<Container>
							<Box className={classes.titolEscoles}>
								<Typography variant="h2" style={{ color: "white" }}>
									ESCOLES
								</Typography>
							</Box>
							<Grid container spacing={5} style={{ justifyContent: "center" }}>
								{escoles?.map((escola) => {
									return (
										escola.id !== 0 && (
											<EscolaSingle
												escola={escola}
												onClick={() =>
													navigate("inscripcions", {
														state: escola,
													})
												}
											/>
										)
									);
								})}
							</Grid>
						</Container>
					</Box>
				</ParallaxLayer>
				<ParallaxLayer
					offset={3.99}
					speed={0.5}
					style={{
						display: "flex",

						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Box mt={20}>
						<Colaboradors />
					</Box>
				</ParallaxLayer>

				<ParallaxLayer
					offset={0}
					speed={0.2}
					onClick={() => parallax.current.scrollTo(1)}
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Logo style={{ width: 550, height: 300 }} className={classes.icon} fill="#21C4B4" />
					{/* <SvgIcon
						component={Logo}
						viewBox="0 0 23 27"
						style={{
							display: "block",
							width: "40%",
							// fill: "#b2d6cf",
							height: "45%",
						}}
						className={classes.icon}
					/> */}
				</ParallaxLayer>

				<ParallaxLayer
					offset={0}
					speed={0.1}
					onClick={() => parallax.current.scrollTo(1)}
					style={{
						display: "flex",
						alignItems: "flex-end",
						justifyContent: "center",
						zIndex: 100,
					}}
				>
					<InfoImportant />
				</ParallaxLayer>

				<ParallaxLayer offset={0} speed={0.7} style={{ opacity: 0.5 }} onClick={() => parallax.current.scrollTo(1)}>
					<SvgIcon
						component={Cloud}
						viewBox="0 0 417 256"
						style={{
							display: "block",
							width: "12%",
							marginLeft: "70%",
							fill: "#9FE1D9",
							height: "50%",
						}}
					/>
					<SvgIcon
						component={Cloud}
						viewBox="0 0 417 256"
						style={{
							display: "block",
							width: "17%",
							marginLeft: "15%",
							fill: "#9FE1D9",
							height: "50%",
						}}
					/>
				</ParallaxLayer>
				<ParallaxLayer offset={0} speed={0.3} style={{ opacity: 0.3 }} onClick={() => parallax.current.scrollTo(1)}>
					<SvgIcon
						component={Cloud}
						viewBox="0 0 417 256"
						style={{
							display: "block",
							width: "10%",
							marginLeft: "10%",
							fill: "#9FE1D9",
							height: "50%",
						}}
					/>
					<SvgIcon
						component={Cloud}
						viewBox="0 0 417 256"
						style={{
							display: "block",
							width: "15%",
							marginLeft: "75%",
							fill: "#9FE1D9",
							height: "50%",
						}}
					/>
				</ParallaxLayer>

				<ParallaxLayer offset={1} speed={0.2} onClick={() => parallax.current.scrollTo(2)}>
					<SvgIcon
						component={Valldan}
						viewBox="0 0 25 30"
						style={{
							display: "block",
							width: "16%",
							marginLeft: "2%",
							fill: "#9FE1D9",
							height: "75%",
						}}
					/>
				</ParallaxLayer>

				<ParallaxLayer offset={1.5} speed={0.4} onClick={() => parallax.current.scrollTo(2)}>
					<SvgIcon
						component={StJoan}
						viewBox="0 0 26 30"
						style={{
							display: "block",
							width: "16%",
							marginLeft: "80%",
							fill: "#9FE1D9",
							height: "75%",
						}}
					/>
				</ParallaxLayer>

				<ParallaxLayer offset={2.5} speed={0.4} onClick={() => parallax.current.scrollTo(3)}>
					<SvgIcon
						component={StaEulalia}
						viewBox="0 0 26 30"
						style={{
							display: "block",
							width: "18%",
							marginLeft: "2%",
							fill: "#9FE1D9",
							height: "80%",
						}}
					/>
				</ParallaxLayer>
			</Parallax>
		</div>
	);
}
