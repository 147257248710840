import { IconButton, Tooltip } from "@material-ui/core";
import moment from "moment/min/moment-with-locales";
import { Edit, Eye } from "react-feather";
import { useNavigate } from "react-router";
import EscolaTaula from "../../../../components/EscolaTaula";

const EscolaActivitatsColumns = (activitats) => {
    const navigate = useNavigate();
    const columns = [
        {
            name: "id",
            label: "id",
            options: {
                filter: false,
                sort: false,
                display: false,
            },
        },

        {
            name: "escola.nom",
            label: "Escola",
            options: {
                filter: true,
                sort: true,
                display: false,
                sortOrder: "asc",
            },
        },
        {
            name: "nom",
            label: "Nom",
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: "hora_i",
            label: "Hora Inici",
            options: {
                filter: false,
                sort: true,
                sortOrder: "asc",
                customBodyRender: (value) =>
                    moment(value, "H:i").format("H:mm"),
            },
        },
        {
            name: "hora_f",
            label: "Hora Final",
            options: {
                filter: false,
                sort: true,
                sortOrder: "asc",
                customBodyRender: (value) =>
                    moment(value, "H:i").format("H:mm"),
            },
        },
        {
            name: "dia",
            label: "Dia de la setmana",
            options: {
                filter: true,
                sort: true,
                sortOrder: "asc",
                customBodyRender: (value) =>
                    moment(value, "e").locale("ca").format("dddd"),
            },
        },
        {
            name: "preu",
            label: "Preu",
            options: {
                filter: false,
                sort: true,
                sortOrder: "asc",
                customBodyRender: (value) => value + " €",
            },
        },
        {
            name: "imparteix",
            label: "Qui l'imparteix",
            options: {
                filter: false,
                sort: true,
                sortOrder: "asc",
            },
        },
        {
            name: "places",
            label: "Places",
            options: {
                filter: false,
                sort: true,
                sortOrder: "asc",
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <div>
                            {activitats[dataIndex].alumnes_count}/
                            {activitats[dataIndex].places}
                        </div>
                    );
                },
                customBodyRender: (value) => value + " €",
            },
        },
        {
            name: "Accions",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <Tooltip title="Veure">
                            <IconButton
                                onClick={() =>
                                    navigate(
                                        `/admin/activitats/${activitats[dataIndex].id}`,
                                        {
                                            state: activitats[dataIndex],
                                        }
                                    )
                                }
                            >
                                <Eye />
                            </IconButton>
                        </Tooltip>
                    );
                },
            },
        },
    ];
    return columns;
};

export default EscolaActivitatsColumns;
