import { useEffect, useState } from "react";

const Thumb = (props) => {
	const [loading, setLoading] = useState(false);
	const [thumb, setThumb] = useState();

	useEffect(() => {
		if (!props.file?.name) {
			setThumb("https://api.sinapsisberga.cat/storage/" + props.file);
			return;
		}
		setLoading(true);
		let reader = new FileReader();

		reader.onloadend = () => {
			setLoading(false);
			setThumb(reader.result);
		};
		reader.readAsDataURL(props.file);
	}, [props.file]);

	if (!props.file) {
		return null;
	}

	if (loading) {
		return <p>loading...</p>;
	}

	return <img src={thumb} alt={props.file.name} className="img-thumbnail mt-2" height={200} />;
};

export default Thumb;
