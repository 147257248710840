import { Box, Container, makeStyles, Paper, Table, TableCell, TableContainer, TableRow, Typography, TableBody } from "@material-ui/core";
import React, { useEffect } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Footer from "../../components/Footer";
import Page from "../../components/Page";

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: 30,
	},
	main: {
		[theme.breakpoints.down("sm")]: {
			width: "100%",
		},
	},
	image: {
		width: 150,
		textAlign: "center",
	},
}));

const Cookies = () => {
	const classes = useStyles();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Page title="Sinapsis - Política de Cookies">
			<ScrollAnimation animateIn="fadeIn" delay={300} style={{ transition: "0.5s" }}>
				<Container className={classes.root}>
					<Box className={classes.main} mb={8} mt={12}>
						<Typography variant="h2">Política de Cookies</Typography>
					</Box>

					<Box my={4}>
						<Typography variant="body1">
							A través d'aquest lloc web no es recapten dades personals dels usuaris sense el seu coneixement, ni se cedeixen a tercers.
						</Typography>
						<Typography variant="body1">
							Aquesta web no utilitza cookies per recollir informació dels usuaris, ni registra les adreces IP d'accés. Únicament s'utilitzen
							cookies pròpies, de sessió, amb finalitat tècnica (aquelles que permeten a l'usuari la navegació a través del lloc web i la
							utilització de les diferents opcions i serveis que hi ha).
						</Typography>
						<Typography variant="body1">
							Aquest lloc conté enllaços a altres llocs web de tercers, les polítiques de privacitat dels quals són alienes a la d'aquest lloc
							web. En accedir a aquests llocs web vostè pot decidir si accepta les seves polítiques de privacitat i de cookies. Amb caràcter
							general, si navega per Internet vostè pot acceptar o rebutjar les cookies de tercers des de les opcions de configuració del seu
							navegador.
						</Typography>
					</Box>
				</Container>
			</ScrollAnimation>
			<Footer />
		</Page>
	);
};

export default Cookies;
