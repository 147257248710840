import React, { useEffect, useState } from "react";
import { Box, Container, Grid, makeStyles, Button, CircularProgress, MuiThemeProvider, Typography } from "@material-ui/core";
import Page from "../../../components/Page";
import { useNavigate, useParams } from "react-router-dom";
import { getActivitat } from "../../../database/API";
import { useSnackbar } from "notistack";
import Title from "../../../components/Title";
import moment from "moment/min/moment-with-locales";
import Activitat from "../../../components/Activitat";
import MUIDataTable from "mui-datatables";
import ActivitatAlumnesColumns from "./Table/ActivitatAlumnesColumns";
import ActivitatAlumnesOptions from "./Table/ActivitatAlumnesOptions";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: "white",
		minHeight: "100%",
	},
	container: {
		paddingTop: 47,
	},
	formControl: {
		marginTop: 16,
		marginBottom: 15,
	},
	activitatWrap: {
		backgroundColor: "white",
		padding: 30,
		border: "6px solid white",
		cursor: "pointer",
		borderRadius: 10,
		transition: ".2s",
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
	},
	active: {
		border: "6px solid",
		borderColor: theme.palette.text.primary,
	},
	avatar: {
		width: 120,
		height: 120,
		border: "8px solid white",
		cursor: "pointer",
		"& img": {
			objectPosition: "top",
			width: "90%",
		},
		marginBottom: 15,
	},
}));

const ActivitatsView = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const [activitat, setActivitat] = useState();
	const [alumnes, setAlumnes] = useState([]);
	const [loading, setLoading] = useState(true);
	const { key } = useParams();
	const columns = ActivitatAlumnesColumns(activitat?.alumnes);
	const options = ActivitatAlumnesOptions(activitat?.alumnes);

	useEffect(() => {
		const get = async () => {
			const { activitat } = await getActivitat(key);
			setAlumnes(
				activitat?.alumnes.map((i, index) => {
					return { ...i, espera: index >= activitat?.places };
				})
			);
			setActivitat(activitat);
			setLoading(false);
		};
		get();
	}, [key]);

	return (
		<Page className={classes.root} title={"Detalls activitat"}>
			<Container maxWidth={false} className={classes.container} style={{ overflow: "hidden" }}>
				<Box mb={3}>
					<Title
						title={"Detalls activitat"}
						button={
							<Button variant="contained" className={classes.addButton} onClick={() => navigate("edit")}>
								Editar
							</Button>
						}
					/>
				</Box>
				{loading ? (
					<CircularProgress />
				) : (
					<Box mb={5}>
						<Grid container spacing={4}>
							<Grid item md={4}>
								<Activitat activitat={activitat} />
							</Grid>
							<Grid item md={8}>
								<MUIDataTable
									data={alumnes}
									columns={columns}
									options={options}
									title={
										<div>
											<Typography variant="h3">Alumnes inscrits</Typography>
										</div>
									}
								/>
							</Grid>
						</Grid>
					</Box>
				)}
			</Container>
		</Page>
	);
};

export default ActivitatsView;
