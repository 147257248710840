import { Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import React from "react";
import Page from "../../components/Page";
import Portada from "./Portada";
import PortadaMobil from "./PortadaMobil";

const useStyles = makeStyles(() => ({}));

const Home = () => {
	useStyles();

	return (
		<Page title="Sinapsis">
			<Hidden smDown>
				<Portada />
			</Hidden>
			<Hidden mdUp>
				<PortadaMobil />
			</Hidden>
		</Page>
	);
};

export default Home;
