import { colors } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

const breakpoints = createBreakpoints({});

const theme = createTheme({
	palette: {
		background: {
			dark: "#9FE1D9",
			default: "#9FE1D9",
			secondary: "#e8e3c5",
			alt: colors.common.white,
		},
		primary: {
			main: "#00000090",
			hover: "#b2d6cf90",
		},
		secondary: {
			main: "#393a3a",
		},
		third: {
			main: "#f28e5e",
			opacity: "#f28e5e90",
		},
		small: {
			main: "#817e6d",
		},
		danger: {
			main: colors.red[500],
			light: colors.red[300],
		},
		error: {
			main: "#ea7c7c",
		},
		success: {
			main: colors.green[500],
		},
		text: {
			primary: "#393a3a",
			secondary: "#383838",
			danger: colors.red[500],
		},
		typography: {
			fontFamily: "DIN",
		},
		admin: {
			text: "#393a3a",
			primary: "#b2d6cf",
			secondary: "#383838",
		},
	},
	overrides: {
		MuiTypography: {
			h1: {
				fontSize: "5rem",
				fontFamily: "Shermlock",
				textTransform: "uppercase",
				color: "#393a3a",
				[breakpoints.down("sm")]: {
					fontSize: "3rem",
				},
				[breakpoints.down("xs")]: {
					fontSize: "2rem",
				},
			},
			h2: {
				fontSize: "2.8rem",
				fontFamily: "Shermlock",
				textTransform: "uppercase",
				color: "#393a3a",
				[breakpoints.down("xs")]: {
					fontSize: "1.5rem",
				},
			},
			h3: {
				fontSize: "1.5rem",
				fontFamily: "Shermlock",
				fontWeight: 200,
				color: "#393a3a",
				textTransform: "uppercase",
				[breakpoints.down("xs")]: {
					fontSize: "1.2rem",
				},
			},
			h4: {
				fontSize: "1.3rem",
				fontFamily: "Montserrat",
				fontWeight: 700,
				color: "#393a3a",
				textTransform: "uppercase",
				[breakpoints.down("xs")]: {
					fontSize: "1rem",
				},
			},
			h5: {
				fontSize: "1.5rem",
				fontFamily: "Montserrat",
				fontWeight: 400,
				color: "#393a3a",
				[breakpoints.down("xs")]: {
					fontSize: "1.4rem",
				},
			},
			body1: {
				fontFamily: "Montserrat",
				fontSize: "1.1rem",
				color: "#1c1c1c",
				[breakpoints.down("xs")]: {
					fontSize: "0.9rem",
				},
			},
			caption: {
				fontFamily: "Montserrat",
				fontSize: "1rem",
				color: "#393a3a",
			},
			error: {
				fontFamily: "Montserrat",
				fontSize: "0.8rem",
				color: "#ea7c7c",
			},
		},
	},
});

export default theme;
