import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Input } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { updateField } from "../../../database/API";
import { useSnackbar } from "notistack";

export default function DialogUpdateField({ field, open, setOpen, id, setUpdate }) {
	const [loading, setLoading] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const {
		setValue,
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm();

	useEffect(() => {
		open ? setValue("value", field.value) : reset();
		open ? setValue("field", field.field) : reset();
	}, [open]);

	const enviar = async (values) => {
		setLoading(true);
		try {
			const { message } = await updateField(values, id);
			enqueueSnackbar(message, { variant: "success" });
			setOpen(false);
			setUpdate((prev) => prev + 1);
		} catch (e) {
			enqueueSnackbar("Alguna cosa no ha anat bé", { variant: "error" });
			console.log(e);
		}
		setLoading(false);

		console.log(values);
	};

	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			disableScrollLock
			maxWidth="md"
		>
			<form onSubmit={handleSubmit(enviar)}>
				<DialogTitle>Editar camp {field.id}</DialogTitle>
				<DialogContent>
					<Input
						error={Boolean(errors?.value)}
						fullWidth
						helperText={errors?.value?.message}
						label={field.id}
						margin="normal"
						variant="outlined"
						type="text"
						{...register("value")}
					/>
				</DialogContent>
				<DialogActions>
					{loading && <CircularProgress size="20px" />}
					<Button onClick={() => setOpen(false)}>Tancar</Button>

					<Button type="submit" disabled={loading}>
						Enviar
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
}
