export function constructFormInscripcioCasals(values) {
	const data = new FormData();

	data.append("nom", values.nom);
	data.append("data_naixement", values.data_naixement);
	data.append("edat", values.edat);
	data.append("curs", values.curs);
	data.append("adreca", values.adreca);
	data.append("codi_postal", values.codi_postal);
	data.append("poblacio", values.poblacio);
	data.append("email", values.email);
	data.append("telefon", values.telefon);
	data.append("telefon_alt", values.telefon_alt);
	data.append("recollir", values.recollir);
	data.append("colegi", values.colegi);
	data.append("ubicacio", values.ubicacio);
	data.append("recollida", values.recollida ? 1 : 0);
	data.append("menjador", values.menjador ? 1 : 0);
	data.append("carmanyola", values.carmanyola ? 1 : 0);
	data.append("piscina", values.piscina ? 1 : 0);
	data.append("pare", values.pare);
	data.append("mare", values.mare);
	data.append("ampa", values.ampa ? 1 : 0);
	data.append("nombrosa", values.nombrosa ? 1 : 0);
	data.append("setmanes", JSON.stringify(values.setmanes));
	data.append("metge", values.metge);
	data.append("ss", values.ss);
	data.append("personalitat", values.personalitat ?? "");
	data.append("alergia", values.alergia ? 1 : 0);
	data.append("alergies", values.alergies ?? "");
	data.append("regim", values.regim ? 1 : 0);
	data.append("regims", values.regims ?? "");
	data.append("observacions", values.observacions ?? "");

	if (values.foto_ss?.[0]?.name) {
		data.append("foto_ss", values.foto_ss[0], values.foto_ss[0].name);
	}
	if (values.foto_targeta?.[0]?.name) {
		data.append("foto_targeta", values.foto_targeta[0], values.foto_targeta[0].name);
	}
	if (values.foto_nen?.[0]?.name) {
		data.append("foto_nen", values.foto_nen[0], values.foto_nen[0].name);
	}

	data.append("imatge", values.imatge ? 1 : 0);
	data.append("preu", values.preu);

	return data;
}
