import { TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const Input = withStyles((theme) => ({
    root: {
        "& .MuiOutlinedInput-root": {
            borderRadius: 0,
            "& fieldset": {
                borderRadius: 0,
                borderColor: theme.palette.background.black,
            },
            "&.Mui-focused fieldset": {
                borderRadius: 0,
            },
        },
        "& .MuiOutlinedInput-input:-webkit-autofill": {
            borderRadius: 0,
        },
    },
}))(TextField);

export default Input;
