import { Grid } from "@material-ui/core";
import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
	item: {
		transition: "0.2s",
		cursor: "pointer",
		overflow: "hidden",
		position: "relative",
		justifyContent: "center",
		display: "flex",
		"&:hover": {
			transform: "scale(1.1)",
			"&> div > img": {
				opacity: 0.2,
			},
			"&> div > div > h3": {
				opacity: 1,
			},
		},
	},
	title: {
		color: "white",
		fontSize: 28,
		paddingTop: 30,
		paddingBottom: 15,
	},
	avatar: {
		borderRadius: 200,
		width: 250,
		height: 250,
		overflow: "hidden",
		textAlign: "center",
	},
	imatge: {
		transition: "0.2s",
		width: 160,
	},
	overlay: {
		position: "absolute",
		top: "50%",
		right: 0,
		left: 0,
		margin: "auto",
		textAlign: "center",
		"&> h3": {
			color: "white",
			transition: "0.2s",
			fontSize: 30,
			opacity: 0,
		},
	},
}));

const EscolaSingle = (props) => {
	const classes = useStyles();
	const { escola } = props;
	const { onClick } = props;

	return (
		<Grid item lg={4} className={classes.item} onClick={onClick} key={escola.id}>
			<Box className={classes.avatar} style={{ backgroundColor: escola.color }}>
				<Typography variant="h3" className={classes.title}>
					{escola.nom}
				</Typography>
				<img alt="monigote" src={"https://api.sinapsisberga.cat/storage/" + escola.imatge} className={classes.imatge} />
				<Box className={classes.overlay}>
					<Typography variant="h3">INSCRIU-TE!</Typography>
				</Box>
			</Box>
		</Grid>
	);
};

export default EscolaSingle;
