import React, { useEffect, useState } from "react";
import {
	Box,
	Container,
	Grid,
	makeStyles,
	Button,
	CircularProgress,
	Typography,
	TableContainer,
	Table,
	TableRow,
	TableCell,
	TableBody,
	Checkbox,
	Avatar,
	Tooltip,
	IconButton,
} from "@material-ui/core";
import Page from "../../../components/Page";
import { useNavigate, useParams } from "react-router-dom";
import { descarregarPdf, getInscrit } from "../../../database/API";
import Title from "../../../components/Title";
import moment from "moment";
import clsx from "clsx";
import DialogUpdateField from "./DialogUpdateField";
import { Edit } from "react-feather";

const InscritView = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const [alumne, setAlumne] = useState();
	const [loading, setLoading] = useState(true);
	const [open, setOpen] = useState(false);
	const [field, setField] = useState("");
	const [update, setUpdate] = useState(0);
	const { key } = useParams();

	useEffect(() => {
		const get = async () => {
			const { alumne } = await getInscrit(key);
			setAlumne(alumne);
			setLoading(false);
		};
		get();
	}, [key, update]);

	const botoDescarregarPdf = async () => {
		setLoading(true);
		await descarregarPdf(alumne.id);
		setLoading(false);
	};

	const info = [
		{ id: "Nom", value: alumne?.nom, field: "nom" },
		{ id: "E-mail", value: alumne?.email, field: "email" },
		{ id: "Data naixement", value: alumne?.data_naixement, field: "data_naixement" },
		{ id: "Edat", value: alumne?.edat, field: "edat" },
		{ id: "Curs", value: alumne?.curs, field: "curs" },
		{ id: "Adreça", value: alumne?.adreca, field: "adreca" },
		{ id: "Població", value: alumne?.poblacio, field: "poblacio" },
		{ id: "Telèfon", value: alumne?.telefon, field: "telefon" },
		{ id: "Telèfon 2", value: alumne?.telefon_alt, field: "telefon_alt" },
		{ id: "Qui el recull?", value: alumne?.recollir, field: "recollir" },
		{ id: "Escola", value: alumne?.colegi, field: "colegi" },
		{ id: "Nom pare", value: alumne?.pare, field: "pare" },
		{ id: "Nom mare", value: alumne?.mare, field: "mare" },
		{
			id: "Ampa",
			value: <Checkbox disabled checked={alumne?.ampa === "1" ? true : false} />,
		},
		{
			id: "Dret d'imatge",
			value: <Checkbox disabled checked={alumne?.imatge === "1" ? true : false} />,
		},
		{
			id: "Família nombrosa",
			value: <Checkbox disabled checked={alumne?.nombrosa === "1" ? true : false} />,
		},
	];

	const info_inscripcio = [
		// { id: "Escola on s'ha inscrit", value: alumne?.ubicacio },
		// {
		// 	id: "Servei d'acollida",
		// 	value: <Checkbox disabled checked={alumne?.recollida === "1" ? true : false} />,
		// },
		// {
		// 	id: "Servei de menjador",
		// 	value: <Checkbox disabled checked={alumne?.menjador === "1" ? true : false} />,
		// },
		{
			id: "Carmanyola",
			value: <Checkbox disabled checked={alumne?.carmanyola === "1" ? true : false} />,
		},
		// {
		// 	id: "Cursets piscina",
		// 	value: <Checkbox disabled checked={alumne?.piscina === "1" ? true : false} />,
		// },
	];

	const info_salut = [
		{ id: "Nom metge o pediatre", value: alumne?.metge, field: "metge" },
		{ id: "Número targeta SS", value: alumne?.ss, field: "ss" },
		{ id: "Característica personalitat", value: alumne?.personalitat, field: "personalitat" },
		{ id: "Al·lèrgies", value: <Checkbox disabled checked={alumne?.alergia === "1" ? true : false} /> },
		{ id: "Quines al·lèrgies", value: alumne?.alergies, field: "alergies" },
		{ id: "Règim alimentari", value: <Checkbox disabled checked={alumne?.regim === "1" ? true : false} /> },
		{ id: "Quin règim alimentari", value: alumne?.regims, field: "regims" },
		{ id: "Observacions", value: alumne?.observacions, field: "observacions" },
	];

	return (
		<Page className={classes.root} title={"Detalls alumne"}>
			<Container maxWidth={false} className={classes.container} style={{ overflow: "hidden" }}>
				<Box mb={3}>
					<Title
						title={"Detalls inscrit"}
						button={
							<Button variant="contained" className={classes.addButton} onClick={botoDescarregarPdf}>
								Descarregar PDF
							</Button>
						}
					/>
				</Box>
				{loading ? (
					<CircularProgress />
				) : (
					<Grid container spacing={4}>
						<Grid item md={6}>
							<Box className={classes.paper} mb={5}>
								<Box mb={3}>
									<Typography variant="h3">Informació</Typography>
								</Box>
								<TableContainer>
									<Table sx={{ minWidth: 650 }} aria-label="simple table">
										<TableBody>
											{info?.map((row) => (
												<TableRow
													hover
													key={row.id}
													sx={{
														"&:last-child td, &:last-child th": { border: 0 },
													}}
													onClick={() => {
														if (row?.field) {
															setField(row);
															setOpen(true);
														}
													}}
												>
													<TableCell component="th" scope="row">
														{row.id}
													</TableCell>
													<TableCell align="right">{row.value}</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							</Box>
						</Grid>
						<Grid item md={6}>
							<Box className={classes.paper} mb={5}>
								<Box mb={3}>
									<Typography variant="h3">Informació inscripció</Typography>
								</Box>
								<TableContainer>
									<Table sx={{ minWidth: 650 }} aria-label="simple table">
										<TableBody>
											{info_inscripcio?.map(
												(row) =>
													row.value && (
														<TableRow
															hover
															key={row.id}
															sx={{
																"&:last-child td, &:last-child th": { border: 0 },
															}}
														>
															<TableCell component="th" scope="row">
																{row.id}
															</TableCell>
															<TableCell align="right">{row.value}</TableCell>
														</TableRow>
													)
											)}
										</TableBody>
									</Table>
								</TableContainer>
							</Box>
							<Box className={classes.paper}>
								<Box mb={3}>
									<Typography variant="h3">Setmanes inscrites</Typography>
								</Box>
								<Box mt={2} direction={"row"} spacing={2} justifyContent="center" display={"flex"}>
									{JSON.parse(alumne?.setmanes)
										?.sort((a, b) => (a.nom < b.nom ? -1 : a.nom > b.nom ? 1 : 0))
										?.map((item) => (
											<Box key={item.id} className={clsx(classes.selected, classes.setmana)}>
												<Box className={classes.check}></Box>
												<Box mr={1} ml={1} textAlign="center">
													<Typography pb={0} mb={-1}>
														{item?.nom}
													</Typography>
													<Typography variant="caption" style={{ fontSize: 12 }}>
														{item?.dies}
													</Typography>
												</Box>
											</Box>
										))}
								</Box>
							</Box>
							<Box className={classes.paper} my={4}>
								<Box mb={3}>
									<Typography variant="h3">Informació de salut</Typography>
								</Box>

								<TableContainer>
									<Table sx={{ minWidth: 650 }} aria-label="simple table">
										<TableBody>
											{info_salut?.map((row) => (
												<TableRow
													hover
													key={row.id}
													sx={{
														"&:last-child td, &:last-child th": { border: 0 },
													}}
													onClick={() => {
														if (row?.field) {
															setField(row);
															setOpen(true);
														}
													}}
												>
													<TableCell component="th" scope="row">
														{row.id}
													</TableCell>
													<TableCell align="right">{row.value}</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
								<Box display="flex" mt={3} justifyContent={"space-around"}>
									<Tooltip title="Fotografia Targeta Seguretat Social">
										<a href={"https://api.sinapsisberga.cat/storage/" + alumne?.foto_ss} target="_blank">
											<Avatar src={"https://api.sinapsisberga.cat/storage/" + alumne?.foto_ss} />
										</a>
									</Tooltip>
									<Tooltip title="Fotografia Targeta Sanitària">
										<a href={"https://api.sinapsisberga.cat/storage/" + alumne?.foto_targeta} target="_blank">
											<Avatar src={"https://api.sinapsisberga.cat/storage/" + alumne?.foto_targeta} />
										</a>
									</Tooltip>
									<Tooltip title="Fotografia nen/a">
										<a href={"https://api.sinapsisberga.cat/storage/" + alumne?.foto_nen} target="_blank">
											<Avatar src={"https://api.sinapsisberga.cat/storage/" + alumne?.foto_nen} />
										</a>
									</Tooltip>
								</Box>
							</Box>
							<Box className={classes.paper} mt={3} mb={5} justifyContent="space-between" display="flex">
								<Typography variant="h3">Preu</Typography>
								<Typography variant="h4">{alumne?.preu}€</Typography>
							</Box>
						</Grid>
					</Grid>
				)}
			</Container>
			<DialogUpdateField open={open} setOpen={setOpen} field={field} id={key} setUpdate={setUpdate} />
		</Page>
	);
};

export default InscritView;

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: "white",
		minHeight: "100%",
	},
	container: {
		paddingTop: 47,
	},
	paper: {
		backgroundColor: "white",
		borderRadius: 5,
		padding: 30,
		boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
	},
	formControl: {
		marginTop: 16,
		marginBottom: 15,
	},
	activitatWrap: {
		backgroundColor: "white",
		padding: 30,
		border: "6px solid white",
		cursor: "pointer",
		borderRadius: 10,
		transition: ".2s",
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
	},
	avatar: {
		width: 120,
		height: 120,
		border: "8px solid white",
		cursor: "pointer",
		"& img": {
			objectPosition: "top",
			width: "90%",
		},
		marginBottom: 15,
	},
	setmana: {
		padding: 10,
		backgroundColor: "white",
		margin: 5,
		borderRadius: 10,
		transition: "0.2s",
		cursor: "pointer",
		opacity: 0.5,
	},
	selected: {
		backgroundColor: "green",
		opacity: 1,
		"& .MuiTypography-root": {
			color: "white",
		},
	},
}));
