import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Container, createMuiTheme, Fade, makeStyles, MuiThemeProvider } from "@material-ui/core";
import { getInscrits } from "../../../database/API";
import MUIDataTable from "mui-datatables";
import Page from "../../../components/Page";
import Title from "../../../components/Title";
import InscritsColumns from "./Table/InscritsColumns";
import InscritsOptions from "./Table/InscritsOptions";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: "white",
		minHeight: "100%",
	},
	container: {
		paddingTop: 40,
		paddingBottom: 40,
	},
}));

const getMuiTheme = () =>
	createMuiTheme({
		overrides: {
			TableHead: {
				root: {
					fontSize: 23,
				},
			},
		},
	});

const InscritsAdmin = () => {
	const classes = useStyles();
	const [alumnes, setAlumnes] = useState();
	const [loading, setLoading] = useState(true);
	const columns = InscritsColumns(alumnes);
	const options = InscritsOptions(alumnes);

	useEffect(() => {
		const get = async () => {
			const { alumnes } = await getInscrits();
			setAlumnes(alumnes);
			setLoading(false);
		};

		get();
	}, []);

	return (
		<Page className={classes.root} title={"Inscrits"}>
			<Container maxWidth={false} className={classes.container}>
				<Title title={"Inscrits Casals"} subtitle={"Administra els alumnes inscrits als casals d'estiu"} />
				<Box py={2} spacing={3}>
					{!loading ? (
						<Fade in={!loading}>
							<div>
								<MuiThemeProvider theme={getMuiTheme()}>
									<MUIDataTable data={alumnes} columns={columns} options={options} />
								</MuiThemeProvider>
							</div>
						</Fade>
					) : (
						<CircularProgress />
					)}
				</Box>
			</Container>
		</Page>
	);
};

export default InscritsAdmin;
