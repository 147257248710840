import { useSnackbar } from "notistack";
import { useNavigate } from "react-router";
import { deletePack } from "../../../../database/API";

const PacksOptions = (packs) => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const options = {
        enableNestedDataAccess: ".",
        filterType: "checkbox",
        onRowClick: (rowData) => {
            navigate(`${rowData[0]}`, {
                state: rowData,
            });
        },
        onRowsDelete: (rowsDeleted) => {
            rowsDeleted.data.forEach(async (item) => {
                let message = await deletePack(packs[item.dataIndex].id);
                enqueueSnackbar(message, {
                    variant: "success",
                });
                console.log(message);
            });
        },
        textLabels: {
            body: {
                noMatch: "No hi han packs",
                toolTip: "Ordenar",
                columnHeaderTooltip: (column) =>
                    `${"Ordenat per"} ${column.label}`,
            },
            pagination: {
                next: "Següent pàgina",
                previous: "Pàgina anterior",
                rowsPerPage: "Files per pàgina:",
                displayRows: "de", // 1-10 of 30
            },
            toolbar: {
                search: "Cercar",
                downloadCsv: "Descarregar CSV",
                print: "Imprimir",
                viewColumns: "Veure columnes",
                filterTable: "Filtrar taula",
            },
            filter: {
                title: "FILTRES",
                reset: "resetejar",
                all: "Tots",
            },
            viewColumns: {
                title: "Mostrar columnes",
            },
            selectedRows: {
                text: "Fila/es eliminades",
                delete: "Eliminar",
            },
        },
        downloadOptions: {
            filterOptions: {
                useDisplayedColumnsOnly: false,
                useDisplayedRowsOnly: true,
            },
        },
    };

    return options;
};

export default PacksOptions;
