import { useSnackbar } from "notistack";
import { deleteAlumne } from "../../../../database/API";
import { saveAs } from "file-saver";
import { write, utils } from "xlsx";
import moment from "moment";

const UsersOptions = (alumnes) => {
	const { enqueueSnackbar } = useSnackbar();

	const options = {
		// downloadOptions: { filename: "Alumnes.csv", separator: ";" },
		enableNestedDataAccess: ".",
		responsive: "standard",
		filterType: "checkbox",
		searchOpen: true,
		searchAlwaysOpen: true,
		searchText: localStorage.getItem("alumnesSearch") === "null" ? "" : localStorage.getItem("alumnesSearch"),
		onSearchChange: (s) => localStorage.setItem("alumnesSearch", s),
		onRowsDelete: (rowsDeleted) => {
			rowsDeleted.data.forEach(async (item) => {
				let message = await deleteAlumne(alumnes[item.dataIndex].id);
				enqueueSnackbar(message, {
					variant: "success",
				});
				console.log(message);
			});
		},
		textLabels: {
			body: {
				noMatch: "No hi han alumnes",
				toolTip: "Ordenar",
				columnHeaderTooltip: (column) => `${"Ordenat per"} ${column.label}`,
			},
			pagination: {
				next: "Següent pàgina",
				previous: "Pàgina anterior",
				rowsPerPage: "Files per pàgina:",
				displayRows: "de", // 1-10 of 30
			},
			toolbar: {
				search: "Cercar",
				downloadCsv: "Descarregar CSV",
				print: "Imprimir",
				viewColumns: "Veure columnes",
				filterTable: "Filtrar taula",
			},
			filter: {
				title: "FILTRES",
				reset: "resetejar",
				all: "Tots",
			},
			viewColumns: {
				title: "Mostrar columnes",
			},
			selectedRows: {
				text: "Fila/es eliminades",
				delete: "Eliminar",
			},
		},

		onDownload: (buildHead, buildBody, columns, values) => {
			const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
			const fileExtension = ".xlsx";
			// build the json, this is one way to do it
			console.log(values);
			const json = values.reduce((result, val) => {
				const temp = {};
				val.data.map((v, idx) => {
					if (idx !== 0 && idx !== 10) {
						if (Array.isArray(v)) {
							let act = v.map((activitat) => activitat.nom).join(", ");
							temp[columns[idx].label] = act;
						} else if (v === "1" || v === "0") {
							temp[columns[idx].label] = v === "1" ? "Sí" : "No";
						} else if (columns[idx].name === "created_at") {
							temp[columns[idx].label] = moment(new Date(v)).format("DD/MM/YYYY");
						} else {
							temp[columns[idx].label] = v;
						}
					}
				});
				result.push(temp);
				return result;
			}, []);

			const fileName = `Alumnes`;
			const ws = utils.json_to_sheet(json);
			const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
			const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
			const data = new Blob([excelBuffer], { type: fileType });
			saveAs(data, fileName + fileExtension);
			// cancel default  CSV download from table
			return false;
		},
	};

	return options;
};

export default UsersOptions;
