import { Checkbox, IconButton, Tooltip } from "@material-ui/core";
import moment from "moment";
import { Eye } from "react-feather";
import { useNavigate } from "react-router";

const InscritsColumns = (alumnes) => {
	const navigate = useNavigate();
	const columns = [
		{
			name: "id",
			label: "ID",
			options: {
				filter: false,
				sort: true,
				display: false,
			},
		},
		{
			name: "nom",
			label: "Nom",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value) => <strong>{value}</strong>,
			},
		},
		{
			name: "email",
			label: "E-mail",
			options: {
				filter: false,
				sort: true,
			},
		},
		// {
		// 	name: "ubicacio",
		// 	label: "Escola on s'ha inscrit",
		// 	options: {
		// 		filter: true,
		// 		sort: true,
		// 	},
		// },
		{
			name: "telefon",
			label: "Telèfon",
			options: {
				filter: false,
				sort: true,
			},
		},
		{
			name: "ampa",
			label: "AMPA",
			options: {
				filter: true,
				sort: true,
				customFilterListOptions: {
					render: (v) => (v === "1" ? "AMPA: Sí" : "AMPA: No"),
				},
				filterOptions: {
					renderValue: (v) => (v === "1" ? "Sí" : "No"),
				},
				customBodyRender: (value) => <Checkbox style={{ color: "#5e738a" }} disabled checked={value == 1 ? true : false} />,
			},
		},
		{
			name: "imatge",
			label: "Drets d'imatge",
			options: {
				filter: true,
				sort: true,
				customFilterListOptions: {
					render: (v) => (v === "1" ? "Drets d'imatge: Sí" : "Drets d'imatge: No"),
				},
				filterOptions: {
					renderValue: (v) => (v === "1" ? "Sí" : "No"),
				},
				customBodyRender: (value) => <Checkbox style={{ color: "#5e738a" }} disabled checked={value == 1 ? true : false} />,
			},
		},
		{
			name: "preu",
			label: "Preu",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) => value + " €",
			},
		},
		{
			name: "created_at",
			label: "Data d'inscripció",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) => moment(new Date(value)).format("DD/MM/YYYY"),
			},
		},
		{
			name: "id",
			label: "Accions",
			options: {
				filter: false,
				sort: false,
				empty: true,
				customBodyRenderLite: (dataIndex) => {
					return (
						<Tooltip title="Veure">
							<IconButton
								onClick={() =>
									navigate(`/admin/inscrits/${alumnes[dataIndex].id}`, {
										state: alumnes[dataIndex],
									})
								}
							>
								<Eye />
							</IconButton>
						</Tooltip>
					);
				},
			},
		},
	];
	return columns;
};

export default InscritsColumns;
