import React, { useState } from "react";
import { Link as RouterLink, Navigate, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
    Box,
    Button,
    Container,
    Link,
    TextField,
    Typography,
    makeStyles,
} from "@material-ui/core";
import { Login } from "../../database/API";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "white",
        height: "100%",
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    // "@global": {
    //     html: {
    //         width: "100%",
    //         height: "100%",
    //     },
    // },
}));

const LoginView = () => {
    const classes = useStyles();
    const [error, setError] = useState("");
    const [token, setToken] = useState("");
    let navigate = useNavigate();

    const logInEmail = async (values) => {
        const { email, password } = values;
        const token = await Login(email, password);
        console.log(token);
        navigate("/admin/alumnes");
    };

    return (
        <div className={classes.root} title={"Login"}>
            <Box
                display="flex"
                flexDirection="column"
                height="100%"
                justifyContent="center"
            >
                <Container maxWidth="sm">
                    <h4>{error}</h4>
                    <Formik
                        initialValues={{
                            email: "",
                            password: "",
                        }}
                        validationSchema={Yup.object().shape({
                            email: Yup.string()
                                .email("Ha de ser un email vàlid")
                                .max(255)
                                .required("L'email és obligatòri"),
                            password: Yup.string()
                                .max(255)
                                .required("La contrasenya és obligatòria"),
                        })}
                        onSubmit={(values) => {
                            logInEmail(values);
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <Box mb={3}>
                                    <Typography
                                        color="textPrimary"
                                        variant="h2"
                                    >
                                        {"Entrar"}
                                    </Typography>
                                    <Typography
                                        color="textSecondary"
                                        gutterBottom
                                        variant="body2"
                                    >
                                        {"Entra a la pàgina d'administració"}
                                    </Typography>
                                </Box>
                                <TextField
                                    error={Boolean(
                                        touched.email && errors.email
                                    )}
                                    fullWidth
                                    helperText={touched.email && errors.email}
                                    label="E-mail"
                                    margin="normal"
                                    name="email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="email"
                                    value={values.email}
                                    variant="outlined"
                                />
                                <TextField
                                    error={Boolean(
                                        touched.password && errors.password
                                    )}
                                    fullWidth
                                    helperText={
                                        touched.password && errors.password
                                    }
                                    label={"Contrasenya"}
                                    margin="normal"
                                    name="password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="password"
                                    value={values.password}
                                    variant="outlined"
                                />
                                <Box my={2}>
                                    <Button
                                        color="primary"
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                    >
                                        {"Sign in"}
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Container>
            </Box>
        </div>
    );
};

export default LoginView;
