import { Box, Container, makeStyles, Paper, Table, TableCell, TableContainer, TableRow, Typography, TableBody } from "@material-ui/core";
import React, { useEffect } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Footer from "../../components/Footer";
import Page from "../../components/Page";

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: 30,
	},
	main: {
		[theme.breakpoints.down("sm")]: {
			width: "100%",
		},
	},
	image: {
		width: 150,
		textAlign: "center",
	},
}));

const Politica = () => {
	const classes = useStyles();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const rows = [
		{ nom: "Responsable", desc: "ISAAC SANTIAGO VILARDAGA" },
		{
			nom: "DNI/NIF",
			desc: "39376869H",
		},
		{
			nom: "Direcció",
			desc: "Plaça Tarascón, 7 4º 4ª - 08600 - BERGA - BARCELONA",
		},
		{
			nom: "E-mail",
			desc: "sinapsis.berga@gmail.com",
		},
	];

	return (
		<Page title="Sinapsis - Política de privacitat">
			<ScrollAnimation animateIn="fadeIn" delay={300} style={{ transition: "0.5s" }}>
				<Container className={classes.root}>
					<Box className={classes.main} mb={8} mt={12}>
						<Typography variant="h2">Política de Privacidad</Typography>
					</Box>
					<Box my={4}>
						<Typography variant="body1">
							A ISAAC SANTIAGO VILARDAGA ens preocupem per la privacitat i la transparència. A continuació, li indiquem en detall els tractaments
							de dades personals que realitzem, així com tota la informació relativa als mateixos.
						</Typography>
					</Box>

					<Box my={4}>
						<Typography variant="h4">Qui és el responsable del tractament de les seves dades?</Typography>
						<TableContainer>
							<Table sx={{ minWidth: 650 }} aria-label="simple table">
								<TableBody>
									{rows?.map((row) => (
										<TableRow
											key={row.nom}
											sx={{
												"&:last-child td, &:last-child th": { border: 0 },
											}}
										>
											<TableCell>{row.nom}</TableCell>
											<TableCell>{row.desc}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
					<Box my={4}>
						<Typography variant="h4">Quins són els seus drets quan ens facilita les seves dades?</Typography>
						<Typography variant="body1">
							Qualsevol persona té dret a obtenir confirmació sobre si a ISAAC SANTIAGO VILARDAGA estem tractant, o no, dades personals que els
							concerneixen.
						</Typography>
						<Typography variant="body1">
							Les persones interessades tenen dret a accedir a les seves dades personals, així com a sol·licitar la rectificació de les dades
							inexactes o, si s'escau, sol·licitar la seva supressió quan, entre altres motius, les dades ja no siguin necessàries per a les
							finalitats que van ser recollides. Igualment té dret a la portabilitat de les seves dades.
						</Typography>
						<Typography variant="body1">
							En determinades circumstàncies, els interessats podran sol·licitar la limitació del tractament de les seves dades, cas en el qual
							únicament les conservarem per a l'exercici o la defensa de reclamacions.
						</Typography>
						<Typography variant="body1">
							En determinades circumstàncies i per motius relacionats amb la seva situació particular, els interessats podran oposar-se al
							tractament de les seves dades. En aquest cas, ISAAC SANTIAGO VILARDAGA deixarà de tractar les dades, excepte per motius legítims
							imperiosos, o l'exercici o la defensa de possibles reclamacions.
						</Typography>
						<Typography variant="body1">
							Podrà exercir materialment els seus drets de la següent forma: dirigint-se a sinapsis.berga@gmail.com o C/ Plaça Tarascón, 7 2n 2a.
							08600 Berga - Barcelona.
						</Typography>
						<Typography variant="body1">
							Quan s’enviïn comunicacions comercials utilitzant l’interès legítim del responsable com a base legal, l’interessat podrà oposar-se
							al tractament de les seves dades per aquesta finalitat.
						</Typography>
						<Typography variant="body1">
							El consentiment atorgat és per a totes les finalitats indicades la base legal del qual és el consentiment de l’interessat. Vostè té
							dret a retirar aquest consentiment en qualsevol moment, sense afectar la licitud del tractament basat en el consentiment previ a la
							seva retirada.
						</Typography>
						<Typography variant="body1">
							En el cas que senti vulnerats els seus drets pel que fa a la protecció de les seves dades personals, especialment quan no hagi
							obtingut satisfacció en l'exercici dels seus drets, pot presentar una reclamació davant l'Autoritat de Control en matèria de
							Protecció de Dades competent a través del seu lloc web: www.aepd.es.
						</Typography>
					</Box>
					<Box my={4}>
						<Typography variant="h3">1. Tractament de les dades de les inscripcions</Typography>
						<Typography variant="h4">Amb quina finalitat tractem les seves dades personals?</Typography>
						<Typography variant="body1">
							A ISAAC SANTIAGO VILARDAGA tractem la informació que ens faciliten les persones interessades per tal de realitzar la gestió
							administrativa, comptable i fiscal de les inscripcions, així com enviar comunicacions comercials sobre els nostres productes i
							serveis. En el cas que no faciliti les seves dades personals, no podrem complir amb les finalitats descrites. No es prendran
							decisions automatitzades en base a les dades facilitades.
						</Typography>
					</Box>
					<Box my={4}>
						<Typography variant="h4">Durant quant de temps conservarem les seves dades?</Typography>
						<Typography variant="body1">
							Les dades es conservaran mentre l' interessat no demani la seva supressió, i si s' escau, durant els anys necessaris per complir amb
							les obligacions legals.
						</Typography>
					</Box>
					<Box my={4}>
						<Typography variant="h4">Quina és la legitimació per al tractament de les seves dades?</Typography>
						<Typography variant="body1">
							Indiquem la base legal per al tractament de les seves dades:
							<p>
								- Execució d' un contracte o mesures precontractuals: Per al tractament de les dades recollides per a les inscripcions a les
								activitats. Gestió fiscal, comptable i administrativa del clients.
							</p>{" "}
							<p>- Consentiment de l' interessat: Tramesa de comunicacions comercials fins i tot per via electrònica.</p>
							<p>
								- Consentiment de l'interessat: Captació de les imatges per a la publicació en els mitjans informatius que utilitzi l'empresa
								com ara la web i/o xarxes socials.
							</p>
						</Typography>
					</Box>
					<Box my={4}>
						<Typography variant="h4">A quins destinataris es comunicaran les seves dades?</Typography>
						<Typography variant="body1">
							Les dades es comunicaran als destinataris següents:
							<p>- Administració Tributària, amb la finalitat de complir amb les obligacions legals (requisit legal).</p>
							<p>- Entitats financeres, amb la finalitat de girar els rebuts corresponents (requisit contractual).</p>
							<p>
								- Tots els mitjans informatius que utilitza l' empresa per a la publicació de les activitats, amb la finalitat de Comunicació d'
								imatges per als mitjans informatius de l' empresa.
							</p>
						</Typography>
					</Box>

					<Box my={4}>
						<Typography variant="h4">Transferències de dades a tercers països</Typography>
						<Typography variant="body1">
							Estan previstes les transferències de dades següents a tercers països:
							<p>
								- Facebook, amb la finalitat de publicació d' imatges i vídeos. La garantia per a aquesta transferència s' ha establert a través
								de: Consentiment explícit de l' interessat. Podeu consultar informació addicional a: https://es-es.facebook.com/privacy/.
							</p>
							<p>
								- Gmail (google), amb la finalitat d'enviament i recepció de correus electrònics. La garantia per a aquesta transferència s' ha
								establert a través de: Clàusules tipus de protecció de dades. Podeu consultar informació addicional a:
								https://policies.google.com/privacy?hl=es.
							</p>
							<p>
								- Instagram, amb la finalitat de publicació d' imatges i vídeos. La garantia per a aquesta transferència s' ha establert a
								través de: Consentiment explícit de l' interessat. Podeu consultar informació addicional a:
								https://instagram.com/about/legal/privacy.
							</p>
							<p>
								- Linkedin, amb la finalitat de publicació d'imatges/vídeos... La garantia per a aquesta transferència s' ha establert a través
								de: Consentiment explícit de l' interessat. Podeu consultar informació addicional a:
								https://www.linkedin.com/legal/privacy-policy.
							</p>
							<p>
								- Twitter, amb la finalitat de finalitat de Publicació d'imatges i vídeos. La garantia per a aquesta transferència s' ha
								establert a través de: Consentiment explícit de l' interessat. Podeu consultar informació addicional a:
								https://twitter.com/en/privacy.
							</p>
							<p>
								- Youtube, amb la finalitat de publicació d'imatges/vídeos. La garantia per a aquesta transferència s' ha establert a través de:
								Consentiment explícit de l' interessat. Podeu consultar informació addicional a: https://policies.google.com/privacy?hl=es.
							</p>
						</Typography>
					</Box>

					<Box my={4}>
						<Typography variant="h4">Com hem obtingut les seves dades?</Typography>
						<Typography variant="body1">
							<p>
								Les dades personals que tractem a ISAAC SANTIAGO VILARDAGA procedeixen de: del mateix interessat o en el seu defecte dels menors
							</p>{" "}
							<p>
								No es tracten categories especials de dades personals (són aquelles dades que revelin l'origen ètnic o racial, les opinions
								polítiques, les conviccions religioses o filosòfiques, o l'afiliació sindical, dades genètiques, dades biomètriques dirigides a
								identificar de manera unívoca una persona física, dades relatives a la salut o dades relatives a la vida sexual o l'orientació
								sexual d'una persona física).
							</p>
						</Typography>
					</Box>

					<Box my={4}>
						<Typography variant="h3">2. Tractament de les dades recollides en el formulari de contacte</Typography>
						<Typography variant="h4">Amb quina finalitat tractem les seves dades personals?</Typography>
						<Typography variant="body1">
							A ISAAC SANTIAGO VILARDAGA tractem la informació que ens faciliten les persones interessades per tal de realitzar la gestió de
							potencials clients que s'han interessat sobre els nostres productes i/o serveis, així com altres contactes comercials i realitzar,
							si s'escau, l'enviament de comunicacions comercials inclusivament per via electrònica. En el cas que no faciliti les seves dades
							personals, no podrem complir amb les finalitats descrites. No es prendran decisions automatitzades en base a les dades facilitades.
						</Typography>
					</Box>
					<Box my={4}>
						<Typography variant="h4">Durant quant de temps conservarem les seves dades?</Typography>
						<Typography variant="body1">Les dades es conservaran mentre l' interessat no sol·liciti la seva supressió.</Typography>
					</Box>
					<Box my={4}>
						<Typography variant="h4">Quina és la legitimació per al tractament de les seves dades?</Typography>
						<Typography variant="body1">
							Indiquem la base legal per al tractament de les seves dades:
							<p>
								Execució d'un contracte o mesures precontractuals: Gestió de potencials clients que s'han interessat sobre els nostres productes
								i/o serveis.
							</p>
							<p>Consentiment de l' interessat: Enviar comunicacions comercials, inclusivament per via electrònica.</p>
							<p>Interès legítim del Responsable: Gestió de les dades de contacte professionals</p>
						</Typography>
					</Box>
					<Box my={4}>
						<Typography variant="h4">A quins destinataris es comunicaran les seves dades?</Typography>
						<Typography variant="body1">No se cediran dades a tercers, llevat d' obligació legal.</Typography>
					</Box>
					<Box my={4}>
						<Typography variant="h4">Transferències de dades a tercers països</Typography>
						<Typography variant="body1">Estan previstes les següents transferències de dades a tercers països:</Typography>
						<Typography variant="body1">
							{" "}
							- Gmail (google), amb la finalitat d'enviament i recepció de correus electrònics. La garantia per a aquesta transferència s' ha
							establert a través de: Clàusules tipus de protecció de dades. Podeu consultar informació addicional a:
							https://policies.google.com/privacy?hl=es.
						</Typography>
					</Box>

					<Box my={4}>
						<Typography variant="h4">Com hem obtingut les seves dades?</Typography>
						<Typography variant="body1">Les dades personals que tractem a ISAAC SANTIAGO VILARDAGA provenen de: El mateix interessat.</Typography>
					</Box>
				</Container>
			</ScrollAnimation>
			<Footer />
		</Page>
	);
};

export default Politica;
