import { makeStyles } from "@material-ui/core";

const navBarStyle = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.default,
		display: "flex",
		height: "100%",
		overflow: "hidden",
		width: "100%",
	},
	wrapper: {
		display: "flex",
		flex: "1",
		overflow: "hidden",
	},
	contentContainer: {
		display: "flex",
		flex: "1 1 auto",
		overflow: "hidden",
	},
	content: {
		flex: "1 1 auto",
		height: "100%",
	},
	navBar: {
		backgroundColor: "transparent",
		justifyContent: "flex-start",
		alignContent: "flex-start",
		transition: "0.5s",
	},
	toolbar: {
		alignItems: "center",
		justifyContent: "center",
	},
	icon: {
		marginRight: theme.spacing(1),
		width: 80,
		height: 80,
	},
	inscripcions: {
		backgroundColor: theme.palette.third.main,
		padding: 1,
		paddingRight: 20,
		paddingLeft: 20,
		color: "white",
		borderRadius: 20,
		marginRight: 10,
		marginLeft: 10,
		"&:hover": {
			backgroundColor: theme.palette.third.opacity,
		},
	},
	inscripcions2: {
		backgroundColor: theme.palette.secondary.main,
		padding: 1,
		paddingRight: 20,
		paddingLeft: 20,
		color: "white",
		borderRadius: 20,
		marginRight: 10,
		marginLeft: 10,
		"&:hover": {
			backgroundColor: theme.palette.third.opacity,
		},
	},
	showTitle: {
		transition: "0.5s",
		opacity: 1,
	},
	hideTitle: {
		transition: "0.5s",
		opacity: 0,
	},
	adminButton: {
		position: "absolute",
		right: 30,
		top: 30,
	},
}));

export default navBarStyle;
