import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink, Navigate, useNavigate } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { AppBar, Box, Hidden, IconButton, Toolbar, makeStyles, SvgIcon } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import InputIcon from "@material-ui/icons/Input";
import { Dashboard } from "@material-ui/icons";
import { ReactComponent as Logo } from "../../theme/icons/logo-nou.svg";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.admin.primary,
	},
	avatar: {
		width: 60,
		height: 60,
	},
	icon: {
		paddingLeft: 20,
		paddingTop: 10,
		fill: "white",
		width: 170,
		height: 50,
	},
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
	const classes = useStyles();
	const [isLogged, setIsLogged] = useState(localStorage.getItem("isLoggedIn") || "");
	let navigate = useNavigate();

	useEffect(() => {
		setIsLogged(localStorage.getItem("isLoggedIn"));
	}, []);

	const logOut = () => {
		localStorage.removeItem("user");
		localStorage.setItem("isLoggedIn", false);
		setIsLogged(false);
		navigate("/");
	};

	return (
		<AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
			<Toolbar>
				<RouterLink to="/">
					<Logo fill="white" style={{ width: 200, height: 50, marginTop: 5 }} />
					{/* <SvgIcon className={classes.icon} component={Logo} viewBox="0 0 24 5" /> */}
				</RouterLink>
				<Box flexGrow={1} />
				<Hidden>
					{isLogged ? (
						<>
							<IconButton color="inherit" onClick={() => navigate("/")}>
								<Dashboard />
							</IconButton>
							<IconButton color="inherit" onClick={() => logOut()}>
								<InputIcon />
							</IconButton>
						</>
					) : (
						<IconButton color="inherit" onClick={() => navigate("/admin/alumnes")}>
							<Dashboard />
						</IconButton>
					)}
				</Hidden>
				<Hidden lgUp>
					<IconButton color="inherit" onClick={onMobileNavOpen}>
						<MenuIcon />
					</IconButton>
				</Hidden>
			</Toolbar>
		</AppBar>
	);
};

TopBar.propTypes = {
	className: PropTypes.string,
	onMobileNavOpen: PropTypes.func,
};

export default TopBar;
