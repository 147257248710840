import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Button, ListItem, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    item: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0,
    },
    button: {
        fontWeight: theme.typography.fontWeightMedium,
        justifyContent: 'flex-start',
        letterSpacing: 0,
        padding: '10px 8px',
        textTransform: 'none',
        width: '100%',
    },
    icon: {
        color: theme.palette.admin.text,
        marginRight: theme.spacing(1),
    },
    title: {
        color: theme.palette.admin.text,
        marginRight: 'auto',
    },
    active: {
        backgroundColor: theme.palette.admin.primary,
        color: 'white',
        '& $title': {
            color: theme.palette.admin.secondary,
            fontWeight: '700',
        },
        '& $icon': {
            color: theme.palette.admin.secondary,
        },
    },
}));

const NavItem = ({ className, href, icon: Icon, title, onClose, ...rest }) => {
    const classes = useStyles();

    return (
        <ListItem
            className={clsx(classes.item, className)}
            disableGutters
            {...rest}
        >
            <Button
                activeClassName={classes.active}
                className={classes.button}
                component={RouterLink}
                to={href}
                onClick={onClose}
            >
                <Icon className={classes.icon} size="20" />

                <Typography variant="body1" className={classes.title}>
                    {title}
                </Typography>
            </Button>
        </ListItem>
    );
};

NavItem.propTypes = {
    className: PropTypes.string,
    href: PropTypes.string,
    icon: PropTypes.elementType,
    title: PropTypes.string,
};

export default NavItem;
