import { Typography } from '@material-ui/core';
import {
    Avatar,
    Box,
    Card,
    CardHeader,
    Grid,
    makeStyles,
} from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    item: { paddingTop: 25 },
    text: {
        paddingTop: 5,
        paddingLeft: 10,
    },
    index: {
        width: 20,
    },
    media: {
        paddingTop: '100%', // 16:9
    },
    imatge: {
        width: 200,
        padding: 20,
    },
    imatgeWrap: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 30,
    },
    avatar: {
        width: 180,
        height: 180,
        border: '8px solid white',
        '& img': {
            objectPosition: 'top',
        },
    },
    link: {
        textDecoration: 'none',
        '& img': {
            transition: '.2s',
        },
        '& .MuiAvatar-root': {
            transition: '.2s',
        },
        '& :hover': {
            '& img': {
                transform: 'scale(1.1)',
            },
            '& .MuiAvatar-root': {
                borderColor: theme.palette.admin.text,
            },
        },
    },
}));

const EscolaItem = (props) => {
    const { item } = props;
    const classes = useStyles();

    return (
        <Grid item md={4} xs={12} key={item.sku}>
            <Link to={`/admin/escoles/${item.id}`} className={classes.link}>
                <Card
                    className={classes.root}
                    style={{ backgroundColor: item.color_alt }}
                >
                    <Box className={classes.imatgeWrap}>
                        <Avatar
                            alt={item.nom}
                            className={classes.avatar}
                            src={
                                'https://api.sinapsisberga.cat/storage/' +
                                item.imatge
                            }
                            style={{ backgroundColor: item.color }}
                        />
                    </Box>
                    <CardHeader
                        style={{
                            textAlign: 'center',
                            textDecoration: 'none',
                        }}
                        title={
                            <Typography variant="h4" style={{ color: 'white' }}>
                                {item.nom}
                            </Typography>
                        }
                    />
                </Card>
            </Link>
        </Grid>
    );
};

export default EscolaItem;
