import * as Yup from "yup";

const schema = Yup.object().shape({
	nom: Yup.string().max(255).required("El nom és obligatòri"),
	data_naixement: Yup.string().max(255).required("La data de naixement és obligatòria"),
	email: Yup.string().email("Ha de ser un email vàlid").max(255).required("L'email és obligatòri"),
	telefon: Yup.string().max(255).required("El telèfon és obligatòri"),
	adreca: Yup.string().max(255).required("L'adreça és obligatòria"),
	codi_postal: Yup.string().max(255).required("El codi postal és obligatòri"),
	poblacio: Yup.string().max(255).required("La població és obligatòria"),
	recollir: Yup.string().max(255).required("El camp és obligatòri"),
	colegi: Yup.string().max(255).required("El camp col·legi és obligatòri"),
	ss: Yup.string().max(255).required("El camp número de la targeta de la Seguretat Social és obligatòri"),
	metge: Yup.string().max(255).required("El camp metge és obligatòri"),
	foto_targeta: Yup.mixed().test("fileSize", "La imatge és massa gran (max. 2MB) o no s'ha trobat", (value) => {
		if (!value.length) return false; // attachment is optional
		return value[0].size <= 2000000;
	}),
	foto_ss: Yup.mixed().test("fileSize", "La imatge és massa gran (max. 2MB) o no s'ha trobat", (value) => {
		if (!value.length) return false; // attachment is optional
		return value[0].size <= 2000000;
	}),
	foto_nen: Yup.mixed().test("fileSize", "La imatge és massa gran (max. 2MB) o no s'ha trobat", (value) => {
		if (!value.length) return false; // attachment is optional
		return value[0].size <= 2000000;
	}),
	accepta: Yup.bool().oneOf([true], "Has d'acceptar la Política de Privacitat per continuar"),
	assistir: Yup.bool().oneOf([true], "Has d'acceptar l'assistència per continuar"),
});

export default schema;
