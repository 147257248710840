import React, { useEffect, useState } from 'react';
import {
    Box,
    Container,
    Grid,
    makeStyles,
    TextField,
    Typography,
    Button,
    CircularProgress,
    Fade,
} from '@material-ui/core';
import Page from '../../../components/Page';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { getActivitats, getEscola, updateEscola } from '../../../database/API';
import { useSnackbar } from 'notistack';
import Title from '../../../components/Title';
import Thumb from '../../../components/Thumb';
import { ColorPicker } from 'material-ui-color';
import MUIDataTable from 'mui-datatables';
import EscolaActivitatsColumns from './Table/EscolaActivitatsColumns';
import ActivitatsOptions from '../Activitats/Table/ActivitatsOptions';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'white',
        minHeight: '100%',
    },
    container: {
        paddingTop: 47,
    },
    '@global': {
        html: {
            width: '100%',
            height: '100%',
        },
    },
    formControl: {
        marginTop: 16,
        marginBottom: 15,
    },
    paper: {
        backgroundColor: 'white',
        borderRadius: 5,
        padding: 30,
        boxShadow:
            '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    },
}));

const EscolesEdit = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { key } = useParams();
    const [escola, setEscola] = useState();
    const [activitats, setActivitats] = useState([]);
    const [loading, setLoading] = useState(true);
    const columns = EscolaActivitatsColumns(activitats);
    const options = ActivitatsOptions(activitats);

    useEffect(() => {
        const get = async () => {
            const { escola } = await getEscola(key);
            const { activitats } = await getActivitats(key);
            setActivitats(activitats);
            setEscola(escola);
            setLoading(false);
        };
        get();
    }, [key]);

    const editar = async (values) => {
        const message = await updateEscola(values, key);
        console.log(values);
        enqueueSnackbar(message, {
            variant: 'success',
        });
        navigate(-1, { state: values });
    };

    return (
        <Page className={classes.root} title={'Editar escola'}>
            <Container maxWidth={false} className={classes.container}>
                <Box mb={3}>
                    <Title
                        title={'Resum escola'}
                        subtitle={"Dades i edició d'escola"}
                    />
                </Box>
                <Grid container spacing={3}>
                    <Grid item md={7}>
                        {!loading ? (
                            <MUIDataTable
                                data={activitats}
                                columns={columns}
                                options={options}
                                title={
                                    <div>
                                        <Typography variant="h3">
                                            Activitats
                                        </Typography>
                                    </div>
                                }
                            />
                        ) : (
                            <Box className={classes.paper}>
                                <CircularProgress />
                            </Box>
                        )}
                    </Grid>
                    <Grid item md={5}>
                        <Box className={classes.paper}>
                            <Box mb={2}>
                                <Typography variant="h3">
                                    Editar Escola
                                </Typography>
                            </Box>
                            {loading ? (
                                <CircularProgress />
                            ) : (
                                <Fade in={!loading}>
                                    <Formik
                                        enableReinitialize
                                        initialValues={{
                                            nom: escola?.nom,
                                            color: escola?.color,
                                            color_alt: escola?.color_alt,
                                            imatge: escola?.imatge,
                                        }}
                                        validationSchema={Yup.object().shape({
                                            nom: Yup.string().required(
                                                'El nom és obligatòri'
                                            ),
                                        })}
                                        onSubmit={(values) => {
                                            editar(values);
                                        }}
                                    >
                                        {({
                                            errors,
                                            handleBlur,
                                            handleChange,
                                            handleSubmit,
                                            isSubmitting,
                                            setFieldValue,
                                            touched,
                                            values,
                                        }) => (
                                            <form onSubmit={handleSubmit}>
                                                <Grid container spacing={3}>
                                                    <Grid item md={12}>
                                                        <TextField
                                                            error={Boolean(
                                                                touched.nom &&
                                                                    errors.nom
                                                            )}
                                                            fullWidth
                                                            helperText={
                                                                touched.nom &&
                                                                errors.nom
                                                            }
                                                            label="Nom"
                                                            margin="normal"
                                                            variant="outlined"
                                                            name="nom"
                                                            onBlur={handleBlur}
                                                            onChange={
                                                                handleChange
                                                            }
                                                            type="text"
                                                            value={values.nom}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={3}>
                                                    <Grid item md={6}>
                                                        <Typography variant="body1">
                                                            Color
                                                        </Typography>
                                                        <Box mt={1} />
                                                        <ColorPicker
                                                            onChange={(
                                                                value
                                                            ) => {
                                                                setFieldValue(
                                                                    'color',
                                                                    value
                                                                );
                                                            }}
                                                            value={values.color}
                                                            label="Color"
                                                            name="color"
                                                        />
                                                    </Grid>
                                                    <Grid item md={6}>
                                                        <Typography variant="body1">
                                                            Color alternatiu
                                                        </Typography>
                                                        <Box mt={1} />
                                                        <ColorPicker
                                                            onChange={(
                                                                value
                                                            ) => {
                                                                setFieldValue(
                                                                    'color_alt',
                                                                    value
                                                                );
                                                            }}
                                                            value={
                                                                values.color_alt
                                                            }
                                                            label="Color alternatiu"
                                                            name="color_alt"
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid container spacing={3}>
                                                    <Grid item md={12}>
                                                        <Box mt={2}>
                                                            <Typography>
                                                                Imatge
                                                            </Typography>
                                                            <input
                                                                id="imatge"
                                                                name="imatge[]"
                                                                type="file"
                                                                onChange={(
                                                                    event
                                                                ) => {
                                                                    setFieldValue(
                                                                        'imatge',
                                                                        event
                                                                            .currentTarget
                                                                            .files[0]
                                                                    );
                                                                }}
                                                                className="form-control"
                                                            />
                                                        </Box>
                                                        <Box p={2}>
                                                            <Thumb
                                                                file={
                                                                    values.imatge
                                                                }
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>

                                                <Box my={2}>
                                                    <Button
                                                        color="primary"
                                                        fullWidth
                                                        size="large"
                                                        type="submit"
                                                        variant="contained"
                                                    >
                                                        {'Editar'}
                                                    </Button>
                                                </Box>
                                            </form>
                                        )}
                                    </Formik>
                                </Fade>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
};

export default EscolesEdit;
