import React, { useEffect, useState } from "react";
import {
    Box,
    Container,
    Grid,
    makeStyles,
    TextField,
    Button,
    Select,
    InputLabel,
    FormControl,
    FormControlLabel,
    Checkbox,
} from "@material-ui/core";
import Page from "../../../components/Page";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { createActivitat, createPack, getEscoles } from "../../../database/API";
import { useSnackbar } from "notistack";
import Title from "../../../components/Title";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "white",
        minHeight: "100%",
    },
    container: {
        paddingTop: 47,
    },
    // "@global": {
    //     html: {
    //         width: "100%",
    //         height: "100%",
    //     },
    // },
    formControl: {
        marginTop: 16,
        marginBottom: 15,
    },
}));

const PackAdd = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [escoles, setEscoles] = useState();

    const crear = async (values) => {
        const message = await createPack(values);
        enqueueSnackbar(message, {
            variant: "success",
        });
        navigate(-1, { state: values });
    };

    useEffect(() => {
        const get = async () => {
            const { escoles } = await getEscoles();
            setEscoles(escoles);
        };
        get();
    }, []);

    return (
        <Page className={classes.root} title={"Crear pack"}>
            <Container maxWidth={false} className={classes.container}>
                <Formik
                    initialValues={{
                        nom: "",
                        preu: 0,
                        escola_id: "",
                    }}
                    validationSchema={Yup.object().shape({
                        nom: Yup.string()
                            .required("El nom és obligatòri")
                            .max(25, "Has superat el límit"),
                        preu: Yup.number().required("El preu és obligatòri"),
                    })}
                    onSubmit={(values) => {
                        crear(values);
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        touched,
                        values,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={3}>
                                <Grid item md={8}>
                                    <Box mb={3}>
                                        <Title
                                            title={"Crear pack d'activitats"}
                                            subtitle={
                                                "Entra les dades per guardar un nou pack d'activitats"
                                            }
                                        />
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={3}>
                                <Grid item md={4}>
                                    <TextField
                                        error={Boolean(
                                            touched.nom && errors.nom
                                        )}
                                        fullWidth
                                        helperText={touched.nom && errors.nom}
                                        label="Nom"
                                        margin="normal"
                                        variant="outlined"
                                        name="nom"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text"
                                        value={values.nom}
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <TextField
                                        error={Boolean(
                                            touched.preu && errors.preu
                                        )}
                                        fullWidth
                                        helperText={touched.preu && errors.preu}
                                        label="Preu"
                                        margin="normal"
                                        variant="outlined"
                                        name="preu"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="double"
                                        value={values.preu}
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <FormControl
                                        className={classes.formControl}
                                        variant="outlined"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    >
                                        <InputLabel htmlFor="escola_id">
                                            Escola
                                        </InputLabel>
                                        <Select
                                            native
                                            value={values.escola_id}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            label="Escola"
                                            name="escola_id"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{
                                                name: "escola_id",
                                                id: "escola_id",
                                            }}
                                        >
                                            <option value={null}></option>
                                            {escoles?.map((escola) => {
                                                return (
                                                    <option
                                                        value={escola.id}
                                                        key={escola.id}
                                                    >
                                                        {escola.nom}
                                                    </option>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Box my={4}>
                                <Button
                                    color="primary"
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                >
                                    {"Crear"}
                                </Button>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Container>
        </Page>
    );
};

export default PackAdd;
