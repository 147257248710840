import { IconButton } from "@material-ui/core";
import { PanTool } from "@material-ui/icons";
import moment from "moment";
import { Eye } from "react-feather";
import { useNavigate } from "react-router";

const ActivitatAlumnesColumns = (alumnes) => {
	const navigate = useNavigate();
	const columns = [
		{
			name: "id",
			label: "ID",
			options: {
				filter: false,
				sort: true,
				display: false,
			},
		},

		{
			name: "nom",
			label: "Nom",
			options: {
				filter: false,
				sort: true,
			},
		},
		{
			name: "curs",
			label: "Curs",
			options: {
				filter: false,
				sort: true,
			},
		},
		{
			name: "n_compte",
			label: "Número de compte",
			options: {
				filter: false,
				sort: true,
			},
		},
		{
			name: "telefon",
			label: "Telèfon",
			options: {
				filter: false,
				sort: true,
			},
		},

		{
			name: "espera",
			label: "Llista d'espera",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value) => (value ? <PanTool color="red" style={{ color: "red", fill: "red" }} /> : "-"),
			},
		},
		{
			name: "created_at",
			label: "Data d'inscripció",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) => moment(new Date(value)).format("DD/MM/YYYY H:mm:ss"),
			},
		},
		{
			name: "id",
			label: "Accions",
			options: {
				filter: false,
				sort: false,
				empty: true,
				customBodyRenderLite: (dataIndex) => {
					return (
						<IconButton
							onClick={() =>
								navigate(`/admin/alumnes/${alumnes[dataIndex].id}`, {
									state: alumnes[dataIndex],
								})
							}
						>
							<Eye />
						</IconButton>
					);
				},
			},
		},
	];
	return columns;
};

export default ActivitatAlumnesColumns;
