import React, { useEffect, useState } from "react";
import {
    Box,
    Container,
    Grid,
    makeStyles,
    Button,
    CircularProgress,
    Typography,
    FormControlLabel,
    Checkbox,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    ListItemText,
    OutlinedInput,
} from "@material-ui/core";
import Page from "../../../components/Page";
import { useNavigate, useParams } from "react-router-dom";
import {
    getActivitats,
    getAlumne,
    getEscoles,
    updateAlumne,
} from "../../../database/API";
import { useSnackbar } from "notistack";
import Title from "../../../components/Title";
import moment from "moment/min/moment-with-locales";
import { Formik } from "formik";
import Input from "../../../components/CustomInput";
import * as Yup from "yup";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "white",
        minHeight: "100%",
    },
    container: {
        paddingTop: 47,
        paddingBottom: 47,
    },
    formControl: {
        marginTop: 16,
        marginBottom: 15,
    },
    activitats: {
        border: "1px solid",
        padding: 16,
        borderColor: theme.palette.primary.main,
    },
}));

const UserEdit = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [alumne, setAlumne] = useState();
    const [escoles, setEscoles] = useState();
    const [escola, setEscola] = useState(null);
    const [activitats, setActivitats] = useState();
    const [loading, setLoading] = useState(true);
    const [loadingAct, setLoadingAct] = useState(true);
    const { key } = useParams();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const get = async () => {
            const { alumne } = await getAlumne(key);
            const { escoles } = await getEscoles();
            setEscoles(escoles);
            setAlumne(alumne);
            setEscola(alumne?.activitats[0].escola_id);
            setLoading(false);
        };
        get();
    }, [key]);

    useEffect(() => {
        setLoadingAct(true);
        const get = async () => {
            const { activitats } = await getActivitats(escola);
            setActivitats(activitats);
            setLoadingAct(false);
        };
        if (escola !== null) get();
    }, [escola]);

    const editar = async (values) => {
        const { message } = await updateAlumne(values, key);
        enqueueSnackbar(message, {
            variant: "success",
        });
        navigate("/admin/alumnes/" + key);
        console.log(values);
    };

    return (
        <Page className={classes.root} title={"Editar alumne"}>
            <Container maxWidth={false} className={classes.container}>
                <Box mb={3}>
                    <Title title={"Editar alumne"} />
                </Box>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <Formik
                        enableReinitialize
                        initialValues={{
                            nom: alumne?.nom,
                            email: alumne?.email,
                            telefon: alumne?.telefon,
                            telefon2: alumne?.telefon2,
                            escola: alumne?.escola,
                            curs: alumne?.curs,
                            edat: alumne?.edat,
                            nom_titular: alumne?.nom_titular,
                            dni: alumne?.dni,
                            n_compte: alumne?.n_compte,
                            ampa: alumne?.ampa === "1" ? 1 : 0,
                            dret: alumne?.dret === "1" ? 1 : 0,
                            observacions: alumne?.observacions,
                            activitat: alumne?.activitats.map((item) => {
                                return item.id;
                            }),
                            escola_id: alumne?.activitats[0].escola_id,
                        }}
                        validationSchema={Yup.object().shape({
                            nom: Yup.string().required("El nom és obligatòri"),
                            email: Yup.string()
                                .email("Ha de ser un E-mail vàlid")
                                .required("L'email és obligatòri"),
                            telefon: Yup.string().required(
                                "El telèfon és obligatòri"
                            ),
                            telefon_2: Yup.string(),
                            escola: Yup.string().required(
                                "L'escola és obligatòria"
                            ),
                            curs: Yup.string().required(
                                "El curs és obligatòri"
                            ),
                            edat: Yup.number()
                                .min(2)
                                .max(20)
                                .required("L'edat és obligatòria"),
                            nom_titular: Yup.string().required(
                                "El nom del titular és obligatòri"
                            ),
                            dni: Yup.string().required(
                                "El DNI del titular és obligatòri"
                            ),
                            n_compte: Yup.string().required(
                                "El número de compte del titular és obligatòri"
                            ),
                            accepta: Yup.bool().oneOf(
                                [true],
                                "Has d'acceptar la Política de Privacitat per continuar"
                            ),
                        })}
                        onSubmit={(values) => {
                            editar(values);
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue,
                            touched,
                            values,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={3}>
                                    <Grid item md={12}>
                                        <Box className={classes.activitats}>
                                            <Box mb={3}>
                                                <Typography>
                                                    Activitats
                                                </Typography>
                                            </Box>
                                            <FormControl
                                                className={classes.formControl}
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            >
                                                <InputLabel htmlFor="escola_id">
                                                    Escola
                                                </InputLabel>
                                                <Select
                                                    native
                                                    value={values.escola_id}
                                                    onChange={(value) => {
                                                        console.log(
                                                            value.target.value
                                                        );
                                                        setFieldValue(
                                                            "escola_id",
                                                            value.target.value
                                                        );
                                                        setEscola(
                                                            value.target.value
                                                        );
                                                        setFieldValue(
                                                            "activitat",
                                                            []
                                                        );
                                                    }}
                                                    onBlur={handleBlur}
                                                    label="Escola"
                                                    name="escola_id"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        name: "escola_id",
                                                        id: "escola_id",
                                                    }}
                                                    style={{ borderRadius: 0 }}
                                                >
                                                    {escoles?.map((item) => (
                                                        <option value={item.id}>
                                                            {item.nom}
                                                        </option>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            {loadingAct ? (
                                                <CircularProgress />
                                            ) : (
                                                activitats?.map((item) => (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={values.activitat?.includes(
                                                                    item.id
                                                                )}
                                                                onChange={() => {
                                                                    if (
                                                                        values.activitat?.includes(
                                                                            item.id
                                                                        )
                                                                    ) {
                                                                        let newValues =
                                                                            values.activitat.filter(
                                                                                function (
                                                                                    element
                                                                                ) {
                                                                                    return (
                                                                                        element !==
                                                                                        item.id
                                                                                    );
                                                                                }
                                                                            );
                                                                        setFieldValue(
                                                                            "activitat",
                                                                            newValues
                                                                        );
                                                                    } else {
                                                                        setFieldValue(
                                                                            "activitat",
                                                                            [
                                                                                ...values.activitat,
                                                                                item.id,
                                                                            ]
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        label={item.nom}
                                                    />
                                                ))
                                            )}

                                            {/* <FormControl
                                            variant="outlined"
                                            fullWidth
                                        >
                                            <InputLabel>Activitats</InputLabel>
                                            <Select
                                                multiple
                                                value={values.activitat}
                                                onChange={(event) => {
                                                    const {
                                                        target: { value },
                                                    } = event;

                                                    setFieldValue(
                                                        "activitat",
                                                        typeof value ===
                                                            "string"
                                                            ? value.split(",")
                                                            : value
                                                    );
                                                    console.log(value);
                                                }}
                                                onBlur={handleBlur}
                                                label="Activitats"
                                                name="activitat"
                                                inputProps={{
                                                    name: "activitat",
                                                    id: "activitat",
                                                }}
                                            >
                                                {activitats?.map((item) => (
                                                    <MenuItem
                                                        key={item.nom}
                                                        value={item.id}
                                                    >
                                                        {item.nom}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl> */}
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Box
                                    className={
                                        values.activitat.length === 0 &&
                                        classes.noOpacity
                                    }
                                >
                                    <Box my={3}>
                                        <Typography>
                                            Dades de l'alumne
                                        </Typography>
                                    </Box>
                                    <Grid container spacing={3}>
                                        <Grid item md={6}>
                                            <Input
                                                error={Boolean(
                                                    touched.nom && errors.nom
                                                )}
                                                fullWidth
                                                helperText={
                                                    touched.nom && errors.nom
                                                }
                                                label="Nom i cognoms"
                                                margin="normal"
                                                variant="outlined"
                                                name="nom"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="text"
                                                value={values.nom}
                                            />
                                        </Grid>
                                        <Grid item md={6}>
                                            <Input
                                                error={Boolean(
                                                    touched.email &&
                                                        errors.email
                                                )}
                                                fullWidth
                                                helperText={
                                                    touched.email &&
                                                    errors.email
                                                }
                                                disabled
                                                label="E-mail"
                                                margin="normal"
                                                variant="outlined"
                                                name="email"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="text"
                                                value={values.email}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item md={6}>
                                            <Input
                                                error={Boolean(
                                                    touched.telefon &&
                                                        errors.telefon
                                                )}
                                                fullWidth
                                                helperText={
                                                    touched.telefon &&
                                                    errors.telefon
                                                }
                                                label="Telèfon"
                                                margin="normal"
                                                variant="outlined"
                                                name="telefon"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="text"
                                                value={values.telefon}
                                            />
                                        </Grid>
                                        <Grid item md={6}>
                                            <Input
                                                error={Boolean(
                                                    touched.telefon2 &&
                                                        errors.telefon2
                                                )}
                                                fullWidth
                                                helperText={
                                                    touched.telefon2 &&
                                                    errors.telefon2
                                                }
                                                label="Telèfon 2 (opcional)"
                                                margin="normal"
                                                variant="outlined"
                                                name="telefon2"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="text"
                                                value={values.telefon2}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item md={4}>
                                            <Input
                                                error={Boolean(
                                                    touched.escola &&
                                                        errors.escola
                                                )}
                                                fullWidth
                                                helperText={
                                                    touched.escola &&
                                                    errors.escola
                                                }
                                                label="Escola on cursa"
                                                margin="normal"
                                                variant="outlined"
                                                name="escola"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="text"
                                                value={values.escola}
                                            />
                                        </Grid>
                                        <Grid item md={4}>
                                            <Input
                                                error={Boolean(
                                                    touched.curs && errors.curs
                                                )}
                                                fullWidth
                                                helperText={
                                                    touched.curs && errors.curs
                                                }
                                                label="Curs"
                                                margin="normal"
                                                variant="outlined"
                                                name="curs"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="text"
                                                value={values.curs}
                                            />
                                        </Grid>
                                        <Grid item md={4}>
                                            <Input
                                                error={Boolean(
                                                    touched.edat && errors.edat
                                                )}
                                                fullWidth
                                                helperText={
                                                    touched.edat && errors.edat
                                                }
                                                label="Edat"
                                                margin="normal"
                                                variant="outlined"
                                                name="edat"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="number"
                                                value={values.edat}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item md={12}>
                                            <Input
                                                error={Boolean(
                                                    touched.observacions &&
                                                        errors.observacions
                                                )}
                                                fullWidth
                                                helperText={
                                                    touched.observacions &&
                                                    errors.observacions
                                                }
                                                label="Observacions"
                                                multiline
                                                rows={2}
                                                margin="normal"
                                                variant="outlined"
                                                name="observacions"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="number"
                                                value={values.observacions}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item md={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={values.ampa}
                                                        onChange={handleChange}
                                                        name="ampa"
                                                    />
                                                }
                                                label="Soci AMPA"
                                            />
                                        </Grid>
                                    </Grid>

                                    <Box my={3}>
                                        <Typography>
                                            Dades del titular
                                        </Typography>
                                    </Box>
                                    <Grid container spacing={3}>
                                        <Grid item md={6}>
                                            <Input
                                                error={Boolean(
                                                    touched.nom_titular &&
                                                        errors.nom_titular
                                                )}
                                                fullWidth
                                                helperText={
                                                    touched.nom_titular &&
                                                    errors.nom_titular
                                                }
                                                label="Nom i cognoms del titular"
                                                margin="normal"
                                                variant="outlined"
                                                name="nom_titular"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="text"
                                                value={values.nom_titular}
                                            />
                                        </Grid>
                                        <Grid item md={6}>
                                            <Input
                                                error={Boolean(
                                                    touched.dni && errors.dni
                                                )}
                                                fullWidth
                                                helperText={
                                                    touched.dni && errors.dni
                                                }
                                                label="DNI del titular"
                                                margin="normal"
                                                variant="outlined"
                                                name="dni"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="text"
                                                value={values.dni}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item md={12}>
                                            <Input
                                                error={Boolean(
                                                    touched.n_compte &&
                                                        errors.n_compte
                                                )}
                                                fullWidth
                                                helperText={
                                                    touched.n_compte &&
                                                    errors.n_compte
                                                }
                                                label="Número de compte"
                                                margin="normal"
                                                variant="outlined"
                                                name="n_compte"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="text"
                                                value={values.n_compte}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3}>
                                        <Grid item md={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={values.dret}
                                                        onChange={handleChange}
                                                        name="dret"
                                                    />
                                                }
                                                label="Autorització drets d'imatge"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Box my={2}>
                                        <Button
                                            color="primary"
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                        >
                                            Editar
                                        </Button>
                                    </Box>
                                </Box>
                            </form>
                        )}
                    </Formik>
                )}
            </Container>
        </Page>
    );
};

export default UserEdit;
