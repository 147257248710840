import { Box, useMediaQuery } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import React from "react";
import Page from "../../components/Page";
import Portada from "./Portada";

const useStyles = makeStyles((theme) => ({
    "@global": {
        body: {
            overflow: "hidden",
        },
        ".makeStyles-wrapper-2": {
            padding: 0,
        },
    },
    destacat: {
        color: theme.palette.third.main,
    },
    boxQuisom: {
        backgroundColor: "white",
        borderRadius: 50,
        padding: 100,
        width: "60%",
    },
    boxQuisomM: {
        backgroundColor: "white",
        borderRadius: 0,
        padding: 30,
        paddingTop: 100,
        paddingBottom: 100,
    },
    boxEscoles: {
        width: "55%",
        justifyContent: "center",
    },
}));

const QuiSom = () => {
    const classes = useStyles();
    const matches = useMediaQuery("(min-width:960px)");

    return (
        <Box className={matches ? classes.boxQuisom : classes.boxQuisomM}>
            <Typography variant="h2" className={classes.destacat}>
                QUI SOM?
            </Typography>
            <Box mt={3}>
                <Typography variant="body1">
                    Som un projecte educatiu que veu la llum l’estiu del 2013
                    per donar un impuls a la necessitat de PROFESSIONALITZAR les
                    activitats extraescolars de Berga i la comarca del Berguedà.
                </Typography>
                <Box my={3} />
                <Typography variant="body1">
                    SINAPSIS neix de la inquietud de professionals del lleure,
                    l’educació i l’esport per tal d’oferir un servei ampli, de
                    qualitat i proper a les famílies del nostre entorn.
                </Typography>
                <Box my={3} />
                <Typography variant="body1">
                    L’experiència ens permet ser referents a la ciutat,
                    treballant en totes les escoles de Berga. Col·laborem amb
                    les AMPA’s per tal de realitzar activitats que s’adequin a
                    la demanda de les escoles, prioritzant l’alumnat amb
                    personal qualificat i les bases legals cobertes.
                </Typography>
                <Box my={3} />
                <Typography variant="body1">
                    El respecte, l’empatia i l’aprenentatge dels infants son les
                    bases de les activitats de SINAPSIS. Treballem amb alegria,
                    il·lusió i vetllem per tenir una bona comunicació amb les
                    famílies.
                </Typography>
                <Box my={3} />
                <Typography variant="body1">
                    Ens agrada col·laborar amb altres entitats, per això tenim
                    convenis amb altres empreses i clubs per tal de sumar i
                    enriquir la nostra tasca.
                </Typography>
            </Box>
        </Box>
    );
};

export default QuiSom;
