import React, { useEffect, useRef, useState } from "react";
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import { ReactComponent as Logo } from "../../theme/icons/logo-nou.svg";
import { ReactComponent as Cloud } from "../../theme/icons/cloud.svg";
import { ReactComponent as StaEulalia } from "../../theme/icons/staeulalia.svg";
import { ReactComponent as Valldan } from "../../theme/icons/valldan.svg";
import { ReactComponent as StJoan } from "../../theme/icons/stjoan.svg";
import { Container, makeStyles, SvgIcon } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { getEscoles } from "../../database/API";
import EscolaSingle from "../../components/EscolaSingle";
import { Grid } from "@material-ui/core";
import Footer from "../../components/Footer";
import { useNavigate } from "react-router";
import QueFem from "./QueFem";
import QuiSom from "./QuiSom";
import InfoImportant from "../../components/InfoImportant";
import Colaboradors from "../../components/Colaboradors";

const useStyles = makeStyles((theme) => ({
	"@global": {
		body: {
			overflowX: "hidden",
			overflowY: "auto",
		},
		".makeStyles-wrapper-2": {
			padding: 0,
		},
	},
	icon: {
		fill: "#b2d6cf",
		height: 500,
		width: 500,
	},
	cloud: {
		fill: "#b2d6cf",
		height: 500,
		width: 500,
	},
	titolEscoles: {
		backgroundColor: theme.palette.background.default,
		borderRadius: 50,
		padding: 10,
		textAlign: "center",
		marginBottom: 50,
	},
	logo: {
		alignItems: "center",
		display: "flex",
		justifyContent: "center",
		height: "100%",
	},
	footer: {
		marginTop: 150,
		backgroundColor: theme.palette.background.default,
	},
}));

export default function PortadaMobil() {
	const classes = useStyles();
	const parallax = useRef(null);
	const navigate = useNavigate();
	const [scrollY, setScrollY] = useState(window.scrollY);

	const [escoles, setEscoles] = useState();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	});

	const handleScroll = () => {
		setScrollY(window.scrollY);
	};

	useEffect(() => {
		const get = async () => {
			const { escoles } = await getEscoles();
			setEscoles(escoles);
			setLoading(false);
		};
		get();
	}, []);

	return (
		<div
			style={{
				width: "100%",
				height: "100%",
				position: "absolute",
				backgroundColor: "#9FE1D9",
				marginBottom: 100,
				zIndex: 10,
				overflowX: "hidden",
			}}
		>
			<Box className={classes.logo} my={0}>
				<Box display="flex" flexDirection="column" alignItems="center">
					<Logo style={{ width: 300, height: 250, top: 0 - scrollY / 10 }} fill="white" />
					{/* <SvgIcon
						component={Logo}
						viewBox="0 0 22 26"
						style={{
							width: 250,
							fill: "white",
							top: 0 - scrollY / 10,
						}}
						className={classes.icon}
					/> */}
					<InfoImportant mobile={true} />
				</Box>

				<SvgIcon
					component={Cloud}
					viewBox="0 0 417 256"
					style={{
						position: "absolute",
						opacity: 0.7,
						display: "block",
						width: "20%",
						marginLeft: "-33%",
						fill: "white",
						height: "20%",
						top: 20 + scrollY / 5,
					}}
				/>
				<SvgIcon
					component={Cloud}
					viewBox="0 0 417 256"
					style={{
						position: "absolute",
						opacity: 0.7,
						display: "block",
						width: "12%",
						marginLeft: "35%",
						fill: "white",
						height: "49%",
						top: 0 + scrollY / 5,
					}}
				/>
				<SvgIcon
					component={Cloud}
					viewBox="0 0 417 256"
					style={{
						position: "absolute",
						opacity: 0.7,
						display: "block",
						width: "17%",
						marginLeft: "-35%",
						fill: "white",
						height: "37%",
						top: 200 + scrollY / 5,
					}}
				/>
				<SvgIcon
					component={Cloud}
					viewBox="0 0 417 256"
					style={{
						position: "absolute",
						opacity: 0.7,
						display: "block",
						width: "12%",
						marginLeft: "-70%",
						fill: "white",
						height: "20%",
						top: 600 + scrollY / 5,
					}}
				/>
				<SvgIcon
					component={Cloud}
					viewBox="0 0 417 256"
					style={{
						position: "absolute",
						opacity: 0.7,
						display: "block",
						width: "17%",
						marginLeft: "45%",
						fill: "white",
						height: "50%",
						top: 100 + scrollY / 5,
					}}
				/>
				<SvgIcon
					component={Cloud}
					viewBox="0 0 417 256"
					style={{
						position: "absolute",
						opacity: 0.7,
						display: "block",
						width: "17%",
						marginLeft: "22%",
						fill: "white",
						height: "30%",
						top: 400 + scrollY / 5,
					}}
				/>
			</Box>
			<QuiSom />
			<Box>
				<QueFem />
			</Box>

			<Box className={classes.boxEscoles}>
				<Container>
					<Box className={classes.titolEscoles}>
						<Typography variant="h2" style={{ color: "white" }}>
							ESCOLES
						</Typography>
					</Box>
					<Grid container spacing={5} style={{ justifyContent: "center" }}>
						{escoles?.map((escola) => {
							return (
								escola.id !== 0 && (
									<EscolaSingle
										escola={escola}
										onClick={() =>
											navigate("inscripcions", {
												state: escola,
											})
										}
									/>
								)
							);
						})}
					</Grid>
				</Container>
				<Box my={20}>
					<Colaboradors />
				</Box>
				<Box className={classes.footer}>
					<Footer />
				</Box>
			</Box>
		</div>
	);
}
