import React, { useEffect, useState } from "react";
import {
	Box,
	Container,
	Grid,
	makeStyles,
	Button,
	CircularProgress,
	Typography,
	TableContainer,
	Table,
	TableRow,
	TableCell,
	TableBody,
	Checkbox,
} from "@material-ui/core";
import Page from "../../../components/Page";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getAlumne } from "../../../database/API";
import { useSnackbar } from "notistack";
import Title from "../../../components/Title";
import moment from "moment/min/moment-with-locales";
import { CheckCircle, XCircle } from "react-feather";
import Activitat from "../../../components/Activitat";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: "white",
		minHeight: "100%",
	},
	container: {
		paddingTop: 47,
	},
	paper: {
		backgroundColor: "white",
		borderRadius: 5,
		padding: 30,
		boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
	},
	formControl: {
		marginTop: 16,
		marginBottom: 15,
	},
	activitatWrap: {
		backgroundColor: "white",
		padding: 30,
		border: "6px solid white",
		cursor: "pointer",
		borderRadius: 10,
		transition: ".2s",
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
	},
	avatar: {
		width: 120,
		height: 120,
		border: "8px solid white",
		cursor: "pointer",
		"& img": {
			objectPosition: "top",
			width: "90%",
		},
		marginBottom: 15,
	},
}));

const UserView = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const [alumne, setAlumne] = useState();
	const [loading, setLoading] = useState(true);
	const { key } = useParams();

	useEffect(() => {
		const get = async () => {
			const { alumne } = await getAlumne(key);
			setAlumne(alumne);
			setLoading(false);
		};
		get();
	}, [key]);

	const info = [
		{ id: "Nom", value: alumne?.nom },
		{ id: "E-mail", value: alumne?.email },
		{ id: "Telèfon", value: alumne?.telefon },
		{ id: "Telèfon 2", value: alumne?.telefon2 },
		{ id: "Escola", value: alumne?.escola },
		{ id: "Curs", value: alumne?.curs },
		{ id: "Talla samarreta", value: alumne?.talla },
		{ id: "Edat", value: alumne?.edat + " anys" },
		{
			id: "Ampa",

			value: <Checkbox disabled checked={alumne?.ampa === "1" ? true : false} />,
		},
		{
			id: "Dret d'imatge",
			value: <Checkbox disabled checked={alumne?.dret === "1" ? true : false} />,
		},
		{ id: "Observacions", value: alumne?.observacions },
	];

	const info_titular = [
		{ id: "Nom", value: alumne?.nom_titular },
		{ id: "DNI", value: alumne?.dni },
		{ id: "Número de compte", value: alumne?.n_compte },
	];

	return (
		<Page className={classes.root} title={"Detalls alumne"}>
			<Container maxWidth={false} className={classes.container}>
				<Box mb={3}>
					<Title
						title={"Detalls alumne"}
						button={
							<Button variant="contained" className={classes.addButton} onClick={() => navigate("edit")}>
								Editar
							</Button>
						}
					/>
				</Box>
				{loading ? (
					<CircularProgress />
				) : (
					<Grid container spacing={4}>
						<Grid item md={6}>
							<Box className={classes.paper}>
								<Box mb={3}>
									<Typography variant="h3">Informació</Typography>
								</Box>
								<TableContainer>
									<Table sx={{ minWidth: 650 }} aria-label="simple table">
										<TableBody>
											{info?.map((row) => (
												<TableRow
													hover
													key={row.id}
													sx={{
														"&:last-child td, &:last-child th": { border: 0 },
													}}
												>
													<TableCell component="th" scope="row">
														{row.id}
													</TableCell>
													<TableCell align="right">{row.value}</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							</Box>
							<Box className={classes.paper} my={4}>
								<Box mb={3}>
									<Typography variant="h3">Informació econòmica</Typography>
								</Box>

								<TableContainer>
									<Table sx={{ minWidth: 650 }} aria-label="simple table">
										<TableBody>
											{info_titular?.map((row) => (
												<TableRow
													hover
													key={row.id}
													sx={{
														"&:last-child td, &:last-child th": { border: 0 },
													}}
												>
													<TableCell component="th" scope="row">
														{row.id}
													</TableCell>
													<TableCell align="right">{row.value}</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							</Box>
						</Grid>
						<Grid item md={6}>
							<Box className={classes.paper}>
								<Box mb={3}>
									<Typography variant="h3">Activitats apuntades</Typography>
								</Box>
								{alumne?.activitats.map((activitat) => (
									<Link to={`/admin/activitats/` + activitat.id} style={{ textDecoration: "none" }}>
										<Activitat activitat={activitat} />
									</Link>
								))}
							</Box>
							<Box className={classes.paper} mt={3} justifyContent="space-between" display="flex">
								<Typography variant="h3">Total mensual</Typography>
								<Typography variant="h4">
									{alumne?.activitats.map((activitat) => activitat.preu).reduce((acc, curr) => acc + parseInt(curr, 10), 0)} €
								</Typography>
							</Box>
						</Grid>
					</Grid>
				)}
			</Container>
		</Page>
	);
};

export default UserView;
