import { Box, Button, Grid, Hidden, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import React from "react";
import { useNavigate } from "react-router";

const useStyles = makeStyles((theme) => ({
	overlay: {
		backgroundColor: "rgba(0, 0, 0, 0.4)",
		position: "absolute",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
		zIndex: 10000,
	},
	faldo: {
		bottom: 0,
		backgroundColor: theme.palette.background.dark,
		width: "100%",
		position: "absolute",
		display: "flex",
		justifyContent: "space-between",
	},
}));

const PoliticaDeCookies = ({ setTancar }) => {
	const classes = useStyles();
	const navigate = useNavigate();

	const tancar = () => {
		localStorage.setItem("tancar", true);
		setTancar(true);
	};

	return (
		<Box className={classes.overlay}>
			<Box className={classes.faldo}>
				<Grid spacing={3} container>
					<Grid item md={8}>
						<Box p={3}>
							<Typography>
								Aquest lloc web únicament utilitza cookies pròpies amb finalitats tècniques. No recapta ni cedeix dades personals dels usuaris
								sense el seu coneixement.
							</Typography>
							<Typography>
								No obstant això, pot contenir enllaços a llocs web de tercers amb polítiques de privacitat alienes a aquesta pàgina que vostè
								podrà decidir si accepta o no quan hi accedeixi.
							</Typography>
						</Box>
					</Grid>
					<Grid item md={4} style={{ textAlign: "right" }}>
						<Box p={3}>
							<Button variant="outlined" onClick={tancar}>
								Tancar
							</Button>
							<Box m={2} />
							<Button variant="outlined" onClick={() => navigate("politica-cookies")}>
								Més informació
							</Button>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};

export default PoliticaDeCookies;
