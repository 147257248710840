import { Container } from "@material-ui/core";
import { Link } from "@material-ui/core";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getTweets } from "../database/API";

const useStyles = makeStyles((theme) => ({
	container: {
		height: 200,
		marginTop: 200,
	},
	title: {
		color: theme.palette.admin.secondary,
	},
	delete: {
		backgroundColor: theme.palette.text.danger,
		color: "white",
	},
	twitterWrap: {
		backgroundColor: "white",
		borderRadius: 50,
		padding: 40,
		display: "flex",
		flexDirection: "column",
		height: 500,
	},
	list: {
		overflowX: "auto",
		"&::-webkit-scrollbar": {
			width: 4,
		},

		"&::-webkit-scrollbar-thumb": {
			background: "#ccc",
			borderRadius: 4,
		},
		"&::-webkit-scrollbar-thumb:hover": {
			background: "#b3b3b3",
			boxShadow: "0 0 2px 1px rgba(0, 0, 0, 0.2)",
		},
	},
	tweet: {
		display: "flex",
		padding: 10,
		marginRight: 10,
		borderBottom: "1px solid #cacaca",
		flexDirection: "column",
		"&:hover": {
			textDecoration: "none",
			backgroundColor: "#fafafa",
		},
	},
	image: {},
	tweetText: {
		fontSize: 13,
	},
}));

const Twitter = (props) => {
	const classes = useStyles();
	const [tweets, setTweets] = useState([]);

	useEffect(() => {
		const get = async () => {
			const { tweets } = await getTweets();
			setTweets(tweets);
		};
		get();
	}, []);

	return (
		<Container className={classes.container}>
			<Grid container spacing={5}>
				<Grid item md={6}></Grid>

				<Grid item md={12}>
					<Box mb={3}>
						<Typography variant="h2" style={{ color: "white" }}>
							Twitter
						</Typography>
					</Box>
					<Box className={classes.twitterWrap}>
						<Box className={classes.list}>
							{tweets?.map((item) => {
								return (
									<Link className={classes.tweet} href="https://twitter.com/SinapsisBerga" target="_blanc">
										<Typography className={classes.tweetText}>
											{item.full_text.split("\n").map((str) => (
												<p>{str}</p>
											))}
										</Typography>
										{item.entities?.media && <img className={classes.image} alt="Imatge Tweet" src={item.entities.media[0]?.media_url} />}
									</Link>
								);
							})}
						</Box>
					</Box>
				</Grid>
			</Grid>
		</Container>
	);
};

export default Twitter;
