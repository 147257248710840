import { useSnackbar } from "notistack";
import { deleteActivitat } from "../../../../database/API";
import { write, utils } from "xlsx";
import { saveAs } from "file-saver";

const ActivitatsOptions = (activitats, filterFunc) => {
	const { enqueueSnackbar } = useSnackbar();
	const options = {
		enableNestedDataAccess: ".",
		filterType: "checkbox",
		onFilterChange: filterFunc ? filterFunc : () => null,
		searchOpen: true,
		searchAlwaysOpen: true,
		searchText: localStorage.getItem("activitatSearch") === "null" ? "" : localStorage.getItem("activitatSearch"),
		onSearchChange: (s) => localStorage.setItem("activitatSearch", s),

		onRowsDelete: (rowsDeleted) => {
			rowsDeleted.data.forEach(async (item) => {
				let message = await deleteActivitat(activitats[item.dataIndex].id);
				enqueueSnackbar(message, {
					variant: "success",
				});
			});
		},
		textLabels: {
			body: {
				noMatch: "No hi han activitats",
				toolTip: "Ordenar",
				columnHeaderTooltip: (column) => `${"Ordenat per"} ${column.label}`,
			},
			pagination: {
				next: "Següent pàgina",
				previous: "Pàgina anterior",
				rowsPerPage: "Files per pàgina:",
				displayRows: "de", // 1-10 of 30
			},
			toolbar: {
				search: "Cercar",
				downloadCsv: "Descarregar CSV",
				print: "Imprimir",
				viewColumns: "Veure columnes",
				filterTable: "Filtrar taula",
			},
			filter: {
				title: "FILTRES",
				reset: "resetejar",
				all: "Tots",
			},
			viewColumns: {
				title: "Mostrar columnes",
			},
			selectedRows: {
				text: "Fila/es eliminades",
				delete: "Eliminar",
			},
		},
		downloadOptions: {
			filterOptions: {
				useDisplayedColumnsOnly: false,
				useDisplayedRowsOnly: true,
			},
		},
		onDownload: (buildHead, buildBody, columns, values) => {
			const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
			const fileExtension = ".xlsx";
			console.log(columns);

			const json = values.reduce((result, val) => {
				const temp = {};
				val.data.map((v, idx) => {
					if (idx !== 0 && idx !== 1 && idx !== 10) {
						if (Array.isArray(v)) {
							let act = v.map((activitat) => activitat.nom).join(", ");
							temp[columns[idx].label] = act;
						} else {
							temp[columns[idx].label] = v;
						}
					}
				});
				result.push(temp);
				return result;
			}, []);

			const fileName = `Activitats`;
			const ws = utils.json_to_sheet(json);
			const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
			const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
			const data = new Blob([excelBuffer], { type: fileType });
			saveAs(data, fileName + fileExtension);

			return false;
		},
	};

	return options;
};

export default ActivitatsOptions;
