import React, { useEffect, useState } from "react";
import {
    Box,
    CircularProgress,
    Container,
    createMuiTheme,
    Fade,
    IconButton,
    makeStyles,
    MuiThemeProvider,
} from "@material-ui/core";
import Page from "../../../components/Page";
import { useNavigate } from "react-router-dom";
import { getActivitats, getPacks } from "../../../database/API";
import MUIDataTable from "mui-datatables";
import Title from "../../../components/Title";
import { Add } from "@material-ui/icons";
import PacksColumns from "./Table/PacksColumns";
import PacksOptions from "./Table/PacksOptions";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "white",
        minHeight: "100%",
    },
    container: {
        paddingTop: 40,
        paddingBottom: 80,
    },
}));

const getMuiTheme = () =>
    createMuiTheme({
        overrides: {
            TableHead: {
                root: {
                    fontSize: 23,
                },
            },
        },
    });

const PacksAdmin = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [packs, setPacks] = useState();
    const [loading, setLoading] = useState(true);
    const columns = PacksColumns();
    const options = PacksOptions(packs);

    useEffect(() => {
        const get = async () => {
            const { packs } = await getPacks();
            console.log(packs);
            setPacks(packs);
            setLoading(false);
        };

        get();
    }, []);

    return (
        <Page className={classes.root} title={"Packs d'activitats"}>
            <Container maxWidth={false} className={classes.container}>
                <Title
                    title={"Packs d'activitats"}
                    subtitle={"Administra els packs de les activitats"}
                    button={
                        <IconButton
                            className={classes.addButton}
                            onClick={() => navigate("nou")}
                        >
                            <Add />
                        </IconButton>
                    }
                />
                <Box pt={2} spacing={3}>
                    {!loading ? (
                        <Fade in={!loading}>
                            <div>
                                <MuiThemeProvider theme={getMuiTheme()}>
                                    <MUIDataTable
                                        data={packs}
                                        columns={columns}
                                        options={options}
                                    />
                                </MuiThemeProvider>
                            </div>
                        </Fade>
                    ) : (
                        <CircularProgress />
                    )}
                </Box>
            </Container>
        </Page>
    );
};

export default PacksAdmin;
